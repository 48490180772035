import React, { useEffect, useState, useMemo } from "react";

import { isNonEmptyArray, isEmptyArray, isNonEmptyObject } from "_helpers";

import { CardCustom as Card } from "./_Card";
import { CollapseHeaderCustom as CollapseHeader } from "./_CollapseHeader";
// import { fakeData } from "./_fakeData";

import { Collapse } from "antd";

export function BasesStatus({ fetchData }){
    const [ data, setData ] = useState({});
    const defaultActiveKey = useMemo(() => ( ["veo", "veowhite", "caloon"] ), [])

    useEffect(() => {
        if( isNonEmptyObject(fetchData) ){
            const dataReduced = Object.entries(fetchData).reduce((acc, [key, val]) => {
                const keySplit = key.split("@"); 
                const domain = keySplit[1];
                const subDomain = keySplit[0];
                        
                if( !acc[domain] ){
                    acc[domain] = [];
                } 
    
                if( domain !== "veo" && domain !== "veowhite" && !acc["other"] ){
                    acc["other"] = [];
                }
    
                switch(domain){
                    case "veo" :
                    case "veowhite" :
                        acc[domain].push({title: subDomain, ...val});
                        break;
                    default : 
                        acc["other"].push({title: subDomain, ...val});
                        break;
                }
                
                return acc;
            }, {})
    
            if( dataReduced.hasOwnProperty("other") && isNonEmptyArray(dataReduced["other"]) ){
                for( const domain in dataReduced ){
                    if( isEmptyArray(dataReduced[domain]) ) delete dataReduced[domain];
                }
            }

            setData(dataReduced)
        }
    }, [fetchData])

    const collapseItems = useMemo(() => {
        if( isNonEmptyObject(data) ){
            return Object.entries(data).map(([key, val]) => {
                return {
                    key,
                    label: <CollapseHeader entries={{ key, val }} />,
                    children: <Card entry={{ val }} />,
                    style: {}
                }
            })
        }
    }, [data]);

    return (
        <div>
            <Collapse 
                items={collapseItems || []} 
                ghost 
                expandIcon={() => null}    
                defaultActiveKey={defaultActiveKey}
            />
        </div>
    );
}