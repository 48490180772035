import React from "react";

import { CustomTable as Table } from "_components";

import { Col, Typography } from "antd";

export const AdministrationSection = ({ title, type = 'table', config = {}, ...props }) => {
    const { Title } = Typography;

    const { fetchData, initialColumns, rowKey, crudMod, ...rest } = config;

    const renderComponent = () => {
        switch( type ){
            case 'table' :
                return (
                    <Table 
                        dataSource={fetchData}
                        initialColumns={initialColumns}
                        rowKey={rowKey}
                        crudMod={crudMod}
                        {...rest}
                        {...props}
                    />
                );
            default : return <></>;
        }
    }

    return (
        <>
            {
                title
                &&
                    <Col xs={24}>
                        <Title level={2}>{ title }</Title>
                    </Col>
            }
            <Col xs={24}>
                { renderComponent() }
            </Col>
        </>
    );
}