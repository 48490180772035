import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '_contexts';
  
export const PublicRoutes = ({ redirectPath = "/", redirectResetPassPath = "/resetpassword" }) => {
    const { user } = useAuth();
    const location = useLocation();

    return (
        <>
            {
                user?.isAuth && !user?.passConfirmed
                ? 
                    <Navigate to={redirectResetPassPath} state={{ from: location }} replace /> 
                :
                user?.isAuth && user?.passConfirmed
                ?
                    <Navigate to={redirectPath} state={{ from: location }} replace /> 
                :
                    <Outlet /> 
            }
        </>
    );
}

export const PublicExtendRoutes = ({ redirectPath = "/", redirectLoginPath = "/login" }) => {
    const { user } = useAuth();
    const location = useLocation();

    return (
        <>
            {
                user?.isAuth && !user?.passConfirmed
                ? 
                    <Outlet /> 
                :
                user?.isAuth && user?.passConfirmed
                ?
                    <Navigate to={redirectPath} state={{ from: location }} replace /> 
                :
                    <Navigate to={redirectLoginPath} state={{ from: location }} replace /> 
            }
        </>
    );
}

export const ProtectedRoutes = ({ redirectPath = "/login" }) => {
    const { user } = useAuth();
    const location = useLocation();

    return (
        <>
            {
                user?.isAuth && user?.passConfirmed
                ? 
                    <Outlet /> 
                :
                    <Navigate to={redirectPath} state={{ from: location }} replace /> 
            }
        </>
    );
};

export const PrivateRoutes = ({ redirectPath = "/", redirectLoginPath = "/login" }) => {
    const { user } = useAuth();
    const location = useLocation();

    return (
        <>
            {
                user?.isAuth && user?.isAdmin && user?.passConfirmed
                ? 
                    <Outlet /> 
                :
                user?.isAuth && user?.passConfirmed
                ? 
                    <Navigate to={redirectPath} state={{ from: location }} replace /> 
                : 
                    <Navigate to={redirectLoginPath} state={{ from: location }} replace />

            }
        </>
    );
}