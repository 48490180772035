import { useState, useEffect, useCallback } from "react";

import { isNonEmpty } from "_helpers";
import { getDashboard } from "_services";

export function useDashboard(){
    const [ licensesList, setLicensesList ] = useState({});
    const [ basesStatus, setBasesStatus ] = useState({});
    const [ fetchIsDone, setFetchIsDone ] = useState(false);
    const [ doRedirect, setDoRedirect ] = useState(false);

    const resetData = useCallback(() => {
        setLicensesList({});
        setBasesStatus({});
        setFetchIsDone(true);
        setDoRedirect(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        try{
            const timer = setTimeout(async () => {
                const { response, data, redirect } = await getDashboard();

                if( response ){
                    if( isNonEmpty(data) ){
                        let licensesListTemp = {}, basesStatusTemp = {};
    
                        Object.entries(data).forEach(([key, values]) => {
                            if( isNonEmpty(values) ){
                                Object.entries(values).forEach(([base, records]) => {
                                    if( key === 'licenses' ){
                                        licensesListTemp[base] = records;
                                    } else {
                                        basesStatusTemp[base] = records;
                                    }
                                })
                            }
                        })
    
                        setLicensesList(licensesListTemp);
                        setBasesStatus(basesStatusTemp);
                    }
                    setFetchIsDone(true);
                } else {
                    resetData();
                }

                setDoRedirect(redirect);
            }, 0)

            return () => clearTimeout(timer);
        } catch(e){
            resetData();
            throw new Error(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return {
        fetchLicensesList: licensesList,
        fetchBasesStatus: basesStatus,
        fetchIsDone,
        dashboardDoRedirect: doRedirect
    };
}