import { useState, useEffect, useCallback } from "react";

import { isNonEmptyObject } from "_helpers";
import { 
    apiList,
    apiCrud,
    remoteLicensesList,
    remoteLicensesCrud,
    permissionsList,
    permissionsCrud,
    usersList,
    usersCrud
} from "_services";

// API
export function useAdminApiList( ) {
    const [ rowData, setRowData ] = useState([]);
    const [ doRedirect, setDoRedirect ] = useState(false);

    const fetchData = useCallback(( ) => {
        try{
            const timer = setTimeout(async () => {
                const { response, data, redirect } = await apiList();

                if( response ){
                    setRowData(data);
                } else {
                    setRowData([]);
                }
                setDoRedirect(redirect);
            }, 0)

            return () => clearTimeout(timer);
        } catch(e){
            setRowData([]);
            throw new Error(e);
        }
    }, [])

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        apiData: rowData,
        fetchApiData: fetchData,
        apiDoRedirect: doRedirect
    };
}

export function useAdminApiCrud() {
    const [ values, setValues ] = useState({});
    const [ successMessage, setSuccessMessage ] = useState("");
    const [ errorMessage, setErrorMessage ] = useState("");

    const [ method, setMethod ] = useState("");

    const handleSubmit = ( formValues, crudMethod ) => {
        setValues(formValues);
        setMethod(crudMethod);
    }

    const handleReset = ( ) => {
        setValues({});
        setSuccessMessage("");
        setErrorMessage("");
        setMethod("");
    }

    useEffect(() => {
        if( isNonEmptyObject(values) ){
            try {
                const timer = setTimeout(async () => {
                    const { response, successMsg, errorMsg } = await apiCrud(values, method);
        
                    if( response ){
                        setSuccessMessage(successMsg);
                    } else {
                        setErrorMessage(errorMsg);
                    }
                }, 0)
        
                return () => clearTimeout(timer);
            } catch(e) {
                handleReset();
                throw new Error(e);
            }
        }
    }, [values, method])

    return {
        setApiValues: handleSubmit,
        setApiResetValues: handleReset,
        apiSuccessMsg: successMessage,
        apiErrorMsg: errorMessage,
    }
}

// REMOTE LICENSES
export function useAdminRemoteLicensesList( ) {
    const [ rowData, setRowData ] = useState([]);
    const [ doRedirect, setDoRedirect ] = useState(false);

    const fetchData = useCallback(( ) => {
        try{
            const timer = setTimeout(async () => {
                const { response, data, redirect } = await remoteLicensesList();

                if( response ){
                    setRowData(data);
                } else {
                    setRowData([]);
                }
                setDoRedirect(redirect);
            }, 0)

            return () => clearTimeout(timer);
        } catch(e){
            setRowData([]);
            throw new Error(e);
        }
    }, [])

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        remoteLicensesData: rowData,
        fetchRemoteLicensesData: fetchData,
        remoteLicensesDoRedirect: doRedirect
    };
}

export function useAdminRemoteLicensesCrud() {
    const [ values, setValues ] = useState({});
    const [ successMessage, setSuccessMessage ] = useState("");
    const [ errorMessage, setErrorMessage ] = useState("");

    const [ method, setMethod ] = useState("");

    const handleSubmit = ( formValues, crudMethod ) => {
        setValues(formValues);
        setMethod(crudMethod);
    }

    const handleReset = ( ) => {
        setValues({});
        setSuccessMessage("");
        setErrorMessage("");
        setMethod("");
    }

    useEffect(() => {
        if( isNonEmptyObject(values) ){
            try {
                const timer = setTimeout(async () => {
                    const { response, successMsg, errorMsg } = await remoteLicensesCrud(values, method);
        
                    if( response ){
                        setSuccessMessage(successMsg);
                    } else {
                        setErrorMessage(errorMsg);
                    }
                }, 0)
        
                return () => clearTimeout(timer);
            } catch(e) {
                handleReset();
                throw new Error(e);
            }
        }
    }, [values, method])

    return {
        setRemoteLicensesValues: handleSubmit,
        setRemoteLicensesResetValues: handleReset,
        remoteLicensesSuccessMsg: successMessage,
        remoteLicensesErrorMsg: errorMessage,
    }
}

// PERMISSIONS
export function useAdminPermissionsList( ) {
    const [ rowData, setRowData ] = useState([]);
    const [ doRedirect, setDoRedirect ] = useState(false);

    const fetchData = ( ) => {
        try{
            const timer = setTimeout(async () => {
                const { response, data, redirect } = await permissionsList();

                if( response ){
                    setRowData(data);
                } else {
                    setRowData([]);
                }
                setDoRedirect(redirect);
            }, 0)

            return () => clearTimeout(timer);
        } catch(e){
            setRowData([]);
            throw new Error(e);
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        permissionsData: rowData,
        fetchPermissionsData: fetchData,
        permissionsDoRedirect: doRedirect
    };
}

export function useAdminPermissionsCrud() {
    const [ values, setValues ] = useState({});
    const [ successMessage, setSuccessMessage ] = useState("");
    const [ errorMessage, setErrorMessage ] = useState("");

    const [ method, setMethod ] = useState("");

    const handleSubmit = ( formValues, crudMethod ) => {
        setValues(formValues);
        setMethod(crudMethod);
    }

    const handleReset = ( ) => {
        setValues({});
        setSuccessMessage("");
        setErrorMessage("");
        setMethod("");
    }

    useEffect(() => {
        if( isNonEmptyObject(values) ){
            try {
                const timer = setTimeout(async () => {
                    const { response, successMsg, errorMsg } = await permissionsCrud(values, method);
        
                    if( response ){
                        setSuccessMessage(successMsg);
                    } else {
                        setErrorMessage(errorMsg);
                    }
                }, 0)
        
                return () => clearTimeout(timer);
            } catch(e) {
                handleReset();
                throw new Error(e);
            }
        }
    }, [values, method])

    return {
        setPermissionsValues: handleSubmit,
        setPermissionsResetValues: handleReset,
        permissionsSuccessMsg: successMessage,
        permissionsErrorMsg: errorMessage,
    }
}

// USERS
export function useAdminUsersList() {
    const [ rowData, setRowData ] = useState([]);
    const [ doRedirect, setDoRedirect ] = useState(false);

    const fetchData = ( ) => {
        try{
            const timer = setTimeout(async () => {
                const { response, data, redirect } = await usersList();

                if( response ){
                    setRowData(data);
                } else {
                    setRowData([]);
                }
                setDoRedirect(redirect);
            }, 0)

            return () => clearTimeout(timer);
        } catch(e){
            setRowData([]);
            throw new Error(e);
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        usersData: rowData,
        fetchUsersData: fetchData,
        usersDoRedirect: doRedirect
    };
}

export function useAdminUsersCrud() {
    const [ values, setValues ] = useState({});
    const [ successMessage, setSuccessMessage ] = useState("");
    const [ errorMessage, setErrorMessage ] = useState("");

    const [ method, setMethod ] = useState("");

    const handleSubmit = ( formValues, crudMethod ) => {
        setValues(formValues);
        setMethod(crudMethod);
    }

    const handleReset = ( ) => {
        setValues({});
        setSuccessMessage("");
        setErrorMessage("");
        setMethod("");
    }

    useEffect(() => {
        if( isNonEmptyObject(values) ){
            try {
                const timer = setTimeout(async () => {
                    const { response, successMsg, errorMsg } = await usersCrud(values, method);
        
                    if( response ){
                        setSuccessMessage(successMsg);
                    } else {
                        setErrorMessage(errorMsg);
                    }
                }, 0)
        
                return () => clearTimeout(timer);
            } catch(e) {
                handleReset();
                throw new Error(e);
            }
        }
    }, [values, method])

    return {
        setUsersValues: handleSubmit,
        setUsersResetValues: handleReset,
        usersSuccessMsg: successMessage,
        usersErrorMsg: errorMessage,
    }
}