import React, { useState, useEffect } from "react";

import { useTranslation } from "_contexts";
import { formatNumbers } from "_helpers";

import { Card, Typography } from "antd";
import { ResponsiveContainer, PieChart, Pie, Cell, Legend, Sector } from "recharts";

const RenderActiveShape = ({ opacity, optionsPanelIsOpen, ...props }) => {
    const { innerRadius, outerRadius, startAngle, endAngle, cx, cy, percent, payload, fill } = props;
    const { _tId, id, value } = payload;

    const commonParams = {
        textAnchor: "middle",
        fill: fill,
        fontFamily: "Poppins",
        fontSize: optionsPanelIsOpen ? "20" : "22"
    }

    const translate = useTranslation();

    return (
        <g>
            <text x={cx} y={cy} dx={0} dy={-32} {...commonParams}>
                {translate(_tId+"."+id)}
            </text>
            <text x={cx} y={cy} dx={9} {...commonParams}>
                {(formatNumbers(value/1000, 1) + "k€")}
            </text>
            <text x={cx} y={cy} dx={5} dy={32} {...commonParams}>
                {(percent * 100).toFixed(1) + "%"}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
                fillOpacity={opacity[id]}
            />
        </g>
    );
};

const RenderLegend = ({ colors, handlers, ...props }) => {
    const { payload, iconSize } = props;
    const { Title } = Typography;
    let sumPayload = 0;

    const { setActiveIndex, opacity, setOpacity } = handlers;

    payload.forEach(el => sumPayload+= el.payload.value)

    const handleMouseEnter = (liId, index) => {
        setOpacity(opacity => ({...opacity, [liId]: 0.5}));
        setActiveIndex(index);
    };

    const handleMouseLeave = (liId) => {
        setOpacity(opacity => ({...opacity, [liId]: 1}));
        setActiveIndex(null);
    };

    const handleMouseQuit = (  ) => {
        const opacityCopy = {...opacity};

        for(const property in opacityCopy){
            opacityCopy[property] = 1;
        }

        setOpacity(opacityCopy);
        setActiveIndex(null);
    }
        
    // const handleClickLeave = (liId) => {
    //     handlerTabs("2"); // Change tab
    //     handleMouseLeave(liId); // Reinitialize opacity
    // }

    const payloadFormatted = (sumPayload) ? formatNumbers(sumPayload/1000, 1) : 0;

    const translate = useTranslation();

    return(
        <Card 
            title={(<Title level={3}>{translate("General.distribution")}</Title>)} 
            style={{width: 420, border: 'none'}}
            actions={[
                <div className="text-right pr-2" style={{fontSize: 18, pointerEvents: "none"}}>Total : {payloadFormatted}k€</div>
            ]}
            onMouseLeave={() => handleMouseQuit()}
        >
            <ul>
                {payload.map((el, index) => {
                    const { _tId, id, value, percent } = el.payload;

                    const valueFormatted = (value) ? formatNumbers(value/1000, 1) : 0,
                        percentFormatted = (percent) ? (percent * 100).toFixed(1) : 0;

                    return(
                        <li 
                            key={index} 
                            style={{listStyleType: "square", fontSize: iconSize, color: colors[index % colors.length]}} 
                            onMouseEnter={() => handleMouseEnter(id, index)} 
                            onMouseLeave={() => handleMouseLeave(id)}
                            // onClick={(index+1 === payload.length) && id === "OTHERS" ? () => handleClickLeave(id) : undefined}
                        >
                            {translate(_tId+"."+id) + " : " + valueFormatted + "k€ – " + percentFormatted + "%"}
                        </li>
                    );
                })}
            </ul>
        </Card>
    );
}

export const RechartsCustom = ({ id, fetchData, fetchColors, buildPdfIsPending, optionsPanelIsOpen }) => {
    const [ rowData, setRowData ] = useState([]);
    const [ colors, setColors ] = useState([]);

    const [ opacity, setOpacity ] = useState({});
    const [ activeIndex, setActiveIndex ] = useState(null);

    useEffect(() => {
        setRowData(fetchData);
        setColors(fetchColors);

        let opacityTemp = {};

        for( const item of fetchData ){
            opacityTemp[item.id] = 1;
        }

        setOpacity(opacityTemp);
    }, [fetchData, fetchColors]);

    useEffect(() => {
        if( buildPdfIsPending ){
            const opacityCopy = {...opacity};

            if( Object.values(opacityCopy).some(el => el !== 1) ){
                for( const property in opacityCopy ){
                    if( opacityCopy[property] === 1 ) continue;
                    opacityCopy[property] = 1;
                }

                setOpacity(opacityCopy);
            }

            setActiveIndex(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buildPdfIsPending])

    return(
        <div className="mx-auto mb-3" style={{width: '70%', height: 500}}>
            <ResponsiveContainer id={id}>
                <PieChart>
                    <Pie
                        data={rowData}
                        dataKey="value"
                        nameKey="name"
                        cx="45%" // Décalage x
                        // cy="50%"
                        activeIndex={activeIndex}
                        activeShape={(props) => <RenderActiveShape opacity={opacity} optionsPanelIsOpen={optionsPanelIsOpen} {...props}/>}
                        innerRadius="60%"
                        outerRadius="90%"
                        startAngle={90}
                        endAngle={-360}
                        paddingAngle={5}
                        labelLine={false}
                        isAnimationActive={false}
                        onMouseEnter={(_, index) => setActiveIndex(index)}
                        onMouseLeave={() => setActiveIndex(null)}
                    >
                        {
                            rowData.map((dataValues, index) => (
                                <Cell 
                                    key={"cell-" + index} 
                                    fill={colors[index]} 
                                    fillOpacity={opacity[dataValues.id]}
                                    stroke="none"
                                />
                            ))
                        }
                    </Pie>
                    <Legend
                        align="right"
                        // verticalAlign="middle"
                        layout="vertical"
                        iconSize={16}
                        wrapperStyle={{
                            right: "15px",
                            top: "20%"
                        }}
                        content={(props) => <RenderLegend colors={colors} handlers={{setActiveIndex, opacity, setOpacity}} {...props}/>} 
                    />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
}