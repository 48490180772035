import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";

import { isNonEmptyArray } from "_helpers";
import { 
    useAdminApiList,
    useAdminApiCrud,
    useAdminRemoteLicensesList,
    useAdminRemoteLicensesCrud,
    useAdminPermissionsList,
    useAdminPermissionsCrud,
    useAdminUsersList,
    useAdminUsersCrud
} from "_hooks";

import { apiColumns, remoteLicensesColumns, permissionsColumns, usersColumns, titleRender } from "./_columns";
import { CustomModal as Modal } from "./_Modal";
import { AdministrationSection } from "./AdministrationSection";

import { message as Message, Row } from "antd";
import { useNavigate } from "react-router-dom";

export function Administration(  ){
    const [ apiRowData, setApiRowData ] = useState([]);
    
    const [ remoteLicensesRowData, setRemoteLicensesRowData ] = useState([]);
    const [ remoteLicensesExpandedRowKeys, setRemoteLicensesExpandedRowKeys ] = useState([]);

    const [ permissionsRowData, setPermissionsRowData ] = useState([]);
    const [ permissionsExpandedRowKeys, setPermissionsExpandedRowKeys ] = useState([]);

    const [ usersRowData, setUsersRowData ] = useState([]);
    const [ userModalIsOpen, setUserModalIsOpen ] = useState(false);

    const { apiData, apiDoRedirect, fetchApiData } = useAdminApiList();
    const { setApiValues, setApiResetValues, apiSuccessMsg, apiErrorMsg } = useAdminApiCrud();

    const { remoteLicensesData, remoteLicensesDoRedirect, fetchRemoteLicensesData } = useAdminRemoteLicensesList();
    const { setRemoteLicensesValues, setRemoteLicensesResetValues, remoteLicensesSuccessMsg, remoteLicensesErrorMsg } = useAdminRemoteLicensesCrud();

    const { permissionsData, permissionsDoRedirect, fetchPermissionsData } = useAdminPermissionsList();
    const { setPermissionsValues, setPermissionsResetValues, permissionsSuccessMsg, permissionsErrorMsg } = useAdminPermissionsCrud();

    const { usersData, usersDoRedirect, fetchUsersData } = useAdminUsersList();
    const { setUsersValues, setUsersResetValues, usersSuccessMsg, usersErrorMsg } = useAdminUsersCrud();

    const [ messageApi, contextHolder ] = Message.useMessage();
    const navigate = useNavigate();

    const permissionsDataRef = useRef([]);

    useEffect(() => {
        setApiRowData(apiData);
    }, [apiData])

    useEffect(() => {
        setRemoteLicensesRowData(arrayToTree(remoteLicensesData));
        const ids = remoteLicensesData.map(d => d.id);
        setRemoteLicensesExpandedRowKeys(ids);
    }, [remoteLicensesData])

    useEffect(() => {
        setPermissionsRowData(arrayToTree(permissionsData));
        permissionsDataRef.current = permissionsData;
        const ids = permissionsData.map(d => d.id);
        setPermissionsExpandedRowKeys(ids);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissionsData])

    useEffect(() => {
        setUsersRowData(usersData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersData])

    useEffect(() => {
        if( apiDoRedirect || remoteLicensesDoRedirect || permissionsDoRedirect || usersDoRedirect ){
            navigate('/');
        }
    }, [apiDoRedirect, remoteLicensesDoRedirect, permissionsDoRedirect, usersDoRedirect])

    const success = useCallback((message) => {
        return messageApi.success(message);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const error = useCallback((message) => {
        return messageApi.error(message);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if( apiSuccessMsg || apiErrorMsg){
            if( apiSuccessMsg ){
                success(apiSuccessMsg);
                fetchApiData();
            } else {
                error(apiErrorMsg);
            }
            setApiResetValues();
        }

        if( remoteLicensesSuccessMsg || remoteLicensesErrorMsg){
            if( remoteLicensesSuccessMsg ){
                success(remoteLicensesSuccessMsg);
                fetchRemoteLicensesData();
            } else {
                error(remoteLicensesErrorMsg);
            }
            setRemoteLicensesResetValues();
        }

        if( permissionsSuccessMsg || permissionsErrorMsg){
            if( permissionsSuccessMsg ){
                success(permissionsSuccessMsg);
                fetchPermissionsData();
            } else {
                error(permissionsErrorMsg);
            }
            setPermissionsResetValues();
        }

        if( usersSuccessMsg || usersErrorMsg){
            if( usersSuccessMsg ){
                success(usersSuccessMsg);
                fetchUsersData();
            } else {
                error(usersErrorMsg);
            }
            setUsersResetValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiSuccessMsg, apiErrorMsg, remoteLicensesSuccessMsg, remoteLicensesErrorMsg, permissionsSuccessMsg, permissionsErrorMsg, usersSuccessMsg, usersErrorMsg])

    const arrayToTree = useCallback((arr, parent = 'root') => {
        return arr
            .filter(item => item.parent === parent)
            .map(child => {
                let ret = {
                    ...child
                }
                if( isNonEmptyArray(arrayToTree(arr, child.id)) ){
                    ret['children'] = arrayToTree(arr, child.id);
                }

                return ret ;
            })
    }, [])

    const parentList = useCallback((data, onlyParentRoot = false, idProperty = 'id', parentProperty = 'parent') => (
        data
            .reduce((acc, val) => {
                const index = acc.findIndex(el => el.value === val[idProperty]);

                if( index === -1 ){
                    if( onlyParentRoot && val[parentProperty] !== 'root' ){
                        return acc;
                    } else {
                        acc.push({
                            value: val[idProperty],
                            label: val[idProperty]
                        });
                    }
                }

                return acc;
            }, [
                { value: "_empty", label: "Sélection", disabled: true }
            ])
    ), [])

    const columnsRemoteLicensesTable = useMemo(() => (
        remoteLicensesColumns
            .map(item => {
                const { dataIndex } = item;
               
                if( dataIndex === 'parent' ) item.inputValues = parentList(remoteLicensesData, true);

                return item;
            })
    ), [remoteLicensesData])

    const columnsPermissionsTable = useMemo(() => (
        permissionsColumns
            .map(item => {
                const { dataIndex } = item;
               
                if( dataIndex === 'parent' ) item.inputValues = parentList(permissionsData);

                return item;
            })
    ), [permissionsData])

    const handleApiSubmit = ( values, method ) => {
        const { domain } = values;

        if( ["edit", "delete"].includes(method) ){
            const find = apiData.find(d => d.domain === domain)
            if( find ){
                setApiValues(values, method);
            }
        } else if( method === 'add' ){
            setApiValues(values, method);
        }
    }

    const handleRemoteLicensesSubmit = ( values, method ) => {
        const { id } = values;

        if( ["edit", "delete"].includes(method) ){
            const find = remoteLicensesData.find(d => d.id === id)
            if( find ){
                setRemoteLicensesValues(values, method);
            }
        } else if( method === 'add' ){
            setRemoteLicensesValues(values, method);
        }
    }

    const handlePermissionsSubmit = ( values, method ) => {
        const { id } = values;

        if( ["edit", "delete"].includes(method) ){
            const find = permissionsData.find(d => d.id === id)
            if( find ){
                setPermissionsValues(values, method);
            }
        } else if( method === 'add' ){
            setPermissionsValues(values, method);
        }
    }

    const handleUsersSubmit = ( values, method ) => {
        if( isNonEmptyArray(permissionsDataRef?.current) ){
            const permissionsIds = permissionsDataRef.current.map(el => el.id);
            values['permissions'] = [];

            for(const property in values){
                const value = values[property];

                if( permissionsIds.includes(property) && value ){
                    values['permissions'].push(property);
                    delete values[property];
                }
            }

            setUsersValues(values, method);
        }
        setUserModalIsOpen(false);
    }

    const modalComponent = useMemo(() => ([
        <Modal 
            key="modalComponent"
            isOpen={userModalIsOpen}
            modalExtraParams={permissionsRowData}
            modalOpener={setUserModalIsOpen}
            onSubmit={handleUsersSubmit}
        />
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ]), [userModalIsOpen, permissionsRowData])

    return(
        <div className="container-fluid">
            { contextHolder }
            <Row>
                <AdministrationSection 
                    title="Clés API"
                    config={{
                        fetchData: apiRowData,
                        initialColumns: apiColumns,
                        rowKey: 'domain',
                        crudMod: {
                            itemToManage: "une clé API",
                            actionsHeader: titleRender('Actions'),
                            onSubmit: handleApiSubmit
                        }
                    }}
                />
            </Row>
            <Row>
                <AdministrationSection 
                    title="Licences distantes"
                    config={{
                        fetchData: remoteLicensesRowData,
                        initialColumns: columnsRemoteLicensesTable,
                        rowKey: 'id',
                        expandableconfig: {
                            keys: remoteLicensesExpandedRowKeys,
                            setKeys: setRemoteLicensesExpandedRowKeys,
                            id: 'id'
                        },
                        crudMod: {
                            itemToManage: "une licence distante",
                            actionsHeader: titleRender('Actions'),
                            onSubmit: handleRemoteLicensesSubmit
                        }
                    }}
                />
            </Row>
            <Row>
                <AdministrationSection 
                    title="Permissions"
                    config={{
                        fetchData: permissionsRowData,
                        initialColumns: columnsPermissionsTable,
                        rowKey: 'id',
                        expandableconfig: {
                            keys: permissionsExpandedRowKeys,
                            setKeys: setPermissionsExpandedRowKeys,
                            id: 'id'
                        },
                        crudMod: {
                            itemToManage: "une permission",
                            actionsHeader: titleRender('Actions'),
                            onSubmit: handlePermissionsSubmit
                        }
                    }}
                />
            </Row>
            <Row>
                <AdministrationSection 
                    title="Utilisateurs"
                    config={{
                        fetchData: usersRowData,
                        initialColumns: usersColumns,
                        rowKey: 'username',
                        crudMod: {
                            itemToManage: "un utilisateur",
                            actionsHeader: titleRender('Actions'),
                            modalComponent: modalComponent,
                            modalOpener: setUserModalIsOpen,
                            onSubmit: handleUsersSubmit
                        }
                    }}
                />
            </Row>
            <div style={{ height: '10px' }}>&nbsp;</div>
        </div>
    );
}