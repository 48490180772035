import React, { useMemo } from "react";

import { Badge, Col, Row, Typography } from "antd";

import { ReactComponent as Dailydump } from "_assets/dashboard/dailydump.svg";
import { ReactComponent as Mail } from "_assets/dashboard/mail.svg";
import { ReactComponent as Email } from "_assets/dashboard/email.svg";

export const CollapseHeaderCustom = ({ entries }) => {
    const { Title } = Typography;
    const { key, val } = entries;

    let baseVar = 'Base', otherVar = 'Autre';
    if( val.length > 1 ){
        baseVar+= 's';
        otherVar+= 's';
    } 

    const badgesColor = useMemo(() => {
        let colors = {
            c1: 'green',
            c2: 'green',
            c3: 'green'
        };

        if( val.length > 0 ){
            val.forEach(v => {
                const { apilog, envelope, email } = v,
                    { inbox, outbox } = email;

                if( colors.c1 === 'green' && apilog?.status === 'KO' ) colors.c1 = 'red';
                if( colors.c2 === 'green' && envelope?.status === 'KO' ) colors.c2 = 'red';
                if( colors.c3 === 'green' && inbox?.status === 'KO' || outbox?.status === 'KO' ) colors.c3 = 'red';
            })
        }

        return {
            dailyDumpColor: colors.c1,
            mailColor: colors.c2,
            emailColor: colors.c3,
        }
    }, [val])

    const defaults = useMemo(() => ({
        height: 30,
        width: 30,
        cursor: 'default'
    }), [])

    const classNameDefaults = useMemo(() => ([
        "mx-1"
    ].join(" ")), [])

    return (
        <div className="ant-collapse-header" >
            <Row>
                <Col xs={22}>
                    <Title level={2}>
                        {
                            ['veo', 'veowhite'].includes(key)
                            ?
                                <div>{baseVar} {key}</div>
                            :
                                <div>{otherVar} {baseVar.toLowerCase()}</div>
                        }
                    </Title>
                </Col>
                <Col xs={2}>
                    <div className="d-flex justify-content-end">
                        <Badge dot color={badgesColor.dailyDumpColor}>
                            <Dailydump className={classNameDefaults} {...defaults} />
                        </Badge>
                        <Badge dot color={badgesColor.mailColor}>
                            <Mail className={classNameDefaults} {...defaults} />
                        </Badge>
                        <Badge dot color={badgesColor.emailColor}>
                            <Email className={classNameDefaults} {...defaults} />
                        </Badge>
                    </div>
                </Col>
            </Row>
            
        </div>
    );
}