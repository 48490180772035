import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";

import { CustomFormItemByType as Input } from "_components";
import { useAuth } from "_contexts";
import { objectsAreEquals, shallowCopy } from "_helpers";
import { login, forgotPassword } from "_services";

import { 
    Button,
    Card, 
    Col,
    Image,
    message,
    QRCode, 
    Row,
    Typography
} from "antd";

import Recouveo from "_assets/recouveo.png";

import styles from "_styles/Login.module.css";

export function Login() {
    const initialFields = useMemo(() => ({
        login: null,
        password: null
    }), []);
    const [ fields, setFields ] = useState(initialFields);
    const [ email, setEmail ] = useState(null);
    const initialFieldsState = useMemo(() => ({
        login: {
            _isTouched: false,
            _status: null
        },
        password: {
            _isTouched: false,
            _status: null
        }
    }), []);
    const [ fieldsState, setFieldsState ] = useState(initialFieldsState);

    const initialErrors = useMemo(() => ({
        login: "",
        password: ""
    }), []);
    const [ errors, setErrors ] = useState(initialErrors);
    const [ passwordVisible, setPasswordVisible ] = useState(false);

    const [ switchMode, setSwitchMode ] = useState("login");

    const formIsSubmittedRef = useRef(false);

    const { setUser } = useAuth();
    const [ messageApi, contextHolder ] = message.useMessage();
    // const InputPassword = Input.Password;
    const { Link } = Typography;

    const loginIsTouched = useMemo(() => ( fieldsState.login._isTouched  ), [fieldsState.login]);
    const passwordIsTouched = useMemo(() => ( fieldsState.password._isTouched ), [fieldsState.password]);

    const qrDimension = useMemo(() => ( 64 ), []);

    useEffect(() => {
        document.title = "Reporting";
    }, [])

    useEffect(() => {
        handleErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginIsTouched, passwordIsTouched, fields.login, fields.password])

    const handleReset = ( ) => {
        setFields(initialFields);
        setFieldsState(initialFieldsState);
        setErrors(initialErrors);
        setPasswordVisible(false);
        setEmail(null);
    } 

    useEffect(() => {
        handleReset();
    }, [switchMode])

    const handleChange = ({ name, value }) => {
        setFields(fields => ({ ...fields, [name]: value }));
    }

    const handleEmailChange = ({ value }) => {
        setEmail(value);
    }

    const handleBlur = ({ name }) => {
        setFieldsState(fieldsState => ({...fieldsState, [name]: {_isTouched: true}}));
    }

    const handleErrors = useCallback(( ) => {
        const formIsSubmitted = formIsSubmittedRef.current; 

        const fieldsCopy = shallowCopy(fields),
            { login, password } = fieldsCopy;

        let tempErrors = shallowCopy(errors), tempFieldsState = shallowCopy(fieldsState);

        if( loginIsTouched || formIsSubmitted ){
            tempErrors['login'] = !login ? 'Le login est un champ obligatoire' : '';
            tempFieldsState['login']['_status'] = !login ? 'error' : null;
        } 

        if( passwordIsTouched || formIsSubmitted ){
            tempErrors['password'] = !password ? 'Le mot de passe est un champ obligatoire' : '';
            tempFieldsState['password']['_status'] = !password ? 'error' : null;
        }

        setErrors(tempErrors);
        setFieldsState(tempFieldsState);

        return objectsAreEquals(tempErrors, initialErrors);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields, fieldsState, errors, loginIsTouched, passwordIsTouched, formIsSubmittedRef.current])
    
    const onSubmit = ( ) => {
        formIsSubmittedRef.current = true;
        const isValid = handleErrors();

        if( isValid ){
            try{
                const timer = setTimeout(async () => {
                    const { response, successMsg, errorMsg, data } = await login(fields);

                    if( response ){
                        messageApi.success({key: "validation", content: successMsg});
                        setUser(oldVal => ({...oldVal, isAuth: response, ...data}));
                    } else{
                        messageApi.error({key: "validation", content: errorMsg});
                    }
                }, 0)

                formIsSubmittedRef.current = false;
                handleReset();

                return () => clearTimeout(timer);
            } catch(e){
                throw new Error(e);
            }
        } else {
            messageApi.error({key: "validation", content: "Validation incorrecte, veuillez correctement remplir les champs marqués d'un astérisque"});
        }
    }

    const onForgotPasswordSubmit = useCallback(() => {
        if( email ){
            try{
                const timer = setTimeout(async () => {
                    const { response, successMsg, errorMsg } = await forgotPassword({ email });

                    if( response ){
                        messageApi.success({key: "validation", content: successMsg});
                    } else{
                        messageApi.error({key: "validation", content: errorMsg});
                    }
                }, 0)

                handleReset();

                return () => clearTimeout(timer);
            } catch(e){
                throw new Error(e);
            }
        } else {
            messageApi.error({key: "validation", content: "Validation incorrecte, veuillez correctement remplir le champ marqué d'un astérisque"});
        }
    }, [email])

    const handleKeyUp = ({ key }) => key === 'Enter' && onSubmit();
    const handleEmailKeyUp = ({ key }) => key === 'Enter' && onForgotPasswordSubmit();

    return(
        <>
            { contextHolder }
            <Row gutter={16} className="h-100 w-100 justify-content-center align-items-center">
                <Col>
                    <Row className="flex-column">
                        <Image src={Recouveo} preview={false} height={160} />
                        <Row style={{ height: 40 }}>
                            <Col>
                                <QRCode 
                                    value="recouveo.com" 
                                    className={styles.qrCode}
                                    size={qrDimension}
                                    bordered={false}
                                />
                            </Col>
                            <Col flex='auto'>
                                <span style={{ fontSize: 30 }}>Reporting</span>
                            </Col>
                        </Row>
                    </Row>
                </Col>
                <Col>
                    <Card
                        title={ switchMode === "login" ? "Se connecter" : switchMode === "forgotPassword" && "Mot de passe oublié" }
                        headStyle={{ minHeight: "0px", height: "50px" }}
                        bodyStyle={{ paddingBottom: "8px", height: "150px" }}
                        style={{ width: "450px", height: "200px" }}
                    >   
                        <Row gutter={[16,8]} className="h-100 flex-column justify-content-between">
                        {
                            switchMode === "login"
                            ?
                                <>
                                    <Col>
                                        <Input
                                            name="login"
                                            required
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onKeyUp={handleKeyUp}
                                            status={fieldsState?.login?._status}
                                            value={fields.login}
                                        >
                                            Login
                                        </Input>
                                    </Col>
                                    <Col>
                                        <Input
                                            type="inputpassword"
                                            name="password"
                                            required
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onKeyUp={handleKeyUp}
                                            status={fieldsState?.password?._status}
                                            value={fields.password}
                                            visibilityToggle={{
                                                visible: passwordVisible,
                                                onVisibleChange: setPasswordVisible,
                                            }}
                                        >
                                            Mot de passe
                                        </Input>
                                    </Col>
                                    <Col className="d-flex align-items-center">
                                        {/* <Link onClick={() => setSwitchMode("forgotPassword")}>Mot de passe oublié ?</Link> */}
                                        <Button className="ms-auto" type="primary" onClick={onSubmit}>Connexion</Button>
                                    </Col>
                                </>
                            :
                            switchMode === "forgotPassword"
                            &&
                                <>
                                    <Col className="mt-auto d-flex">
                                        <Input
                                            name="email"
                                            required
                                            onChange={handleEmailChange}
                                            onKeyUp={handleEmailKeyUp}
                                            value={email}
                                        >
                                            E-mail
                                        </Input>
                                    </Col>
                                    <Col className="mt-auto d-flex align-items-center">
                                        <Link onClick={() => setSwitchMode("login")}>Se connecter ?</Link>
                                        <Button className="ms-auto" type="primary" onClick={onForgotPasswordSubmit}>Envoyer</Button>
                                    </Col>
                                </>
                        }
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    );
}