import React, { useState, useEffect, useMemo, useCallback } from "react";

import { CustomTableCell as TableCell } from "./_TableCell";
import { CustomTableModal as TableModal } from "./_TableModal";
import { isNonEmptyArray, isNonEmptyObject, deepCopy } from "_helpers";

import { 
    Button,
    Popconfirm,
    Space, 
    Table 
} from "antd";

export const CustomTable = ({ 
    dataSource = [], 
    initialColumns = [], 
    rowKey="key", 
    crudMod = {},
    ...rest
}) => {
    const [ rowData, setRowData ] = useState([]);

    const [ editingKey, setEditingKey ] = useState("");
    const [ editingValues, setEditingValues ] = useState({});

    const {
        itemToManage = "une entrée",
        actionsHeader,
        modalComponent,
        modalOpener,
        onSubmit,
    } = crudMod;

    const {
        expandableconfig
    } = rest;

    useEffect(() => {
        setRowData(dataSource);
    }, [dataSource])

    const isEditing = key => key === editingKey;

    const removeTmp = () => {
        let rowDataCopy = deepCopy(rowData);
        rowDataCopy = rowDataCopy.filter(d => d[rowKey] !== 'tmp');

        setRowData(rowDataCopy);
    }

    const expandableObj = useCallback(( keys, setKeys, idProperty ) => {
        return {
            expandedRowKeys: keys,
            onExpand: ( expandable, record ) => {
                if( expandable ) {
                    setKeys([...keys, record[idProperty]]);
                } else {
                    setKeys(keys.filter((id) => record[idProperty] !== id));
                }
            }
        }
    }, [])

    const handleAdd = () => {
        if( !editingKey && !modalComponent ){
            const newData = initialColumns.reduce((acc, val) => {
                const { dataIndex } = val;
    
                if( !acc[rowKey] ) acc[rowKey] = 'tmp';
                if( !acc[dataIndex] ) acc[dataIndex] = "";
    
                return acc;
            }, {})
    
            setEditingKey(newData[rowKey]);
            setRowData([...rowData, newData]);
        }

        if( modalComponent ){
            modalOpener(oldVal => !oldVal);
        }
    };

    const handleCancel = () => {
        removeTmp();
        setEditingKey('');
        setEditingValues({});
    }

    const handleEdit = ( id, rec ) => {
        setEditingKey(id);
        setEditingValues(rec);
    }

    const handleChange = ( name, value ) => {
        setEditingValues(oldVal => ({ ...oldVal, [name]: value }));
    }

    const handleSubmit = ( values, method ) => {
        handleCancel();
        onSubmit(values, method);
    }

    const components = useMemo(() => ({
        body: {
          cell: TableCell,
        },
    }), [])

    const expandable = useMemo(() => {
        if( expandableconfig ){
            const { keys, setKeys, id } = expandableconfig;
    
            return expandableObj(keys, setKeys, id);
        }
    }, [expandableconfig])

    const columns = useMemo(() => (
        isNonEmptyObject(crudMod)
        ?
            [
                ...initialColumns,
                {
                    title: actionsHeader ? actionsHeader : 'Actions',
                    render: (_, rec) => {
                        const editable = isEditing(rec[rowKey]);

                        return (
                            <>
                                {
                                    editable && !modalComponent
                                    ?
                                        <Space>
                                            <Button type="primary" onClick={editingKey ? () => handleSubmit(editingValues, editingKey === 'tmp' ? 'add' : 'edit') : undefined}>Sauver</Button>
                                            <Button type="primary" danger onClick={handleCancel}>Annuler</Button>
                                        </Space>
                                    :
                                    (!editable || modalComponent)
                                    &&
                                        <Space>
                                            <Button type="primary" onClick={() => handleEdit(rec[rowKey], rec)}>Editer</Button>
                                            <Popconfirm
                                                description="Êtes-vous sûr de vouloir supprimer cette entrée ?"
                                                onConfirm={() => handleSubmit(rec, 'delete')}    
                                            >
                                                <Button type="primary" danger>Supprimer</Button>
                                            </Popconfirm>
                                        </Space>
                                }
                            </>
                        );
                    }
                }
            ]
                .map(col => {
                    const { editable, dataIndex, inputType, inputValues, title } = col;

                    if( dataIndex === rowKey ){
                        col.editable = ( editingKey === 'tmp' );
                    }

                    if( !editable ){
                        return col;
                    }
                    return {
                        ...col,
                        onCell: rec => {
                            const editing = +isEditing(rec[rowKey]);

                            return {
                                rec,
                                inputType: inputType ? inputType : 'text',
                                inputValues,
                                dataIndex,
                                title,
                                editing,
                                editingValues,
                                handleChange
                            }
                        }
                    };
                })
        :
            initialColumns
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [crudMod, editingKey, editingValues, rowKey, actionsHeader, modalComponent])

    return (
        <Space direction="vertical" className="w-100">
            <Table 
                columns={columns}
                dataSource={rowData}
                rowKey={rowKey}
                components={!modalComponent ? components : undefined}
                bordered
                pagination={false}
                expandable={expandable}
            />
            {
                isNonEmptyObject(crudMod)
                &&
                    <Button 
                        type="primary" 
                        className="d-flex ms-auto" 
                        style={ editingKey ? { backgroundColor: 'grey', cursor: 'not-allowed' } : undefined } 
                        onClick={ handleAdd }>
                            Ajouter { itemToManage }
                    </Button>
            }
            {
                modalComponent && modalComponent
            }
        </Space>
    );
}