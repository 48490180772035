import React, { useState, useEffect } from "react";

import { isArray, isNonEmptyArray } from "_helpers";

import { Checkbox, Divider, TreeSelect } from "antd";

export const CustomTreeSelect = ({ fetchData, value, onChange, dependencyIsSelected, handleDependencyComeFromCleared, handleDependencyComeFromDeselected }) => {
    const [ rowData, setRowData ] = useState([]);
    
    const [ itemsSelected, setItemsSelected ] = useState([]);
    const [ isCheckedAll, setIsCheckedAll ] = useState(false);

    const { SHOW_CHILD } = TreeSelect;

    const [ dependencyComeFromCleared, setDependencyComeFromCleared ] = handleDependencyComeFromCleared;
    const [ dependencyComeFromDeselected, setDependencyComeFromDeselected ] = handleDependencyComeFromDeselected;

    useEffect(() => {
        setRowData(fetchData);
    }, [fetchData])

    useEffect(() => {
        if( isCheckedAll ){
            let rowDataTemp = [];

            rowData.forEach(data => {
                const { value, children } = data;

                if( value.includes('xxx') ) return ;

                if( !rowDataTemp.includes(value) && !children ) rowDataTemp.push(value);

                if( isArray(children) && isNonEmptyArray(children) ){
                    children.forEach(child => {
                        if( !rowDataTemp.includes(child.value) ) rowDataTemp.push(child.value);
                    })
                }
            })


            setItemsSelected(rowDataTemp);
        } else {
            setItemsSelected([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCheckedAll])

    useEffect(() => {
        onChange(itemsSelected, "entities");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsSelected])

    useEffect(() => {
        if( dependencyComeFromCleared || dependencyComeFromDeselected  ){
            setItemsSelected([]);
            setIsCheckedAll(false);
            setDependencyComeFromCleared(false);
            setDependencyComeFromDeselected(false);
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependencyComeFromCleared, dependencyComeFromDeselected])

    return(
        <>
            <TreeSelect
                treeData={
                    isNonEmptyArray(rowData) 
                    ?
                        [
                            {
                                title: (
                                    <>
                                        <Checkbox checked={isCheckedAll} onChange={() => setIsCheckedAll(prevValue => !prevValue)}>
                                            {!isCheckedAll ? 'Tout sélectionner' : 'Tout désélectionner'}
                                        </Checkbox>
                                        <Divider className="my-3"/>
                                    </>
                                ),
                                value: 'xxx',
                                checkable: false
                            },
                            ...rowData
                        ]
                    :
                        []
                }
                placeholder="Sélection"
                className="w-100"
                size="large"
                value={value}
                treeCheckable={true}
                showCheckedStrategy={SHOW_CHILD}
                maxTagCount="responsive"
                treeNodeFilterProp="title" // When searching use 'title' instead of 'key' 
                onChange={(value) => setItemsSelected(value)}
            />
        </>
    );
}    