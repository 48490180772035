import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';

import { AppProvider, AuthProvider, TranslateProvider } from "_contexts";

import { ConfigProvider } from 'antd';
import frFR from 'antd/locale/fr_FR';
import 'dayjs/locale/fr';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <ConfigProvider 
            locale={frFR} 
            theme={{
                token: {
                    fontFamily: 'Poppins, sans-serif',
                    colorPrimary: '#0b6fba'
                }
            }}
        >
        <AuthProvider>
        <AppProvider>
        <TranslateProvider>
            <App/>
        </TranslateProvider>
        </AppProvider>
        </AuthProvider>
        </ConfigProvider>
    </React.StrictMode>
);