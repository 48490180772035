import React, { useMemo, useRef, Fragment } from "react";

import { CustomFormItemByType as Input, InputText as Text } from "_components";

import { Button, Col, Modal, Row, Typography } from "antd";

export const CustomModal = ({ isOpen, isEditMode, modalExtraParams, modalOpener, onSubmit }) => {
    const formValuesRef = useRef({});
    
    const { Title } = Typography;

    const handleChange = ({ name , value }) => {
        formValuesRef.current = { ...formValuesRef.current, [name]: value };
    }

    const handleSubmit = ( ) => {
        onSubmit(formValuesRef.current, 'add');
        formValuesRef.current = {};
    }

    const roleList = useMemo(() => ([
        { value: "_empty", label: "Sélection", disabled: true },
        { value: "user", label: "Utilisateur" }
    ]), [])

    const footer = useMemo(() => ([
        <Row key="modalFooter" className="justify-content-end">
            <Button type="primary" onClick={handleSubmit}>Sauver</Button>
            <Button type="primary" danger onClick={() => modalOpener(oldVal => !oldVal)}>Annuler</Button>
        </Row>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ]), [formValuesRef?.current])

    const permissionsTitleTranslate = useMemo(() => ({
        reporting: 'Reporting',
        filestatus: 'Etat des dossiers',
        filetracking: 'Suivi des dossiers',
        dashboard: 'Dashboard',
    }), [])

    const PermissionsRender = ({ id, txt, parent, children }) => {
        return (
            <Fragment key={id}>
                {
                    parent === 'root' && !children
                    ?
                        <Row className="flex-column">
                            {
                                id in permissionsTitleTranslate
                                &&
                                    <Title level={5}>{ permissionsTitleTranslate[id] }</Title>
                            }
                            <Input 
                                type="checkbox"
                                name={id}
                                className="ms-3"
                                labelOrientation="right"
                                labelSeparator=""
                                onChange={handleChange}
                            >
                                { txt }
                            </Input>
                        </Row>
                    :
                    parent === 'root' && children
                    ?
                        <>
                            {
                                id in permissionsTitleTranslate
                                &&
                                    <Title level={5}>{ permissionsTitleTranslate[id] }</Title>
                            }
                            <Input 
                                type="checkbox"
                                name={id}
                                className="ms-3"
                                labelOrientation="right"
                                labelSeparator=""
                                onChange={handleChange}
                            >
                                { txt }
                            </Input>
                            {
                                children.map(child => {
                                    return (
                                        <PermissionsRender {...child} />
                                    )
                                })
                            }
                        </>
                    :
                    parent !== 'root' && children
                    ?
                        <>
                            <Input 
                                type="checkbox"
                                name={id}
                                className="ms-3"
                                labelOrientation="right"
                                labelSeparator=""
                                onChange={handleChange}
                            >
                                { txt }
                            </Input>
                            {
                                children.map(child => {
                                    return (
                                        <PermissionsRender {...child} />
                                    )
                                })
                            }
                        </>
                    :
                        <Input 
                            type="checkbox"
                            name={id}
                            className="ms-3"
                            labelOrientation="right"
                            labelSeparator=""
                            onChange={handleChange}
                        >
                            { txt }
                        </Input>
                }
            </Fragment>
        );
    }

    const permissionsList = useMemo(() => (
        modalExtraParams
            .map((el, idx) => {
                return (
                    <Col key={idx} xs={24} className="mt-2">
                        <PermissionsRender {...el} />
                    </Col>
                );
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [modalExtraParams])

    const formConfig = useMemo(() => ([
        {
            name: 'username',
            label: 'Nom d\'utilisateur',
            type: 'input'
        },
        {
            name: 'password',
            label: 'Mot de passe',
            type: 'inputpassword'
        },
        {
            name: 'email',
            label: 'E-mail',
            type: 'input'
        },
        {
            name: 'role',
            label: 'Rôle',
            type: 'select',
            options: roleList
        },
        {
            name: 'permissions',
            label: 'Permissions',
            type: 'multicheckboxgroup',
            options: permissionsList
        }
    ]), [roleList, permissionsList])

    return (
        <Modal
            open={isOpen}
            footer={footer}
            closable={false}
            width={800}
            maskClosable={false}
            destroyOnClose={true}
        >
            <Title level={3}>{ isEditMode ? 'Edition de ' + formValuesRef?.['current']?.['username'] : 'Ajout d\'un utilisateur'}</Title>

            {
                formConfig.map((el, idx) => {
                    const { type, label, className, ratio, ...rest } = el;

                    return (
                        <div className="mt-2">
                            {
                                type === 'multicheckboxgroup'
                                ?
                                    <Fragment key={idx}>
                                        <Text>{ label }</Text>
                                        <div className="ms-3">
                                            <Input className={className} type={type} onChange={handleChange} {...rest} />
                                        </div>
                                    </Fragment>
                                :
                                    <Fragment key={idx}>
                                        <Input className={className} type={type} onChange={handleChange} {...rest}>
                                            { label }
                                        </Input>
                                    </Fragment>
                            }
                        </div>
                    );

                })
            }


        </Modal>
    );
}