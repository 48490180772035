import React from "react";

import { Menu } from "antd";

import { ReactComponent as DropdownArrow } from "_assets/dropdown-arrow.svg";

export const CustomMenu = ({ items, onSelect, defaultOpenKeys = [], defaultSelectedKeys = [], mode = 'inline' }) => {
    return (
        <Menu 
            mode={mode}
            items={items} 
            className="h-100"
            defaultOpenKeys={defaultOpenKeys}
            defaultSelectedKeys={defaultSelectedKeys}
            expandIcon={({ isOpen }) => <DropdownArrow transform={isOpen ? "rotate(180)" : undefined} className="expand-icon"/>}
            onSelect={({ key }) => onSelect(key)}
        />
    );
}