import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";

import { 
    AgGridCustom as AgGrid,
    CustomFormRow as FormRow, 
    CustomSpin as Spin,
    CustomTreeSelect as TreeSelect,
    RangePickerFooter as Footer, 
    RechartsCustom as Recharts
} from "_components";
import {
    GridContext,
    useApp,
    useAuth,
    useTranslate,
    useTranslation
} from "_contexts";
import { 
    formatHeadernames, 
    // buildPdf,
    disableAntdPickerDates,
    isArray,
    isEmptyArray,
    isNonEmptyArray,
    isNonEmptyObject,
    shallowCopy,
    hasPermission
} from "_helpers";
import { 
    useGetColumnDefs,
    useGetReportingCharts,
    useReporting, 
    useReportingGridGroups,
    useReportingDownloadPdf,
    useCheckboxGroup
} from "_hooks";

import { popoverContent } from "./_popover";
import { 
    gridOptions,

    createPinDataReporting,
    createPinDataBilling,
    createPinDataBillingSynt,
} from "./_tableConfigs";

import { NotificationsModalContent as ModalContent } from "Pages";

import styles from "_styles/reporting/reporting.module.css";

import { 
    Badge,
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Menu,
    message as Message,
    Modal,
    Popover, 
    Row,
    Select,
    Space
} from "antd";

import { CCollapse } from "@coreui/react";
// import { jsPDF } from "jspdf";
import { useNavigate } from "react-router-dom";

import { ReactComponent as DownloadPdf } from "_assets/reporting/download_pdf.svg";
import { ReactComponent as OptionsPanel } from "_assets/reporting/ext_filters.svg";
import { ReactComponent as BillingIcon } from "_assets/reporting/invoice.svg";
import { ReactComponent as Notifications } from "_assets/reporting/notifications.svg";
import { ReactComponent as ReportingIcon } from "_assets/reporting/report.svg";

import { ReactComponent as Close } from "_assets/close_icon.svg";
import { ReactComponent as Delete } from "_assets/delete.svg";
import { ReactComponent as DownloadXlsx } from "_assets/download_xlsx.svg";
import { ReactComponent as DropdownArrow } from "_assets/dropdown-arrow.svg";
import { ReactComponent as Info } from "_assets/info.svg";
import { ReactComponent as RightArrow } from "_assets/right_arrow.svg";

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export function Reporting(  ){
    const initialFieldsState = useMemo(() => ({
        bases: [],
        entities: [],
        constructDates: [],
        displayDates: []
    }), []);

    const initialExternalFiltersState = useMemo(() => ({
        searchbar: null,
        user: null
    }), []);

    const formatFR = useMemo(() => ( "DD/MM/YYYY" ), []);
    const formatEN = useMemo(() => ( "YYYY-MM-DD" ), []);

    const viewModeOptions = useMemo(() => ([
        { value: "entity_view", label: "Vue par entités" },
        { value: "account_view", label: "Vue par comptes" },
        { value: "affect_view", label: "Vue par affectations" },
        { value: "record_view", label: "Vue par factures" },
    ]), []);

    const viewModeTranslate = useMemo(() => ({
        entity_view: 'soc_id',
        account_view: 'acc_id',
        affect_view: 'user',
        record_view: 'record_id'
    }), []);

    const clearIconStyle = useMemo(() => ({
        height:"1em", width:"1em", fontSize:14 
    }), [])

    const [ exportIsDisable, setExportIsDisable ] = useState(false);

    const checkboxGroupOptions = useMemo(() => ([
        { value: 'columnGroups', label: 'Sans les groupes d\'en-têtes', disabled: exportIsDisable },
        { value: 'skipHeader', label: 'Sans les en-têtes', disabled: exportIsDisable },
        { value: 'skipPinnedTop', label: 'Sans la ligne flottante', disabled: exportIsDisable },
    ]), [exportIsDisable]);

    const [ viewModeDefaultValue ] = useState("entity_view");
    const [ viewMode, setViewMode ] = useState(viewModeDefaultValue);

    const pinDataExtraParamsBillingSynt = useMemo(() => {
        if( viewMode === 'record_view' ) return ['amount', 'amount_due'];
        return [];
    }, [viewMode]);

    const [ fields, setFields ] = useState(initialFieldsState);

    const displayDates = useMemo(() => {
        if( fields.displayDates.every(date => date) ) return fields.displayDates.map(date => dayjs(date).format(formatFR));
        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields.displayDates]);

    const [ bases, setBases ] = useState([]);

    const [ basesComeFromDeselected, setBasesComeFromDeselected ] = useState(false);
    const [ basesComeFromCleared, setBasesComeFromCleared ] = useState(false);

    const [ reportingRowData, setReportingRowData ] = useState([]);
    const [ reportingRowDataFiltered, setReportingRowDataFiltered ] = useState([]);
    const [ entities, setEntities ] = useState([]);
    const [ entitiesTranslate, setEntitiesTranslate ] = useState([]);
    const [ users, setUsers ] = useState([]);
    const [ options, setOptions ] = useState({});

    const [ notificationsCount, setNotificationsCount ] = useState(0);
    const [ notifications, setNotifications ] = useState({});

    const [ notificationsModalIsOpen, setNotificationsModalIsOpen ] = useState(false);

    const [ activeLeftMenuKeyGroup, setActiveLeftMenuKeyGroup ] = useState("reporting");

    const [ formIsSubmitted, setFormIsSubmitted ] = useState(false);
    const [ countFormIsSubmitted, setCountFormIsSubmitted ] = useState(0);

    const [ defaultOpenedMenu ] = useState("reporting");
    const [ defaultSelectedMenu ] = useState("reporting_table");
    const [ currentSelectedMenu, setCurrentSelectedMenu ] = useState(defaultSelectedMenu);
    const [ optionsPanelIsOpen, setOptionsPanelIsOpen ] = useState(false);
    const [ exportPanelIsOpen, setExportPanelIsOpen ] = useState(false);

    const [ gridApi, setGridApi ] = useState(null);

    const [ externalFilters, setExternalFilter ] = useState(initialExternalFiltersState);

    const [ attrAreVisible, setAttrAreVisible ] = useState(false);
    const [ assignmentIsVisible, setAssignmentIsVisible ] = useState(false);
    const [ priorityIsVisible, setPriorityIsVisible ] = useState(false);

    const initialAlertsDisplayedState = useMemo(() => ({
        localFeeIsMissing: false,
        remoteFeeIsMissing: false,
    }), []);

    const today = useMemo(() => ( dayjs().format('YYYYMMDD_HHmmss') ), [])

    const initialExcelCustomParamsState = useMemo(() => ({
        fileName: 'Reporting - ' + today,
        sheetName: 'Reporting'
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    const [ excelCustomParams, setExcelCustomParams ] = useState(initialExcelCustomParamsState);

    const [ alertsIsDisplayed, setAlertIsDisplayed ] = useState(initialAlertsDisplayedState);
    const [ buildPdfIsPending, setBuildPdfIsPending ] = useState(false);

    const { translations } = useTranslate();
    const translate = useTranslation();
    const navigate = useNavigate();

    const { RangePicker } = DatePicker;
    const CheckboxGroup = Checkbox.Group;
    const { Option, OptGroup } = Select;

    const gridReportingRef = useRef();
    const gridBillingLocalRef = useRef();
    const gridBillingRemoteRef = useRef();
    const gridBillingSyntRef = useRef();

    const userTypeRef = useRef("all");

    const payload = {
        formIsSubmitted,
    }

    const { appConfig } = useApp();
    const { user } = useAuth();

    const { columnDefsReporting, columnDefsBillings, columnDefsBillingsSynt } = useGetColumnDefs();
    const { fetchColumnDefs, fetchTableFields, reportingGridGroupsDoRedirect } = useReportingGridGroups(columnDefsReporting);
    const { fetchReportingData, fetchReportingToBeChecked, fetchBillingData, initialBillingDataState, reportingDoRedirect } = useReporting(fields, payload);
    const { reportingDownloadPdfDoRedirect, downloadPdfIsPending, initiateDownload } = useReportingDownloadPdf(reportingRowDataFiltered); // X
    const { chartsData, chartsColors, chartsPdfData, chartsPdfConfig } = useGetReportingCharts(reportingRowDataFiltered, fields);
    const { checkedList, indeterminate, checkAll, onCheckboxGroupChange, onCheckboxGroupAllChange, onResetAll } = useCheckboxGroup(checkboxGroupOptions);

    const [ billingRowData, setBillingRowData ] = useState(initialBillingDataState);
    const [ billingRowDataByViewMode, setBillingRowDataByViewMode ] = useState(initialBillingDataState['BOTH']);

    const newColumnDefs = formatHeadernames(fetchColumnDefs, displayDates);

    const resetFields = useCallback(() => {    
        setReportingRowData([]);
        setNotifications({});
        setNotificationsCount(0);
        setReportingRowDataFiltered([]);
        setBillingRowData(initialBillingDataState);
        setBillingRowDataByViewMode(initialBillingDataState['BOTH']);
        setUsers([]);
        setAlertIsDisplayed(initialAlertsDisplayedState);

        if( gridApi ) gridApi.api.setQuickFilter("");
        setExternalFilter(initialExternalFiltersState);

        setFormIsSubmitted(true);
        setCountFormIsSubmitted(oldCount => oldCount +1 );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridApi])

    const updateRowData = useCallback(( newData ) => {
        setReportingRowDataFiltered(newData);
    }, [])

    useEffect(() => {
        document.title = "Reporting";
    }, [])

    useEffect(() => {
        if( reportingDoRedirect || reportingGridGroupsDoRedirect ){
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportingDoRedirect, reportingGridGroupsDoRedirect])

    useEffect(() => {
        setBases(appConfig?.reporting_bases || []);
    }, [appConfig?.reporting_bases])

    const chartsDataIsNonEmpty = useMemo(() => ( 
        Object.values(chartsData)
            .every(chartData => Object.values(chartData)
            .some(chartValue => chartValue.value !== 0))
        ), [chartsData])
    const fetchBillingDataIsNonEmpty = useMemo(() => ( Object.values(fetchBillingData).some(el => isNonEmptyArray(el)) ), [fetchBillingData]);

    const condition4 = useMemo(() => ( 
        isNonEmptyArray(fetchReportingData) || 
        isNonEmptyObject(fetchReportingToBeChecked) || 
        fetchBillingDataIsNonEmpty 
    ), [fetchReportingData, fetchReportingToBeChecked, fetchBillingDataIsNonEmpty]);

    useEffect(() => {
        if( condition4 ){
            setReportingRowData(fetchReportingData);
            setNotifications(fetchReportingToBeChecked);
    
            let tempCount = 0;
    
            for( const property in fetchReportingToBeChecked ){
                const value = fetchReportingToBeChecked[property];
    
                tempCount += value.length;
            }
    
            setNotificationsCount(tempCount);
    
            setReportingRowDataFiltered(fetchReportingData);
            if( fetchBillingDataIsNonEmpty ) setBillingRowData(fetchBillingData);
    
            let uniqueUsers = fetchReportingData
                .map(data => data.user)
                .filter((value, index, self) => value && self.indexOf(value) === index);
    
            uniqueUsers.sort();
    
            uniqueUsers = uniqueUsers
                .map(user => ({
                    value: user,
                    label: user
                }))
    
            setUsers(uniqueUsers);
    
            setFormIsSubmitted(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition4])

    useEffect(() => {
        if( gridApi && formIsSubmitted ){
            setTimeout(() => { gridApi.api.hideOverlay() } , 0);
            setTimeout(() => { gridApi.api.showLoadingOverlay() } , 100);
        }
    }, [gridApi, formIsSubmitted])

    useEffect(() => {
        if( countFormIsSubmitted ){
            switch( currentSelectedMenu ){
                case 'reporting_table' :
                    if( isEmptyArray(reportingRowData) ){
                        setTimeout(() => { gridApi.api.showNoRowsOverlay() }, 100) ;
                    } 
                    break;
                case 'billing_int' :
                    if( isEmptyArray(billingRowData['LOCAL']) ){
                        setTimeout(() => { gridApi.api.showNoRowsOverlay() }, 100) ;
                    } 
                    break;
                case 'billing_ext' :
                    if( isEmptyArray(billingRowData['REMOTE']) ){
                        setTimeout(() => { gridApi.api.showNoRowsOverlay() }, 100) ;
                    } 
                    break;
                case 'billing_synt' :
                    if( isEmptyArray(billingRowData['BOTH']) ){
                        setTimeout(() => { gridApi.api.showNoRowsOverlay() }, 100) ;
                    } 
                    break;
                default :
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countFormIsSubmitted, gridApi, currentSelectedMenu])

    const condition5 = useMemo(() => ( isNonEmptyObject(appConfig?.entities) && isNonEmptyObject(appConfig?.entities_translate) && isNonEmptyObject(appConfig?.options) ), [appConfig?.entities, appConfig?.entities_translate, appConfig?.options]);
    const condition6 = useMemo(() => ( fields.bases.length ), [fields.bases.length]);

    useEffect(() => {
        if( condition5 && condition6 ){
            let tempEntities = [];
            let tempEntitiesTranslate = [];
            let tempOptions = {};

            fields.bases.forEach(el => {
                let tempObj = {
                    title: (
                        <Divider orientation="left" className="mt-2 mb-1">{el}</Divider>
                    ),
                    value: el + 'xxx',
                    checkable: false
                };
                tempEntities = [...tempEntities, ...[tempObj], ...appConfig.entities[el]];
                tempEntitiesTranslate = [...tempEntitiesTranslate, ...appConfig.entities_translate[el]];
                if( appConfig.options.hasOwnProperty(el) ){
                    tempOptions = {...tempOptions, [el]: appConfig.options[el]};
                }
            })

            setEntities(tempEntities);
            setEntitiesTranslate(tempEntitiesTranslate);
            setOptions(tempOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition5, condition6])

    const getCurrentRef = useCallback(() => {
        let currentRef = null;

        if( gridReportingRef.current ){
            currentRef = gridReportingRef.current;
        } else if( gridBillingLocalRef.current ){
            currentRef = gridBillingLocalRef.current;
        } else if( gridBillingRemoteRef.current ){
            currentRef = gridBillingRemoteRef.current;
        } else if( gridBillingSyntRef.current ){
            currentRef = gridBillingSyntRef.current;
        }

        return currentRef;
    }, []);

    const condition7 = useMemo(() => ( gridReportingRef.current && formIsSubmitted ), [gridReportingRef, formIsSubmitted]);

    useEffect(() => {
        setGridApi(getCurrentRef());
        
        if( condition7 ){
            setTimeout(() => { gridReportingRef.current.api.setColumnDefs(newColumnDefs) }, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelectedMenu, condition7])

    const condition8 = useMemo(() => ( reportingRowData.length ), [reportingRowData]);

    useEffect(() => {
        if( condition8 ){
            if( externalFilters.searchbar && externalFilters.user ){
                userTypeRef.current = externalFilters.user;

                if( gridApi ){
                    gridApi.api.setQuickFilter(externalFilters.searchbar);
                    gridApi.api.onFilterChanged();
                } else {
                    const filteredData = reportingRowData.filter(data => {
                        const dataValues = Object.values(data).filter(e => e).map(el => el.toString().toLowerCase());

                        const searchbarToLower = externalFilters.searchbar.toLowerCase();
                        const userToLower = externalFilters.user.toLowerCase();

                        return [searchbarToLower, userToLower].every(el => dataValues.includes(el));
                    })

                    setReportingRowDataFiltered(filteredData);
                }
            } else if( externalFilters.searchbar ){
                if( gridApi ){
                    gridApi.api.setQuickFilter(externalFilters.searchbar);
                    gridApi.api.onFilterChanged();
                } else {
                    const filteredData = reportingRowData.filter(data => {
                        const dataValues = Object.values(data).filter(e => e).map(el => el.toString().toLowerCase());

                        return dataValues.some(el => el.includes(externalFilters.searchbar.toLowerCase()));
                    })

                    setReportingRowDataFiltered(filteredData);
                }
            } else if( externalFilters.user ){
                userTypeRef.current = externalFilters.user;
        
                if( gridApi ){
                    gridApi.api.onFilterChanged();
                } else {
                    const filteredData = reportingRowData.filter(data => {
                        const { user } = data;

                        if( externalFilters.user === 'all' ) return user || user === null;
                        if( externalFilters.user === 'no_affect' ) return user === null;

                        return user && user === externalFilters.user;
                    })

                    setReportingRowDataFiltered(filteredData);
                }
            } else {
                userTypeRef.current = 'all';

                if( gridApi ){
                    gridApi.api.setQuickFilter("");
                    // gridApi.api.onFilterChanged();
                } else {
                    setReportingRowDataFiltered(reportingRowData);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalFilters, currentSelectedMenu, gridApi, reportingRowData])

    const condition9 = useMemo(() => ( isEmptyArray(users) && isNonEmptyArray(checkedList) ), [users, checkedList]);

    useEffect(() => {
        setExportIsDisable(isEmptyArray(users));
        if( condition9 ) onResetAll(); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, condition9])

    useEffect(() => {
        setActiveLeftMenuKeyGroup(currentSelectedMenu.split('_')[0]);
        if( !['reporting_table', 'billing_int', 'billing_ext'].includes(currentSelectedMenu) && attrAreVisible ){
            setAttrAreVisible(!attrAreVisible);
        } 
     
        if( ['reporting_table', 'billing_int', 'billing_ext'].includes(currentSelectedMenu) && attrAreVisible && gridApi ){
            gridApi.columnApi.applyColumnState({
                state: [
                    { colId: 'atr_propart', hide: !attrAreVisible },
                    { colId: 'atr_zonegeo', hide: !attrAreVisible },
                    { colId: 'atr_mission', hide: !attrAreVisible }
                ],
            });
        } 
        if( !['reporting_table'].includes(currentSelectedMenu) ){
            setAssignmentIsVisible(false);
            setPriorityIsVisible(false);
        } 
        if( ['reporting_table'].includes(currentSelectedMenu) && gridApi ){
            gridApi.columnApi.applyColumnState({
                state: [
                    { colId: 'assignment', hide: !assignmentIsVisible },
                    { colId: 'priority', hide: !priorityIsVisible },
                ],
            });
        } 
    }, [currentSelectedMenu, attrAreVisible, assignmentIsVisible, priorityIsVisible, gridApi])

    const condition10 = useMemo(() => ( activeLeftMenuKeyGroup !== 'reporting' && externalFilters.user !== null ), [activeLeftMenuKeyGroup, externalFilters.user]);

    useEffect(() => {
        if( condition10 ) setExternalFilter(externalFilters => ({ ...externalFilters, 'user': initialExternalFiltersState.user }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition10])

    const condition11 = useMemo(() => ( gridApi && currentSelectedMenu === 'billing_synt' ), [gridApi, currentSelectedMenu]);

    useEffect(() => {  // Gère le colonage
        if( condition11 ){
            if( isNonEmptyArray(billingRowData['LOCAL']) && !alertsIsDisplayed.localFeeIsMissing ){
                const find = billingRowData['LOCAL'].findIndex(el => el.rate === 0);

                if( find !== -1 ){
                    Message.error({
                        content: (
                            <>
                                Attention ! L'un des taux de l'onglet <span className="bld-txt">Encaissement chez Recouvéo</span> n'est pas défini
                            </>
                        ),
                        duration: 5
                    });
                    setAlertIsDisplayed(alertsIsDisplayed => ({...alertsIsDisplayed, 'localFeeIsMissing': true}));
                }
            }

            if( isNonEmptyArray(billingRowData['REMOTE']) && !alertsIsDisplayed.remoteFeeIsMissing ){
                const find = billingRowData['REMOTE'].findIndex(el => el.rate === 0);

                if( find !== -1 ){
                    Message.error({
                        content: (
                            <>
                                Attention ! L'un des taux de l'onglet <span className="bld-txt">Encaissement chez Créancier</span> n'est pas défini
                            </>
                        ),
                        duration: 5
                    });
                    setAlertIsDisplayed(alertsIsDisplayed => ({...alertsIsDisplayed, 'remoteFeeIsMissing': true}));
                }
            }

            let columnDefsBillingsSyntCopy = shallowCopy(columnDefsBillingsSynt);
            const initialValue = {
                headerName: '', children: [
                    { 
                        headerName: "", 
                        width: 50, 
                        lockPosition: "left", 
                        pinned: "left", 
                        headerCheckboxSelection: true, 
                        headerCheckboxSelectionFilteredOnly: true, 
                        checkboxSelection: true 
                    }
                ]
            };
            let tmpObj = {};
    
            switch( viewMode ){
                case 'entity_view' :
                    tmpObj = {
                        headerName: 'Créancier', children: [
                            { headerName: 'Entité', field: 'soc_id' },
                            { headerName: 'Nom', field: 'soc_name' }
                        ]
                    };
                    break;
                case 'account_view' :
                    tmpObj = {
                        headerName: 'Compte', children: [
                            { headerName: 'N° client', field: 'acc_id' },
                            { headerName: 'Nom', field: 'acc_name' }
                        ]
                    };
                    break;
                case 'affect_view' :
                    tmpObj = {
                        headerName: '', children: [
                            { headerName: 'Affectation', field: 'user' }
                        ]
                    };
                    break;
                case 'record_view' :
                    tmpObj = {
                        headerName: 'Facture', children: [
                            { headerName: 'N° client', field: 'acc_id' },
                            { headerName: 'Nom', field: 'acc_name' },
                            { headerName: 'N° Facture', field: 'record_ref' },
                            { headerName: 'Montant', field: 'amount', type: 'valueColumn' },
                            { headerName: 'Montant dû', field: 'amount_due', type: 'valueColumn' }
                        ]
                    };
                    break;
                default :
                    break;
            }

            columnDefsBillingsSyntCopy = [initialValue, tmpObj, ...columnDefsBillingsSyntCopy];

            gridApi.api.setColumnDefs(columnDefsBillingsSyntCopy)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition11, viewMode])

    const condition12 = useMemo(() => ( isNonEmptyArray(billingRowData['BOTH']) ), [billingRowData]);

    useEffect(() => {  // Gère le tri de la data
        if( condition12 ){
            const  billingRowDataCopy = [...billingRowData['BOTH']];
    
            let tempObj = {};

            billingRowDataCopy.forEach((data, idx) => {
                let dataKey = data[viewModeTranslate[viewMode]];

                if( !tempObj[dataKey] ){
                    tempObj[dataKey] = {};
                }

                Object.entries(data).forEach(([key, value]) => {
                    if( typeof value === 'number' ){
                        if( !tempObj[dataKey][key] ){
                            tempObj[dataKey][key] = 0;
                        }
    
                        tempObj[dataKey][key]+= value;
                    } else {
                        if( !tempObj[dataKey][key] ){
                            tempObj[dataKey][key] = '';
                        }

                        tempObj[dataKey][key] = value;
                    }
                })
            })

            setBillingRowDataByViewMode(Object.values(tempObj));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition12, viewMode])

    // PRIMARY FORM
    const onClearBases = useCallback(() => {
        setBasesComeFromCleared(true);

        setTimeout(() => {
            if( isNonEmptyArray(fields.entities) ){
                setFields(fields => ({...fields, 'entities': []}));
            }
            setEntities([]);
        }, 100);
    }, [fields.entities])

    //EXTERNAL FILTERS
    const handleExternalFilterChange = useCallback(( value, name ) => {
        switch( value ){
            case 'Toutes affectations' :
                value = 'all';
                break;
            case 'Sans affectation' :
                value = 'no_affect';
                break;
            default : 
                break;
        }

        setExternalFilter(externalFilters => ({...externalFilters, [name]: value}))
    }, []);

    const isExternalFilterPresent = ( ) => activeLeftMenuKeyGroup === 'reporting' && userTypeRef.current !== 'all';

    const doesExternalFilterPass = ( node ) => {
        const userRef = userTypeRef.current;
        const userNode = node.data.user;

        if( userRef ){
            if( !['all', 'no_affect'].includes(userRef) ) return userRef === userNode;
            if( userRef === 'no_affect' ) return userNode === '' || userNode === null || userNode === undefined;
        }
        return true;
    }

    const getItem = useCallback(( label, key, icon, hidden, children, type ) => ({label, key, icon, hidden, children, type}), []);

    let leftMenuItems = useMemo(() => ([
        getItem('Reporting', 'reporting', <ReportingIcon width={36} height={36}/>, false, [
            getItem('Tableau', 'reporting_table', null, false),
            getItem('Graphiques', 'reporting_charts', null, false),
        ]),
        getItem('Facturation', 'billing', <BillingIcon width={36} height={36}/>, !hasPermission(['reporting', 'reporting_billing'], user), [
            getItem('Encaissements chez Recouvéo', 'billing_int', null, false),
            getItem('Encaissements chez Créancier', 'billing_ext', null, false),
            getItem('Synthèse', 'billing_synt', null, false),
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ].filter(el => !el.hidden)),[user])

    const toggleRenderComponent = (  ) => {
        switch( currentSelectedMenu ){
            case 'reporting_table' :
                return (
                    <AgGrid 
                        gridRef={gridReportingRef} 
                        columnDefs={fetchColumnDefs}
                        createPinData={createPinDataReporting}
                        createPinDataExtraParams={fetchTableFields}
                        fetchData={reportingRowData}
                        updateRowData={updateRowData}
                    />
                ) ;
            case 'reporting_charts' :
                return (
                    <>
                        {
                            formIsSubmitted 
                            ?
                                <Spin/>
                            :
                                <>
                                    {
                                        chartsDataIsNonEmpty 
                                        ?
                                            <>
                                                <Recharts 
                                                    id="gen-chart"
                                                    fetchData={chartsData.gen_chart} 
                                                    fetchColors={chartsColors.gen_chart} 
                                                    buildPdfIsPending={buildPdfIsPending}
                                                    optionsPanelIsOpen={optionsPanelIsOpen}
                                                />
                                                <Recharts 
                                                    id="oth-chart"
                                                    fetchData={chartsData.oth_chart} 
                                                    fetchColors={chartsColors.oth_chart} 
                                                    buildPdfIsPending={buildPdfIsPending}
                                                    optionsPanelIsOpen={optionsPanelIsOpen}
                                                />
                                                <Recharts 
                                                    id="ant-chart"
                                                    fetchData={chartsData.ant_chart} 
                                                    fetchColors={chartsColors.ant_chart} 
                                                    buildPdfIsPending={buildPdfIsPending}
                                                    optionsPanelIsOpen={optionsPanelIsOpen}
                                                />
                                            </>
                                        :
                                            <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                                Veuillez lancer une requête sur l'onglet&nbsp;<span className="bld-txt">Tableau</span>, aucunes données à afficher pour le moment
                                            </div>
                                    }
                                </>
                        }
                    </>
                ) ;
            case 'billing_int' :
                return (
                    <AgGrid 
                        gridRef={gridBillingLocalRef} 
                        columnDefs={columnDefsBillings}
                        createPinData={createPinDataBilling}
                        fetchData={billingRowData['LOCAL']}
                    />
                ) ;
            case 'billing_ext' :
                return (
                    <AgGrid 
                        gridRef={gridBillingRemoteRef} 
                        columnDefs={columnDefsBillings}
                        createPinData={createPinDataBilling}
                        fetchData={billingRowData['REMOTE']}
                    />
                ) ;
            case 'billing_synt' :
                return (
                    <AgGrid 
                        gridRef={gridBillingSyntRef} 
                        columnDefs={columnDefsBillingsSynt}
                        createPinData={createPinDataBillingSynt}
                        createPinDataExtraParams={pinDataExtraParamsBillingSynt}
                        fetchData={billingRowDataByViewMode}
                    />
                ) ;
            
            default :
                return ;
        }
    };

    const onChange = ( value, field ) => {
        setFields({...fields, [field]: !isArray(value) ? [value] : value});
    }

    const onClear = ( value, field ) => {
        if( !value ) setFields({...fields, [field]: []});
    }

    const onSubmit = ( ) => {
        let isValid = false;

        const requiredKeys = Object.keys(fields).filter(field => field !== "constructDates");

        if( requiredKeys.every(key => isNonEmptyArray(fields[key])) ){
            isValid = true;
        }

        if( !isValid ){
            Message.error("Le formulaire est incorrecte !");
        } else {
            Message.success("Le formulaire à été soumit !");
            
            resetFields();
        }
    }

    // EXCEL EXPORT
    const rowsAreSelected = useCallback((  ) => {
        const rows = gridApi.api.getSelectedRows();

        return isNonEmptyArray(rows);
    }, [gridApi])

    const getParams = useCallback((  ) => {
        const columnsToExport = gridApi.columnApi.getColumns()
            .filter(el => el.colDef.headerName !== '' && el.visible !== false);

        return {
            skipColumnGroupHeaders: checkedList.includes("columnGroups"),
            skipColumnHeaders: checkedList.includes("skipHeader"),
            skipPinnedTop: checkedList.includes("skipPinnedTop"),
            onlySelected: rowsAreSelected(),
            columnKeys: columnsToExport,
            author: 'Veostack',
            processCellCallback: ( params ) => {
                const value = params.value;
                const colId = params.column.colId;
                const column = params.columnApi.getColumn(colId);
                const colIndex = params.columnApi.getAllDisplayedColumns().indexOf(column);

                if ( params.node.rowPinned && colIndex === 1 ) {
                    return 'Total: ';
                }
                return value;
            },
            ...excelCustomParams
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedList, excelCustomParams, gridApi])

    const onBtnExport = () => {
        if( gridApi ) gridApi.api.exportDataAsExcel(getParams())
    }

    const clientNameToExport = useMemo(() => {
        if( isNonEmptyArray(reportingRowData) && isNonEmptyArray(entitiesTranslate) ){
            let uniqueSocIds = reportingRowData
                .map(data => data.soc_id)
                .filter((value, index, self) => value && self.indexOf(value) === index);

            const entitiesTranslateReduced = entitiesTranslate.reduce((acc, val) => {
                const { soc_id, soc_parent_id } = val;

                if( !uniqueSocIds.includes(soc_id) ) return acc;

                let key = soc_id;

                if( soc_parent_id ) key = soc_parent_id;

                if( !acc[key] ){
                    acc[key] = [];
                }

                acc[key].push(val);

                return acc;
            }, {})

            if( Object.keys(entitiesTranslateReduced).length > 1 ){
                return null;  // Case multiple choosen entities
            } else if( Object.keys(entitiesTranslateReduced).length === 1 ){
                const values = Object.values(entitiesTranslateReduced)[0];

                if( values.length > 1 ){
                    if( values[0]['soc_parent_id'] ){
                        const findParent = entitiesTranslate.find(entity => entity.soc_id === values[0]['soc_parent_id']);

                        if( findParent !== undefined ){
                            const pattern = /Global/i;
                            return findParent.soc_name.replace(pattern, '').trim();
                        } 
                    } else {
                        return values[0]['soc_name'];
                    }
                } else if( values.length === 1 ){
                    return values[0]['soc_name'];
                } 
            }
        }
        return null;
    }, [reportingRowData, entitiesTranslate])

    useEffect(() => {
        let fileName = '', sheetName = 'Encaissement ', suffix = '';
        if( clientNameToExport ){
            const clientName = clientNameToExport.replaceAll(".", "");
            fileName+= clientName;
        } 
        if( fileName.length > 0 ){
            fileName+= ' - Encaissement ';
        } else {
            fileName+= 'Encaissement ';
        }
        
        switch( currentSelectedMenu ){
            case 'billing_int':
                suffix = 'Recouvéo';
                fileName+= suffix;
                sheetName+= suffix;
                setExcelCustomParams({'fileName': fileName, 'sheetName': sheetName});
                break;
            case 'billing_ext':
                suffix = 'chez vous';
                fileName+= suffix;
                sheetName+= suffix;
                setExcelCustomParams({'fileName': fileName, 'sheetName': sheetName});
                break;
            case 'billing_synt':
                setViewMode('entity_view');
                setExcelCustomParams(initialExcelCustomParamsState);
                break;
            default: 
                setExcelCustomParams(initialExcelCustomParamsState);
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelectedMenu, clientNameToExport])

    // OPTIONS PANEL
    const handleToggleAttr = useCallback(() => {
        setAttrAreVisible(!attrAreVisible);
        if( gridApi ){
            gridApi.columnApi.applyColumnState({
              state: [
                { colId: 'atr_propart', hide: attrAreVisible },
                { colId: 'atr_zonegeo', hide: attrAreVisible },
                { colId: 'atr_mission', hide: attrAreVisible }
              ],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attrAreVisible, gridApi]);

    const handleToggleAssignment = useCallback(() => {
        setAssignmentIsVisible(!assignmentIsVisible);
        if( gridApi ){
            gridApi.columnApi.applyColumnState({
                state: [
                { colId: 'assignment', hide: assignmentIsVisible },
                ],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignmentIsVisible, gridApi])

    const handleTogglePriority = useCallback(() => {
        setPriorityIsVisible(!priorityIsVisible);
        if( gridApi ){
            gridApi.columnApi.applyColumnState({
                state: [
                { colId: 'priority', hide: priorityIsVisible },
                ],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priorityIsVisible, gridApi])

    // PDF BUILDER 
    const reportingAtToPdf = useMemo(() => {
        if( isNonEmptyArray(fields.displayDates) ){
            // return dayjs(fields.displayDates[1]).format(formatFR)
            return dayjs(fields.displayDates[1]).format(formatEN)
        }
        return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields.displayDates]);

    const chartsTxtConfig = useMemo(() => {
        const extraDataPay = ( chartsPdfData.gen_chart.extraData.hasOwnProperty('PAY') ? chartsPdfData.gen_chart.extraData['PAY'] : null );

        let othChartSecondTxt = "";

        if( chartsPdfData.oth_chart.collectPart ){
            othChartSecondTxt+= translate("PdfExport.thirdPageFooterFirstLineFirstPart") + ` ${chartsPdfData.oth_chart.collectPart}, ` + translate("General.either") + ` ${chartsPdfData.oth_chart.collectFormatted}`;
        }

        if( extraDataPay ){
            othChartSecondTxt+= " (" + translate("General.ofWhich") + ` ${extraDataPay} ` + translate("PdfExport.thirdPageFooterFirstLineLastPart") + ")";
        }

        if( othChartSecondTxt.length ) othChartSecondTxt+= '.';

        let othChartThirdTxt = "";

        if( chartsPdfData.oth_chart.chart02InPendingPart ){
            othChartThirdTxt+= `${chartsPdfData.oth_chart.chart02InPendingPart} ` + translate("PdfExport.thirdPageFooterSecondLineFirstPart") + ` (${chartsPdfData.oth_chart.chart02InPendingFormatted}) ` + translate("PdfExport.thirdPageFooterSecondLineSecondPart");
        }

        if( chartsPdfData.oth_chart.extPart ){
            othChartThirdTxt+= " " + translate("General.ofWhich") + ` ${chartsPdfData.oth_chart.extPart} ` + translate("PdfExport.thirdPageFooterSecondLineLastPart");
        }
        if( chartsPdfData.oth_chart.judicPart ){
            if( !chartsPdfData.oth_chart.extPart ){
                othChartThirdTxt+= " " + translate("General.ofWhich");    
            } else {
                othChartThirdTxt+= " " + translate("General.and");    
            }
            othChartThirdTxt+= ` ${chartsPdfData.oth_chart.judicPart} ` + translate("PdfExport.thirdPageFooterSecondLineOptionalPart");
        }

        if( othChartThirdTxt.length ) othChartThirdTxt+= ".";

        return {
            othChartSecondTxt,
            othChartThirdTxt
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartsPdfData, translations]);

    // const chartsMainElements = useMemo(() => {
    //     const data = {
    //         clientNameToExport,
    //         reportingAtToPdf
    //     }

    //     let stopTxt = translate("PdfExport.fifthPageTableColumnEight");

    //     if(clientNameToExport){
    //         stopTxt+= " " + translate("General.by") + ` ${clientNameToExport}`;
    //     }

    //     const tableConfig = {
    //         columns: [
    //             { header: translate("PdfExport.fifthPageTableColumnOne"), dataKey: 'load_period' },
    //             { header: translate("PdfExport.fifthPageTableColumnTwo"), dataKey: 'sum_due' },
    //             { header: translate("PdfExport.fifthPageTableColumnThree"), dataKey: 'sum_collected' },
    //             { header: translate("PdfExport.fifthPageTableColumnFour"), dataKey: 'fees' },
    //             { header: translate("PdfExport.fifthPageTableColumnFive"), dataKey: 'sum_old_avr' },
    //             { header: translate("PdfExport.fifthPageTableColumnSix"), dataKey: 'sum_ci' },
    //             { header: translate("PdfExport.fifthPageTableColumnSeven"), dataKey: 'sum_dr' },
    //             { header: `${stopTxt}`, dataKey: 'sum_stop' },
    //             { header: translate("PdfExport.fifthPageTableColumnNine"), dataKey: 'sum_judic' },
    //             { header: translate("PdfExport.fifthPageTableColumnTen"), dataKey: 'sum_rest' }
    //         ],
    //         body: chartsPdfData.tableBody,
    //         footer: chartsPdfData.tableFooter
    //     }

    //     return {
    //         data,
    //         tableConfig
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [chartsPdfData, clientNameToExport, reportingAtToPdf, translations]);

    // const onBtnExportPdf = (  ) => {
    //     setBuildPdfIsPending(true);
    //     setTimeout(() => { exportPdf() }, 0);
    // } 

    const onBtnExportPdf = () => {
        initiateDownload(fields.bases, clientNameToExport, reportingAtToPdf);
    }

    // async function exportPdf() {
    //     const doc = new jsPDF('l', 'mm', 'a4', true, true);
        
    //     const elements = [
    //         {
    //             item: document.getElementById('gen-chart'),
    //             texts: [
    //                 {
    //                     txt: translate("PdfExport.secondPageHeader"),
    //                     fontWeight: 'bold',
    //                     ...chartsPdfConfig.firstTextOptions
    //                 },
    //                 {
    //                     txt: translate("PdfExport.secondPageFooterFirstLine") + ` ${chartsPdfData.gen_chart.totalEntrusted}`,
    //                     fontWeight: 'bold',
    //                     ...chartsPdfConfig.secondTextOptions
    //                 },
    //                 {
    //                     txt: chartsPdfData.gen_chart.allLessOtherPart ? `${chartsPdfData.gen_chart.allLessOtherPart} (${chartsPdfData.gen_chart.allLessOtherFormatted}) ` + translate("PdfExport.secondPageFooterSecondLine") : null,
    //                     fontWeight: 'bold',
    //                     color: '#ff1a30',
    //                     ...chartsPdfConfig.thirdTextOptions
    //                 }
    //             ]
    //         },
    //         {
    //             item: document.getElementById('oth-chart'),
    //             texts: [
    //                 {
    //                     txt: translate("PdfExport.thirdPageHeader"),
    //                     fontWeight: 'bold',
    //                     ...chartsPdfConfig.firstTextOptions
    //                 },
    //                 {
    //                     txt: chartsTxtConfig.othChartSecondTxt.length ? `${chartsTxtConfig.othChartSecondTxt}` : null,
    //                     fontWeight: 'bold',
    //                     ...chartsPdfConfig.secondTextOptions
    //                 },
    //                 {
    //                     txt: chartsTxtConfig.othChartThirdTxt.length ? `${chartsTxtConfig.othChartThirdTxt}` : null,
    //                     ...chartsPdfConfig.thirdTextOptions
    //                 }
    //             ]
    //         },
    //         {
    //             item: document.getElementById('ant-chart'),
    //             texts: [
    //                 {
    //                     txt: translate("PdfExport.fourthPageHeader"),
    //                     fontWeight: 'bold',
    //                     ...chartsPdfConfig.firstTextOptions
    //                 },
    //                 {
    //                     txt: chartsPdfData.ant_chart.chart03More180Part ? `${chartsPdfData.ant_chart.chart03More180Part} ` + translate("PdfExport.fourthPageFooterFirstLine") : null,
    //                     ...chartsPdfConfig.secondTextOptions
    //                 }
    //             ]
    //         }
    //     ];

    //     let data = chartsMainElements.data;
    //     const tableConfig = chartsMainElements.tableConfig;

    //     await buildPdf({ doc, elements, data, tableConfig, translate }); 

    //     doc.save(`Rapport_${today}.pdf`);

    //     setBuildPdfIsPending(false);
    // }

    // NOTIFICATIONS MODAL
    const handleToggleModal = (  ) => {
        if( notificationsCount ){
            setNotificationsModalIsOpen(!notificationsModalIsOpen);
            return ;     
        }
        return undefined ;
    }

    return(
        <div className="h-100">
            <Form 
                name="get-reporting" 
                className="w-100"
                onFinish={onSubmit}
            >
                <Row className="justify-content-center">
                    <FormRow
                        width="15%"
                        topTextConfig={{
                            topText: "Base",
                            topRequired: true
                        }}
                        bottomTextConfig={{
                            bottomText: "",
                            bottomIsAlert: true
                        }}
                    >
                        <Select
                            options={bases}
                            placeholder="Sélection"
                            size="large"
                            className="w-100"
                            filterOption={(inputValue, option) =>
                                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                            }
                            mode="multiple"
                            maxTagCount="responsive"
                            allowClear={true}
                            clearIcon={<Delete {...clearIconStyle}/>}
                            dropdownRender={(menu) => (
                                <>
                                    {
                                        isNonEmptyArray(bases) 
                                        ?
                                            menu
                                        :
                                            <Spin/>
                                    }
                                </>
                            )}
                            onChange={(value) => onChange(value, "bases")}
                            onClear={onClearBases}
                            onDeselect={() => setBasesComeFromDeselected(true)}
                        />
                    </FormRow>
                    <FormRow
                        width="15%"
                        topTextConfig={{
                            topText: "Entités",
                            topRequired: true
                        }}
                        bottomTextConfig={{
                            bottomText: "",
                            bottomIsAlert: true
                        }}
                    >
                        <TreeSelect
                            fetchData={entities}
                            value={fields.entities}
                            onChange={(value) => onChange(value, "entities")}
                            dependencyIsSelected={isNonEmptyArray(fields.bases)}
                            handleDependencyComeFromCleared={[
                                basesComeFromCleared,
                                setBasesComeFromCleared
                            ]}
                            handleDependencyComeFromDeselected={[
                                basesComeFromDeselected,
                                setBasesComeFromDeselected
                            ]}
                        />
                    </FormRow>
                    {
                        activeLeftMenuKeyGroup && activeLeftMenuKeyGroup === 'reporting' &&
                            <FormRow
                                topTextConfig={{
                                    topText:
                                        <span className="d-flex align-items-center">
                                            Période de la mission
                                            <Popover content={popoverContent}>
                                                <Info height={15} width={15} className={"ms-1 info-svg"}/>
                                            </Popover>
                                        </span>
                                    ,
                                    topRequired: false
                                }}
                                bottomTextConfig={{
                                    bottomText: "",
                                    bottomIsAlert: false
                                }}
                            >
                                <RangePicker
                                    disabledDate={disableAntdPickerDates}
                                    renderExtraFooter={() =>
                                        <Footer 
                                            selectedDates={fields.constructDates}
                                            setSelectedDates={{
                                                onChange,
                                                field: "constructDates"
                                            }}
                                        />
                                    }
                                    size="large"
                                    value={fields.constructDates}
                                    format={formatFR}
                                    clearIcon={<Delete {...clearIconStyle}/>}
                                    onCalendarChange={(dates) => onChange(dates, "constructDates")} // fired when one of two dates changes
                                    onChange={(value) => onClear(value, "constructDates")}
                                />
                            </FormRow>
                    }
                    <FormRow
                        topTextConfig={{
                            topText: activeLeftMenuKeyGroup && activeLeftMenuKeyGroup === 'reporting' ? "Période du reporting" : "Période de la facturation",
                            topRequired: true
                        }}
                        bottomTextConfig={{
                            bottomText: "",
                            bottomIsAlert: true
                        }}
                    >
                        <RangePicker
                            disabledDate={disableAntdPickerDates}
                            renderExtraFooter={() =>
                                <Footer 
                                    selectedDates={fields.displayDates}
                                    setSelectedDates={{
                                        onChange,
                                        field: "displayDates"
                                    }}
                                />
                            }
                            size="large"
                            value={fields.displayDates}
                            format={formatFR}
                            clearIcon={<Delete {...clearIconStyle}/>}
                            onCalendarChange={(dates) => onChange(dates, "displayDates")}
                            onChange={(value) => onClear(value, "displayDates")}
                        />
                    </FormRow>
                    <FormRow>
                        <Button
                            htmlType="submit"
                            size="large"
                            type="primary"
                            icon={<RightArrow height={18} width={18} viewBox="0 0 40 50"/>}
                        />
                    </FormRow>
                </Row>
            </Form>
            <div className="d-flex align-items-center mb-3 mx-auto" style={{height: '32px', width: '80%'}}>
                {
                    notificationsCount
                    ?
                        <>
                            <Divider className="m-0" style={{width: '47.5%', minWidth: '0%'}}/>
                            <Badge count={notificationsCount} offset={[-2,0]}>
                                <Button 
                                    className="mx-2" 
                                    icon={<Notifications height={18} width={18}/>} 
                                    type="primary" 
                                    shape="circle"
                                    onClick={handleToggleModal}
                                />
                                <Modal 
                                    open={notificationsModalIsOpen}
                                    footer={null}
                                    closable={false}
                                    title={(
                                        <div className="d-flex">
                                            Notifications
                                            <span className="ms-auto">
                                                <Close 
                                                    height={24} 
                                                    width={24}
                                                    style={{
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={handleToggleModal}    
                                                />
                                            </span>
                                        </div>
                                    )}
                                    // mask={false}
                                    width={600}
                                    className="notificationsModal"
                                    style={{
                                        top: 20,
                                        right: "1%",
                                        position: "absolute",
                                        margin: 0
                                    }}
                                    onCancel={handleToggleModal}
                                >
                                    <ModalContent fetchData={notifications}/>
                                </Modal>
                            </Badge>
                            <Divider className="m-0" style={{width: '47.5%', minWidth: '0%'}}/>
                        </>
                    :
                        <Divider className="m-0" style={{minWidth: '0%'}}/>
                    
                }
            </div>
            <Row style={{height: "calc(100% - 140px)"}} gutter={16}>
                <Col xs={4}>
                    <Menu
                        onSelect={({ key }) => setCurrentSelectedMenu(key)}
                        className="h-100"
                        defaultSelectedKeys={[defaultSelectedMenu]}
                        defaultOpenKeys={[defaultOpenedMenu]}
                        mode="inline"
                        items={leftMenuItems}
                        expandIcon={({ isOpen }) => <DropdownArrow transform={isOpen ? "rotate(180)" : undefined} className="expand-icon"/>}
                    />
                </Col>
                <Col xs={optionsPanelIsOpen ? 3 : 0}>
                    <div style={{minHeight: "120px"}}>
                        <CCollapse horizontal visible={optionsPanelIsOpen}>
                            <Card bordered={false} style={{width: "250px", boxShadow: "none"}}>
                                <Divider orientation="left">Filtres</Divider>
                                <Space direction="vertical">
                                    <Input 
                                        placeholder="Votre recherche..."
                                        value={externalFilters.searchbar}
                                        allowClear={{clearIcon: <Delete {...clearIconStyle}/>}}
                                        onChange={({ currentTarget }) => handleExternalFilterChange(currentTarget.value, "searchbar")}
                                    />
                                    {
                                        activeLeftMenuKeyGroup === 'reporting' &&
                                            <Select 
                                                placeholder="Affectation"
                                                value={externalFilters.user}
                                                className="w-100"
                                                // disabled={activeLeftMenuKeyGroup !== "reporting"}
                                                onChange={(_, opt) => handleExternalFilterChange(opt.children, "user")}
                                                virtual={false}
                                                filterOption={(inputValue, option) =>
                                                    option.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                }
                                            > 
                                                {
                                                    isNonEmptyArray(users) &&
                                                        <>
                                                            <Option value="all">Toutes Affectations</Option>
                                                            <Option value="no_affect">Sans affectation</Option>
                                                            <OptGroup 
                                                                label={<Divider className="my-1"/>}
                                                            >
                                                            {
                                                                users.map((user, index) => {
                                                                    const { label, value } = user;

                                                                    return(
                                                                        <Option key={index} value={value}>{label}</Option>
                                                                    );
                                                                }) 
                                                            } 
                                                            </OptGroup>
                                                        </>
                                                }
                                            </Select>

                                    }
                                </Space>

                                {
                                    currentSelectedMenu === 'billing_synt' && 
                                        <>
                                            <Divider orientation="left">Vues</Divider>
                                            <Space 
                                                direction="vertical" 
                                                style={{ display: 'flex' }}
                                            >
                                                <Select 
                                                    options={viewModeOptions}
                                                    defaultValue={viewModeDefaultValue}
                                                    className="w-100"
                                                    onChange={setViewMode}
                                                >
                                                    
                                                </Select>
                                            </Space>
                                        </>
                                }

                                {
                                    ['reporting_table', 'billing_int', 'billing_ext'].includes(currentSelectedMenu) &&
                                        <>
                                            <Divider orientation="left">Colonnes</Divider>
                                            <Space
                                                direction="vertical"
                                                size="small"
                                                style={{ display: 'flex' }}
                                            >
                                                <Checkbox 
                                                    checked={attrAreVisible}
                                                    onChange={handleToggleAttr}
                                                >
                                                    { attrAreVisible ? 'Masquer les colonnes d\'attribut' : 'Afficher les colonnes d\'attribut' }
                                                </Checkbox>
                                                {
                                                    ['reporting_table'].includes(currentSelectedMenu) &&
                                                        <>
                                                            <Checkbox 
                                                                checked={assignmentIsVisible}
                                                                onChange={() => handleToggleAssignment()}
                                                            >
                                                                { assignmentIsVisible ? 'Masquer la colonne d\'affectation' : 'Afficher la colonne d\'affectation' }
                                                            </Checkbox>
                                                            <Checkbox 
                                                                checked={priorityIsVisible}
                                                                onChange={() => handleTogglePriority()}
                                                            >
                                                                { priorityIsVisible ? 'Masquer la colonne de priorité de relance' : 'Afficher la colonne de priorité de relance' }
                                                            </Checkbox>
                                                        </>
                                                }
                                            </Space>
                                        </>
                                }   

                                <Divider 
                                    orientation="left" 
                                    onClick={() => setExportPanelIsOpen(!exportPanelIsOpen)}
                                >
                                    <span role="button">
                                        Exportation 
                                    </span>
                                    <DropdownArrow transform={exportPanelIsOpen ? "rotate(180)" : undefined} className="expand-icon"/>
                                </Divider>
                                <CCollapse visible={exportPanelIsOpen}>
                                    <Space direction="vertical" className="w-100">
                                        {
                                            gridApi
                                            ?
                                                <>
                                                    <Checkbox
                                                        indeterminate={indeterminate}
                                                        onChange={onCheckboxGroupAllChange}
                                                        checked={checkAll}
                                                        disabled={exportIsDisable}
                                                    >
                                                        {
                                                            checkAll
                                                            ?
                                                                'Tout désélectionner'
                                                            :
                                                                'Tout sélectionner'
                                                        }
                                                    </Checkbox>
                                                    <CheckboxGroup
                                                        options={checkboxGroupOptions}
                                                        value={checkedList}
                                                        className={styles['vertical-checkbox-group']}
                                                        onChange={onCheckboxGroupChange}
                                                    />
                                                    <div className="mt-2" style={exportIsDisable ? {cursor: 'not-allowed'} : undefined}>
                                                        <Button 
                                                            type="primary"
                                                            danger={exportIsDisable} 
                                                            icon={<DownloadXlsx height={22} width={22} className="me-1"/>}
                                                            className="w-100 d-flex justify-content-center"
                                                            style={exportIsDisable ? {pointerEvents: "none"} : undefined}
                                                            onClick={() => exportIsDisable ? undefined : onBtnExport()}
                                                        >
                                                            Exporter
                                                        </Button>
                                                    </div>
                                                </>
                                            :
                                                    <div className="mt-2" style={exportIsDisable ? {cursor: 'not-allowed'} : undefined}>
                                                        <Button 
                                                            type="primary"
                                                            danger={exportIsDisable} 
                                                            icon={<DownloadPdf height={22} width={22} className="me-1"/>}
                                                            loading={downloadPdfIsPending}
                                                            className="w-100 d-flex justify-content-center align-items-center"
                                                            style={exportIsDisable ? {pointerEvents: "none"} : undefined}
                                                            onClick={() => exportIsDisable ? undefined : onBtnExportPdf()}
                                                        >
                                                            Exporter
                                                        </Button>
                                                    </div>
                                        }
                                    </Space>
                                </CCollapse>
                            </Card>
                        </CCollapse>
                    </div>
                </Col>
                <Col flex="none">
                    <Row className="flex-column align-items-center h-100">
                        <Divider type="vertical" className="mb-2" style={{height: "15px"}}/>
                        <Button 
                            onClick={() => setOptionsPanelIsOpen(!optionsPanelIsOpen)} 
                            type="primary"
                            shape="circle"
                            icon={<OptionsPanel height={18} width={18}/>}
                        />
                        <Divider type="vertical" className="mt-2" style={{flex: 1}}/>
                    </Row>
                </Col>
                <Col flex={1}>
                    <GridContext.Provider value={{ gridOptions, isExternalFilterPresent, doesExternalFilterPass }}>
                        { toggleRenderComponent() }
                    </GridContext.Provider>
                </Col>
            </Row>
        </div>
    );
}