import { Table } from "antd";

export function summary(pageData){
    const arrayLength = pageData.length;
    let description = "";
    if( arrayLength > 1 ) {
        description = arrayLength + " licences";
    } else {
        description = arrayLength + " licence";
    }

    return(
        <Table.Summary fixed>
            <Table.Summary.Row style={{background: "#fafafa"}}>
                <Table.Summary.Cell>Total</Table.Summary.Cell>
                <Table.Summary.Cell><b>{ description }</b></Table.Summary.Cell>
            </Table.Summary.Row>
        </Table.Summary>
    );
}