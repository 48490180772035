import React, { useState } from "react";

import { ReactComponent as Translation } from "_assets/translation.svg";
import { TRANSLATES } from "_constants";
import { useTranslate } from "_contexts";

import { FloatButton } from "antd";

export const CustomFloatButton = (  ) => {
    const [ floatButtonIsOpen, setFloatButtonIsOpen ] = useState(false);

    const FloatButtonGroup = FloatButton.Group;

    const { lang, setLang } = useTranslate();

    const handleChangeLanguage = ( value ) => {
        setTimeout(() => {
            setFloatButtonIsOpen(!floatButtonIsOpen);
            setLang(value);
        }, 0)
    }

    return(
        <>
            <div style={{
                position: 'fixed', 
                height: '40px', 
                width: '40px',
                right: 24, 
                bottom: 56,
                zIndex: 98,
                borderRadius: '50%'
                }}
                onClick={() => setFloatButtonIsOpen(oldVal => !oldVal)}
            >
                <FloatButtonGroup
                    trigger="click"
                    type="primary"
                    style={{
                        right: 24,
                        bottom: 56
                    }}
                    open={floatButtonIsOpen}
                    icon={<Translation width={36} height={36} viewBox="24 0 100 100" />}
                >
                    {
                        TRANSLATES.map(t => {
                            const { key, value, icon } = t;
                            return(
                                <FloatButton 
                                    key={key} 
                                    icon={icon ? icon : undefined}
                                    onClick={() => handleChangeLanguage(value)}
                                />
                            );
                        })
                    }
                </FloatButtonGroup>
            </div>
            <div style={{
                position: 'fixed', 
                right: 24 + 12, 
                bottom: 32,
                zIndex: 98
            }}>
                <span style={{fontWeight: 'bold'}}>{lang.toUpperCase()}</span>
            </div>
        </>
    );
}