import { useMemo, useRef, useCallback } from "react";

import { CustomSpin as Spin } from "_components";
import { isNonEmptyArray } from "_helpers";

import { Checkbox, Col, DatePicker, Empty, Input, Row, Select, Space, Typography } from "antd";

import { ReactComponent as Delete } from "_assets/delete.svg";

export const CustomFormRow = ( {width, topTextConfig = { topText: "", topRequired: false }, bottomTextConfig = { bottomText: "", bottomIsAlert: false }, ...props} ) => {
    const { children } = props;
    const { topText, topRequired } = topTextConfig;
    const { bottomText, bottomIsAlert } = bottomTextConfig;

    let typoProperties = useMemo(() => {
        return {
            type: "text",
            className: "d-flex justify-content-center"
        }
    }, []);
    
    const CustomTypo = ( {...props} ) => {
        const { children, className } = props;
    
        if( className ) typoProperties.className = [typoProperties.className, className].join(" ");
    
        return <div {...typoProperties}>{children}</div>;
    };

    return(
        <Row style={{ width }}>
            <Col className ="mx-auto" style={{ width: "98%" }}>
                <CustomTypo>
                    {
                        topText
                        ?
                            topRequired
                            ?
                                <>{topText}<span className="red-txt">*</span></>
                            :
                                <>{topText}</>
                        :
                            <>&nbsp;</>
                    }
                </CustomTypo>
                {children}
                <CustomTypo >
                    {
                        bottomText 
                        ? 
                            bottomIsAlert
                            ?
                                <span className="red-txt">{bottomText}</span>
                            :
                                <>{bottomText}</>
                        :
                            <>&nbsp;</>
                    }
                </CustomTypo>
            </Col>
        </Row>
    );
};

// ************************** //

const { Text } = Typography;

export const InputText = ({ weight, required, labelSeparator = " : ", children, ...rest }) => {
    return (
        <Text 
            style={{ fontWeight: weight }} 
            {...rest}
        >
            {
                required
                ?
                    <>
                        { children }<span style={{ color: 'red' }}>*</span> { labelSeparator }
                    </>
                :
                    <>
                        { children } { labelSeparator }
                    </>
            }
            
        </Text>
    );
}

export const CustomFormItemByType = ({ 
    children, 
    className,
    classNameContainer,
    labelOrientation = "left",
    labelSeparator,
    mode,
    name, 
    options = [], 
    onChange, 
    onBlur,
    onKeyUp,
    placeholder,
    ratio = "8/16", 
    required,
    size,
    status,
    style,
    styleContainer,
    submitIsPending = false,
    type, 
    value,
    valuePosition = null,
    visibilityToggle,
    weight = "normal"
}) => {
    const InputPassWord = Input.Password;

    const inputRef = useRef();

    const colRatio = useMemo(() => {
        const splitRatio = ratio
            .split("/")
            .map(el => parseInt(el))
        return splitRatio;
    }, [ratio])

    const handleClick = useCallback(( ) => {
        inputRef.current.focus();
    }, [])

    const clearIconStyle = useMemo(() => ({
        height:"1em", width:"1em", fontSize:14 
    }), []);

    const modeMultipleProps = useMemo(() => {
        return (
            mode === 'multiple'
            ?
                {
                    maxTagCount: "responsive",
                    allowClear: true,
                    clearIcon: (<Delete {...clearIconStyle} />),
                    // showSearch: false, // Disable key controls
                    removeIcon: () => <></> // Disable each tags remove icon
                }
            :
                undefined
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode])

    const renderByType = ( ) => {
        switch( type ) {
            case 'inputpassword' :
                return (
                    <InputPassWord 
                        ref={inputRef} 
                        name={name} 
                        value={value}
                        className={className}
                        status={status}
                        visibilityToggle={visibilityToggle}
                        onChange={({ currentTarget }) => onChange(currentTarget)} 
                        onBlur={({ currentTarget }) => onBlur(currentTarget)} 
                        onKeyUp={onKeyUp}
                    />
                );
            case 'checkbox' :
                return (
                    <Checkbox 
                        ref={inputRef} 
                        name={name} 
                        className={className} 
                        onChange={({ target }) => onChange({ name: target.name, value: target.checked })} 
                    />
                );
            case 'datepicker' :
                return (
                    <DatePicker 
                        value={value}
                        placeholder={placeholder} 
                        format="DD/MM/YYYY" 
                        clearIcon={<Delete {...clearIconStyle} />}
                        className={["w-100", className].join(" ")}
                        onChange={(date) => onChange({ name, value: date, valuePosition })} 
                    />
                );
            case 'select' :
                return (
                    <Select 
                        ref={inputRef} 
                        className={["w-100", className].join(" ")}
                        style={style} 
                        value={value}
                        options={options} 
                        mode={mode}
                        size={size}
                        placeholder={placeholder} 
                        status={status}
                        onChange={(value) => onChange({ name, value })} 
                        onBlur={onBlur}
                        dropdownRender={(menu) => (
                            <>
                                {
                                    isNonEmptyArray(options) 
                                    ?
                                        menu
                                    :
                                    submitIsPending
                                    ?
                                        <Spin />
                                    :
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }
                            </>
                        )}
                        {...modeMultipleProps}
                    />
                ) ;
            case 'multicheckboxgroup' :
                return <Row className={className}>{ options }</Row>;
            default : 
                return (
                    <Input 
                        ref={inputRef} 
                        name={name} 
                        value={value}   
                        className={className}
                        status={status}
                        onChange={({ currentTarget }) => onChange(currentTarget)} 
                        onBlur={onBlur ? ({ currentTarget }) => onBlur(currentTarget) : undefined} 
                        onKeyUp={onKeyUp}
                    />
                );        
        }
    }

    return (
        <>
            {
                !['multicheckboxgroup', 'checkbox'].includes(type)
                ?
                    <Row 
                        align="middle" 
                        className={["w-100", ["top", "bottom"].includes(labelOrientation) ? "flex-column" :  "", classNameContainer].join(" ")} 
                        style={styleContainer}
                    >
                        {
                            children && ["left", "top"].includes(labelOrientation)
                            &&
                                <Col xs={colRatio[0]}>
                                    <InputText 
                                        onClick={handleClick} 
                                        weight={weight} 
                                        required={required} 
                                        labelSeparator={labelSeparator}
                                    >
                                        { children }
                                    </InputText>
                                </Col>
                        }
                        <Col 
                            xs={
                                children 
                                &&
                                    ["left", "top"].includes(labelOrientation) 
                                        ? 
                                            colRatio[1] 
                                        : 
                                    ["right", "bottom"].includes(labelOrientation) 
                                    &&
                                        colRatio[0]
                            } 
                            className="w-100"
                        >
                            { renderByType() }
                        </Col>
                        {
                            children && ["right", "bottom"].includes(labelOrientation)
                            &&
                                <Col xs={colRatio[1]}>
                                    <InputText 
                                        onClick={handleClick} 
                                        weight={weight} 
                                        required={required} 
                                        labelSeparator={labelSeparator}
                                    >
                                        { children }
                                    </InputText>
                                </Col>
                        }
                    </Row>
                :
                    <Space direction={ ["top", "bottom"].includes(labelOrientation) ? "vertical" : "horizontal" } className={["w-100", classNameContainer].join(" ")} style={styleContainer}>
                        {
                            children && ["left", "top"].includes(labelOrientation)
                            &&
                                <InputText onClick={() => handleClick()} weight={weight} required={required} labelSeparator={labelSeparator}>{ children }</InputText>
                        }
                        { renderByType() }
                        {
                            children && ["right", "bottom"].includes(labelOrientation)
                            &&
                                <InputText onClick={() => handleClick()} weight={weight} required={required} labelSeparator={labelSeparator}>{ children }</InputText>
                        }
                    </Space>
            }
        </>
    );
}