import React, { useState, useEffect, useMemo } from "react";

import { useAuth, useApp } from "_contexts";
import { isNonEmptyArray, hasPermission } from "_helpers";
import { logout } from "_services";

import { protectedRoutes } from "routes";

import { Image, Menu, message } from "antd";
import { NavLink, useLocation } from "react-router-dom";

import Logo from "_assets/recouveo-logo.png";

import styles from "_styles/navbar.module.css";

export const NavBar = (  ) => {
    const [ defaultSelectedKey ] = useState("reporting");
    const [ activeKey, setActiveKey ] = useState(defaultSelectedKey);

    const { user, setUser } = useAuth();
    const { setAppConfig } = useApp();
    const location = useLocation();
    const [ messageApi, contextHolder ] = message.useMessage();

    const pathSnippets = useMemo(() => ( location.pathname.split('/').filter((i) => i) ), [location?.pathname]);

    useEffect(() => {
        if( pathSnippets && pathSnippets[0] && pathSnippets[0] !== activeKey ){
            setActiveKey(pathSnippets[0]);
        }
    }, [pathSnippets, activeKey])

    const handleClick = e => {
        e.preventDefault();
        try{
            const timer = setTimeout(async () => {
                const { response } = await logout();

                if( response ){
                    messageApi.success({key: "invalidation", content: "Déconnecté !"});
                    setUser({ isAuth: false });
                    setAppConfig({});
                }
            }, 0)

            return () => clearTimeout(timer);
        } catch(e){
            throw new Error(e);
        }
    } 

    const initialMenu = useMemo(() => (
        protectedRoutes
            .reduce((acc, val) => {
                acc.push({
                    hidden: !hasPermission([val.key], user),
                    ...val
                })
                return acc;
            }, [])
            .filter(el => !el.hidden)
            .map(route => {
                const { key, txt, path } = route;

                let ret = {
                    key,
                    label: (
                        <NavLink to={path}>
                            { txt }
                        </NavLink>
                    )
                }

                if( key === 'dashboard' ){
                    ret = {
                        ...ret,
                        className: "ms-auto"
                    }
                }

                return ret;
            })
    ), [user])

    const isDashboardAllowed = hasPermission(['dashboard'], user);

    const menuItems = useMemo(() => (
        user?.isAuth 
        ?
            [
                ...initialMenu,
                {
                    key: 'disconnect',
                    className: !isDashboardAllowed ? 'ms-auto' : undefined,
                    label: (
                        <a href="/#" onClick={handleClick}>
                            Déconnexion
                        </a>
                    )
                }
            ]
        :
            initialMenu
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [initialMenu, user?.isAuth, isDashboardAllowed])

    return(
        <>
            { contextHolder }
            {
                isNonEmptyArray(pathSnippets) && pathSnippets.some( ps => !['login', 'resetpassword'].includes(ps) )
                ?
                    <div className={["container-fluid", "mb-4", "bg-primary", styles["rec-navbar-container"]].join(" ")} style={{height: "50px"}}>
                        <div style={{marginLeft: "5%"}}>
                            <div className="bg-primary" style={{float: 'left'}}> 
                                <NavLink to="/">
                                    <Image
                                        src={Logo}
                                        alt="Logo du site"
                                        height={36}
                                        preview={false}
                                        className={styles.logo}
                                    />
                                </NavLink>
                            </div>
                        
                            <Menu 
                                items={menuItems}
                                mode="horizontal" 
                                className="bg-primary d-flex"
                                defaultSelectedKeys={[defaultSelectedKey]}
                                selectedKeys={[activeKey]}
                                onSelect={({ key }) => setActiveKey(key)}
                            />
                        </div>
                    </div>
                :
                    null
            }
        </>
    );
}