import { createGetRequest, createPostRequest, createFileDownloadBigDataRequest, b64toBlob } from '_helpers';

export async function getReportingExtraGrid( ) {
    return createGetRequest("reporting_getExtraGrid")
        .then(res => res.data)
        .then(({ success, data, errorMsg, redirect = false }) => {
            return { response: success, data, errorMsg, redirect };
        })
}

export async function getReporting( values ) {
    return createPostRequest("reporting_getData", values)
        .then(res => res.data)
        .then(({ success, data, errorMsg, redirect = false }) => {
            return { response: success, data, errorMsg, redirect };
        })
}

export async function getReportingDownloadPdf( values ) {
    return createFileDownloadBigDataRequest("reporting_downloadPdf", values)
        .then(res => res.data)
        .then(({ success, data, errorMsg, redirect = false }) => {
            if( success ){
                let bin = b64toBlob(data.filebase64) ;
                const url = window.URL.createObjectURL(new Blob([bin]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', data.filename);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
    
                return { response: success, data, errorMsg, redirect };
            } else return { response: false, errorMsg, redirect };
        })
}