import { sortStrings } from "_helpers";

export const columns = [
    {
        title: "Nom",
        dataIndex: "name",
        width: "50%",
        sorter: (a, b) => sortStrings(a.name, b.name)
    },
    {
        title: "E-mail",
        dataIndex: "email",
        width: "50%",
        sorter: (a, b) => sortStrings(a.email, b.email)
    }
];