import { 
    formatTextReplaceAccents as formatText,
    compareTextContains as textContains, 
    isNonEmptyArray
} from "_helpers";

const percentageFormatter = ( params ) => {
    if( params.value ){
        return params.value + '%';
    }
    return '';
}

const defaultColDef = {
    sortable: true,
    resizable: true,
    type: 'centerAlign',
    pinned: null,
    filter: 'agMultiColumnFilter',
    filterParams: {
        filters: [
            {
                //   filter: 'agTextColumnFilter',
                filterParams: { 
                    // defaultOption: 'startsWith',
                    // closeOnApply: true, // only work if apply button
                    trimInput: true,
                    filterOptions: ['contains', 'notContains'],
                    textCustomComparator: textContains,
                    textFormatter: formatText,
                    buttons: ['reset']
                }
            },
            {
                filter: 'agSetColumnFilter', 
                filterParams: {
                    textFormatter: formatText,
                    buttons: ['reset']
                }
            }
        ]
    },
    cellClassRules: {
        bold: params => params.node.rowPinned
    },
    getQuickFilterText: function(params) {
        return params.colDef.hide ? '' : params.value; 
    },
    menuTabs: ['filterMenuTab', 'columnsMenuTab']
};

const collected = "Encaissement",
        fees = "Honoraire";

const collectedValueGetter = ( params ) => {
    const { data } = params;
    return data['amount_collected_LOCAL'] + data['amount_collected_REMOTE'] ;
}

const feesValueGetter = ( params ) => {
    const { data } = params;
    return data['fees_LOCAL'] + data['fees_REMOTE'] ;
}

// CreatePinData ********************
export const createPinDataReporting = ( rowData, columnsFields ) => {
    let result = {
        amount: 0, 
        amount_pending: 0, 
        amount_reste: 0
    };

    if( isNonEmptyArray(columnsFields) ){
        columnsFields.forEach(field => {
            result[field] = 0;
        })
    }
    if( isNonEmptyArray(rowData) ){
        rowData.forEach(data => {
            result["amount"] += data.amount;
            result["amount_pending"] += data.amount_pending;
            result["amount_reste"] += data.amount_reste;
    
            if( isNonEmptyArray(columnsFields) ){
                columnsFields.forEach(field => {
                    if(data[field] !== undefined){
                        result[field] += data[field];
                    }
                })
            }
        })
    }

    return [result];
}

export const createPinDataBilling = ( rowData ) => {
    let result = {
        amount: 0, 
        amount_due: 0,
        amount_collected: 0, 
        fees: 0
    };

    if( isNonEmptyArray(rowData) ){
        rowData.forEach(data => {
            result.amount += data.amount;
            result.amount_due += data.amount_due;
            result.amount_collected += data.amount_collected;
            result.fees += data.fees;
        })
    }

    return [result];
}

export const createPinDataBillingSynt = ( rowData, columnsFields ) => {
    let result = {
        amount_collected_LOCAL: 0, 
        fees_LOCAL: 0,
        amount_collected_REMOTE: 0, 
        fees_REMOTE: 0
    };

    if( isNonEmptyArray(columnsFields) ){
        columnsFields.forEach(field => {
            result[field] = 0
        })
    }

    if( isNonEmptyArray(rowData) ){
        rowData.forEach(data => {
            result.amount_collected_LOCAL += data.amount_collected_LOCAL;
            result.fees_LOCAL += data.fees_LOCAL;
            result.amount_collected_REMOTE += data.amount_collected_REMOTE;
            result.fees_REMOTE += data.fees_REMOTE;
            
            if( isNonEmptyArray(columnsFields) ){
                columnsFields.forEach(field => {
                    if(data[field] !== undefined){
                        result[field] += data[field];
                    }
                })
            }
        })

    }

    return [result];
}
// *********************************

// ColumnDefs ********************
export const columnDefsReporting = [
    {
        headerName: "", groupId: "start", children: [
            { headerName: "Id", field: "record_id", hide: true, minWidth: "300" },
            { headerName: "Base", field: "connector_id", hide: true },
            { 
                headerName: "", 
                width: 50, 
                lockPosition: "left", 
                pinned: "left", 
                headerCheckboxSelection: true, 
                headerCheckboxSelectionFilteredOnly: true, 
                checkboxSelection: true 
            },
            { headerName: "Entité", field: "entity", pinned: "left" }, 
            { headerName: "N° Client", field: "acc_id", pinned: "left" }, 
            { headerName: "Nom", field: "acc_name", pinned: "left" }, 
            { headerName: "N° Facture", field: "record_ref" },
            { headerName: "Statut", field: "status", minWidth: "180" }, 
            { headerName: "Sous-statut", field: "substatus", minWidth: "450" }, 
            { colId: "assignment", headerName: "Affectation", field: "user", hide: true }, 
            { headerName: "Date", field: "date_record", type: "dateColumn" }, 
            { headerName: "Date transmission", field: "date_load", type: "dateColumn" }, 
            { headerName: "Date d'échéance", field: "date_value", type: "dateColumn" }, 
            { headerName: "Tranche d'ancienneté", field: "seniority", type: "valueIntColumn" },
            { colId: "priority", headerName: "Priorité de relance", field: "priority", hide: true },
            { colId: "atr_zonegeo", headerName: "ATTR Zone géo", field: "atr_a_zonegeo", hide: true },
            { colId: "atr_propart", headerName: "ATTR Pro/Part", field: "atr_a_propart", hide: true },
            { colId: "atr_mission", headerName: "ATTR Mission", field: "atr_a_mission", hide: true },
            { headerName: "Montant initial", field: "amount", type: "valueColumn" },
            { headerName: "Montant dû", field: "amount_pending", type: "valueColumn" },
        ],
    },
];

export const columnDefsBillings = [
    { 
                headerName: "", 
                width: 50, 
                lockPosition: "left", 
                pinned: "left", 
                headerCheckboxSelection: true, 
                headerCheckboxSelectionFilteredOnly: true, 
                checkboxSelection: true 
    },
    { headerName: "Entité", field: "soc_name" },
    { headerName: "N° client", field: "acc_id" },
    { headerName: "Nom", field: "acc_name" },
    { headerName: "N° facture", field: "record_ref" },
    { headerName: "Date facture", field: "date_record", type: "dateColumn" },
    { headerName: "Date d'échéance", field: "date_value", type: "dateColumn" },
    { headerName: "Date de transmission", field: "date_load", type: "dateColumn" },
    { headerName: "Tranche d'ancienneté", field: "seniority", type: "valueIntColumn" },
    { colId: "atr_zonegeo", headerName: "ATTR Zone géo", field: "atr_a_zonegeo", hide: true },
    { colId: "atr_propart", headerName: "ATTR Pro/Part", field: "atr_a_propart", hide: true },
    { colId: "atr_mission", headerName: "ATTR Mission", field: "atr_a_mission", hide: true },
    { headerName: "Montant", field: "amount", type: "valueColumn" },
    { headerName: "Montant dû", field: "amount_due", type: "valueColumn" },
    { headerName: collected, field: "amount_collected", type: "valueColumn" },
    { headerName: "Taux", field: "rate", valueFormatter: percentageFormatter },
    { headerName: fees, field: "fees", type: "valueColumn" }
];

export const columnDefsBillingsSynt = [
    { 
        headerName: "Recouvéo", children: [
            { headerName: collected, field: "amount_collected_LOCAL", type: "valueColumn" },
            { headerName: fees, field: "fees_LOCAL", type: "valueColumn" }
        ] 
    },
    { 
        headerName: "Créancier", children: [
            { headerName: collected, field: "amount_collected_REMOTE", type: "valueColumn" },
            { headerName: fees, field: "fees_REMOTE", type: "valueColumn" }
        ] 
    },
    {
        headerName: "Total", children: [
            { headerName: collected, valueGetter: collectedValueGetter, type: "valueColumn" },
            { headerName: fees, valueGetter: feesValueGetter, type: "valueColumn" },
        ]
    }
];
// ******************************

export const gridOptions = {
    defaultColDef: defaultColDef,
    rowHeight: 22,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    debounceVerticalScrollbar: true,
    rowBuffer: 20,
    cacheQuickFilter: true
};