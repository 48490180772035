import { useMemo } from "react";

import { isNonEmptyArray } from "_helpers";

import { Tag } from "antd";

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export const RangePickerFooter = ({ selectedDates, setSelectedDates }) => {
    const { onChange, field } = setSelectedDates;

    let ranges = useMemo(() => {
        return {
            from: {
                'Le début': [dayjs('1970-01-01', true), dayjs()]
            },
            between: {
                'L\'année dernière': [dayjs().subtract(1,'year').startOf('year'), dayjs().subtract(1,'year').endOf('year')],
                'Le mois dernier': [dayjs().subtract(1,'month').startOf('month'), dayjs().subtract(1,'month').endOf('month')],
            },
            to: {

            }
        } 
    }, [])

    if( isNonEmptyArray(selectedDates) ){
        ranges['to']['Aujourd\'hui'] = [selectedDates[0], dayjs()];
        ranges['to']['Dernier jour du mois dernier'] = [selectedDates[0], dayjs().subtract(1,'month').endOf('month')];
    }

    return(
        <>
            <div>
                Depuis :
                {
                    Object.entries(ranges.from).map(entry => {
                        const [ property, value ] = entry;

                        return(
                            <Tag key={property} color="blue" className="mx-1" style={{cursor: "pointer"}} onClick={() => onChange(value, field)}>{property}</Tag>
                        );
                    })
                }
            </div>
            <div>
                Sur la période :
                {
                    Object.entries(ranges.between).map(entry => {
                        const [ property, value ] = entry;

                        return(
                            <Tag key={property} color="blue" className="mx-1" style={{cursor: "pointer"}} onClick={() => onChange(value, field)}>{property}</Tag>
                        );
                    })
                }
            </div>
            {
                isNonEmptyArray(selectedDates) &&
                    <div>
                        À/Au :
                        {
                            Object.entries(ranges.to).map(entry => {
                                const [ property, value ] = entry;

                                return(
                                    <Tag key={property} color="blue" className="mx-1" style={{cursor: "pointer"}} onClick={() => onChange(value, field)}>{property}</Tag>
                                );
                            })
                        }
                    </div>
            }
        </>
    );
}