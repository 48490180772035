import React, { useEffect, useState, useMemo } from "react";

import { CustomSpin as Spin } from "_components";
import { isNonEmptyArray, isNonEmptyObject } from "_helpers";
import { useDashboard } from "_hooks";
import { BasesStatus, LicensesListing } from "Pages";

import { Col, Empty, Menu, Row, Select } from "antd";
import { useNavigate } from "react-router-dom";

import { ReactComponent as DbStatus } from "_assets/dashboard/database-check.svg";
import { ReactComponent as Listing } from "_assets/dashboard/listing.svg";
import { ReactComponent as DropdownArrow } from "_assets/dropdown-arrow.svg";

export function Dashboard(){
    const defaultSelectedMenu = useMemo(() => ( "bases_status" ), [])
    const [ currentSelectedMenu, setCurrentSelectedMenu ] = useState(defaultSelectedMenu);

    const [ bases, setBases ] = useState([]);
    const [ selectedBase, setSelectedBase ] = useState();

    const { fetchBasesStatus, fetchLicensesList, fetchIsDone, dashboardDoRedirect } = useDashboard();

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Dashboard';
    }, [])

    useEffect(() => {
        if( dashboardDoRedirect ){
            navigate('/');
        }
    }, [dashboardDoRedirect])

    useEffect(() => {
        if( isNonEmptyObject(fetchLicensesList) ){
            const basesTemp = [];

            Object.keys(fetchLicensesList).forEach(key => {
                basesTemp.push({
                    value: key,
                    label: key.split("@")[0]
                })
            })

            setBases(basesTemp);
        }
    }, [fetchLicensesList])

    useEffect(() => {
        if( isNonEmptyArray(bases) ) setSelectedBase(bases[0]['value']);
    }, [bases])

    const handleBaseChange = ( val ) => {
        setSelectedBase(val);
    }

    const leftMenuItems = useMemo(() => ([
        {
            key: 'bases_status',
            label: 'Etat bases',
            icon: <DbStatus width={36} height={36} />,
            hidden: false
        },{
            key: 'licenses_listing',
            label: 'Liste des licences',
            icon: <Listing width={36} height={36} />,
            hidden: false,
            children: [
                {
                    key: 'licenses_listing_select',
                    label: (
                        <Select 
                            options={bases}
                            value={selectedBase}
                            className="w-100"
                            placeholder="Sélection"
                            showSearch
                            dropdownRender={(menu) => (
                                <>
                                    {
                                        isNonEmptyArray(bases) 
                                        ?
                                            menu
                                        :
                                        fetchIsDone
                                        ?
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        :
                                            <Spin />
                                    }
                                </>
                            )}
                            onChange={handleBaseChange}
                        />
                    ),
                    hidden: false
                }
            ]
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ].filter(el => !el.hidden || !el?.children.hidden)),[bases, selectedBase, fetchIsDone])

    const toggleRenderComponent = (  ) => {
        switch( currentSelectedMenu ){
            case 'bases_status' :
                return ( isNonEmptyObject(fetchBasesStatus) ) ? <BasesStatus fetchData={fetchBasesStatus} /> : fetchIsDone ? <IsEmpty /> : <Spin /> ;
            case 'licenses_listing_select' :
                return ( isNonEmptyObject(fetchLicensesList) && selectedBase ) ?  <LicensesListing fetchData={fetchLicensesList} selectedBase={selectedBase} /> : fetchIsDone ? <IsEmpty /> : <Spin /> ;
            default :
                return ;
        }
    };

    return(
        <div className="h-100">
            <Row gutter={16} className="h-100">
                <Col xs={4}>
                    <Menu
                        items={leftMenuItems}
                        className="h-100"
                        mode="inline"
                        defaultSelectedKeys={[defaultSelectedMenu]}
                        expandIcon={({ isOpen }) => <DropdownArrow transform={isOpen ? "rotate(180)" : undefined} className="expand-icon"/>}
                        onSelect={({ key }) => setCurrentSelectedMenu(key)}
                    />
                </Col>
                <Col xs={20}>
                    { toggleRenderComponent() }
                </Col>
            </Row>
        </div>
    );
}

const IsEmpty = ( ) => <div className="h-100 d-flex justify-content-center align-items-center">Rien à afficher</div> ;