import React, { useState, useEffect, useMemo } from "react";

import { isNonEmptyArray, shallowCopy } from "_helpers";

import { columnsWithOneBaseCount, columnsWithMoreOneBaseCount } from "./_columns";

import { 
    Checkbox,
    Divider,
    Table,
    Tooltip,
    Radio,
    Row
} from "antd";

export function NotificationsModalContent({ fetchData }){
    const [ rowData, setRowData ] = useState([]);
    const [ rowDataFiltered, setRowDataFiltered ] = useState([]);
    const [ checkboxGroupOptions, setCheckboxGroupOptions ] = useState([]);
    const [ checkboxGroupOptionsValues, setCheckboxGroupOptionsValues ] = useState([]);
    const [ newColumns, setNewColumns ] = useState(columnsWithOneBaseCount);

    const [ notificationsNbBasesCount, setNotificationsNbBasesCount ] = useState(0);

    const RadioGroup = Radio.Group;
    const RadioButton = Radio.Button;
    const CheckboxGroup = Checkbox.Group;

    useEffect(() => {
        const dataKeys = Object.keys(fetchData);
        let dataValues = [];
        const arrayToPush = dataKeys.map(el => ({ value: el, label: el }));

        for(const el of Object.values(fetchData)){
            dataValues = [...dataValues, ...el];
        }

        setRowData(dataValues);
        setRowDataFiltered(dataValues);
        setCheckboxGroupOptionsValues(dataKeys);
        setCheckboxGroupOptions(arrayToPush);
        setNotificationsNbBasesCount(dataKeys.length);
    }, [fetchData])

    useEffect(() => {
        if( notificationsNbBasesCount > 1 ){            
            const newRowData = rowData.filter(row => checkboxGroupOptionsValues.includes(row['_base_name']));
            setRowDataFiltered(newRowData);
            setNewColumns(columnsWithMoreOneBaseCount);

            const checkboxGroupOptionsCopy = shallowCopy(checkboxGroupOptions);

            if( checkboxGroupOptionsValues.length === 1 ){
                const index = checkboxGroupOptionsCopy.findIndex(opt => checkboxGroupOptionsValues.includes(opt.value));

                if( index > -1 ){
                    let item = checkboxGroupOptionsCopy[index];

                    item['disabled'] = true;

                    checkboxGroupOptionsCopy.splice(index, 1, item);
                }
            } else if( checkboxGroupOptionsValues.length > 1 ){
                const index = checkboxGroupOptionsCopy.findIndex(opt => opt.disabled);
                
                if( index > -1 ){
                    let item = checkboxGroupOptionsCopy[index];

                    delete item['disabled'];

                    checkboxGroupOptionsCopy.splice(index, 1, item);
                }
            }

            setCheckboxGroupOptions(checkboxGroupOptionsCopy);
        } else {
            setNewColumns(columnsWithOneBaseCount);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationsNbBasesCount, rowData, checkboxGroupOptionsValues])

    const handleChange = ( value ) => {
        setCheckboxGroupOptionsValues(value);
    }

    const radioGroupOptions = useMemo(() => ([
        { value: 'cloture', label: 'Clôture', tooltip: 'Dossiers sans encaissements sur la période' },
        // { value: 'button2', label: 'Bouton2' },
        // { label: 'Bouton3' },
        // { label: 'Bouton4' }
    ]), [])

    return(
        <>
            <Divider className="m-0 mb-3"/>
            <div className="container-fluid d-flex">
                <Row className="w-100">
                    <RadioGroup defaultValue="cloture" buttonStyle="solid" optionType="button" className="d-flex mb-2 w-100">
                        {
                            radioGroupOptions.map(button => {
                                const { value, label, tooltip } = button;

                                const buttonWidth = `${100/radioGroupOptions.length}%`;

                                return(
                                    <React.Fragment key={value}>
                                        {
                                            tooltip
                                            ?
                                                <Tooltip title={tooltip} overlayStyle={{maxWidth: '500px'}}>
                                                    <RadioButton value={value} className="d-flex justify-content-center" style={{width: buttonWidth}}>
                                                        {label}
                                                    </RadioButton>
                                                </Tooltip>
                                            :
                                                <RadioButton value={value} className="d-flex justify-content-center" style={{width: buttonWidth}}>
                                                    {label}
                                                </RadioButton>

                                        }
                                    </React.Fragment>
                                );
                            })
                        }
                    </RadioGroup>
                    {
                        notificationsNbBasesCount > 1 &&
                            <>
                                <span className="me-2">Bases :</span>
                                <CheckboxGroup
                                    options={checkboxGroupOptions}
                                    value={checkboxGroupOptionsValues}
                                    onChange={handleChange}
                                />
                            </>
                    }
                    {
                        isNonEmptyArray(rowData)
                        ?
                  
                            <Table
                                dataSource={rowDataFiltered}
                                columns={newColumns}
                                className="w-100"
                                rowKey="_file_filerecord_id"
                                size="small"
                                bordered={true}
                                pagination={false}
                                showSorterTooltip={false}
                                style={{
                                    overflowY: 'auto',
                                    height: `calc(100vh - 160px)`
                                }}
                                onRow={() => { // Hack 22/03/23 Bug present in antd table, when copying value, many blanks charaters are adding
                                    return {
                                        onCopy: (event) => {
                                            const selection = document.getSelection();

                                            event.clipboardData.setData("text/plain", selection.toString().trim());
                                            event.preventDefault();
                                        }
                                    }
                                }}
                                // summary={summary}
                            />
                        :
                            <div className="mx-auto">Vide pour le moment</div>
                    }
                </Row>
            </div>
        </>
    );
}