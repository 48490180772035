import { isNonEmptyObject } from "_helpers";

export function defineSize( initialSize ) {
    switch(initialSize){
        case "sm" :
        case "small" :
            initialSize = "small";
            break;
        case "md" :
        case "middle" :
        case "medium" :
            initialSize = "middle";
            break;
        case "lg" :
        case "large" :
            initialSize = "large";
            break;
        default : 
            initialSize = "middle";
            break;
    }

    return initialSize;
}

export function defineBadgeRibbon( activity ) {
    let text, color;

    switch(activity){
        case "high":
            text = "Activité élevée";
            color = "red";
            break;
        case "medium": 
            text = "Activité modérée";
            color = "orange";
            break;
        case "low": 
            text = "Activité faible";
            color = "blue";
            break;
        default:
            break;
    }

    return {
        text, 
        color
    };
}

export function defineTagColor( status ) {
    return status === 'OK' ? 'bdg-success' : 'bdg-error';
}

export function defineCardBgColor( values ) {
    let colors = {
        c1: 'green',
        c2: 'green',
        c3: 'green'
    };

    if( isNonEmptyObject(values) ){
        const { apilog, envelope, email } = values,
            { inbox, outbox } = email;

        if( colors.c1 === 'green' && apilog?.status === 'KO' ) colors.c1 = 'red';
        if( colors.c2 === 'green' && envelope?.status === 'KO' ) colors.c2 = 'red';
        if( colors.c3 === 'green' && inbox?.status === 'KO' || outbox?.status === 'KO' ) colors.c3 = 'red';
    }

    return Object.values(colors).some(el => el === 'red') && '3px solid red';
}