import { useState, useMemo, useEffect, useCallback } from "react";

import { useApp } from "_contexts";
import { isEmptyArray, isNonEmptyArray, isNonEmptyObject, formatNumbers, deepCopy } from "_helpers";

// import { initialConfig, initialConfigExtended } from "Pages/Reporting/_chartConfigs";

import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
dayjs.extend(quarterOfYear);

// TODO partie pdf externaliser en PHP 
// et partie charts conserver partie Front en l'améliorant (tout sur le même composant voir espace client)
// puis gérer les charts en back avec le pdf (voir espace client + reporting envoyé au mensuel)

export const useGetReportingCharts = ( reportingRowDataFiltered, formValues ) => {
    // const initialChartsState = useMemo(() => ( initialConfig ), []);
    // const chartsColorsData = useMemo(() => ( initialConfigExtended.colors ), []);
    // const chartsPdfConfig = useMemo(() => ( initialConfigExtended.pdfConfig ), []);

    const initialChartsData = useMemo(() => ({
        gen_chart: [
            { _tId: 'GeneralChart', id: 'CI', name: 'Créances irrécouvrables', value: 0 },
            { _tId: 'GeneralChart', id: 'AVR', name: 'Avoirs', value: 0 },
            { _tId: 'GeneralChart', id: 'OLD', name: 'Paiements antérieurs', value: 0 },
            { _tId: 'GeneralChart', id: 'STOP', name: 'Demande arrêt relance', value: 0 },
            { _tId: 'GeneralChart', id: 'OTHERS', name: 'Autres', value: 0 }
        ],
        oth_chart: [
            { _tId: 'OtherChart', id: 'COLLECT', name: 'Encaissements', value: 0 },
            { _tId: 'OtherChart', id: 'NEGOC', name: 'En cours de négociation', value: 0 },
            { _tId: 'OtherChart', id: 'EXT', name: 'Actions externes', value: 0 },
            { _tId: 'OtherChart', id: 'JUDIC', name: 'Judiciaire', value: 0 },    
            { _tId: 'OtherChart', id: 'RETURN', name: 'Dossiers clôturés', value: 0 }
        ],
        ant_chart: [
            { _tId: 'AnteriorityChart', id: 'MORE365', name: 'Supérieur à 1 an', value: 0 },
            { _tId: 'AnteriorityChart', id: '180TO365', name: 'De 180 à 365 jours', value: 0 },
            { _tId: 'AnteriorityChart', id: '90TO180', name: 'De 90 à 180 jours', value: 0 },
            { _tId: 'AnteriorityChart', id: 'LESS90', name: 'Inférieur à 90 jours', value: 0 }
        ]
    }), []);
    const [ chartsData, setChartsData ] = useState(initialChartsData);
    const initialChartsPdfData = useMemo(() => ({
        gen_chart: {
            totalEntrusted: null,
            allLessOtherFormatted: null,
            allLessOtherPart: null,
            extraData: {}
        },
        oth_chart: {
            collectFormatted: null,
            collectPart: null,
            extPart: null,
            judicPart: null,
            chart02InPendingFormatted: null,
            chart02InPendingPart: null
        },
        ant_chart: {
            chart03More180Part: null
        },
        tableBody: [],
        tableFooter: []
    }), []);
    const [ chartsPdfData, setChartsPdfData ] = useState(initialChartsPdfData);
    const initialChartsColors = useMemo(() => ({
        gen_chart: ['#ff0000', '#d99694', '#65c92a', '#ffc000', '#4f81bd'],
        oth_chart: ['#92D050', '#4F81BD', '#FFC000', '#a19c9c', '#FF0000'],
        ant_chart: ['#FF0000', '#FFC000', '#a19c9c', '#92D050']
    }), []);
    const [ chartsColors, setChartsColors ] = useState(initialChartsColors);

    const textMaxWidth = useMemo(() => ({ maxWidth: 230 }), []);
    const chartsPdfConfig = useMemo(() => ({
        firstTextOptions: { positions: { x: 50, y: 32 }, ...textMaxWidth },
        secondTextOptions: { positions: { x: 50, y: 180 }, ...textMaxWidth },
        thirdTextOptions: { positions: { x: 50, y: 187 }, ...textMaxWidth },
        contacts: [
            { txt: ('Cédric Brun, Associé').toUpperCase(), fontWeight: 'bold', fontSize: 20, positions: { x: 22, y: 140 } },
            { txt: 'Tél. : 06 64 24 53 04', positions: { x: 37, y: 147 } },
            { txt: 'E-mail : cedric.brun@recouveo.com', positions: { x: 16, y: 154 } },
            { txt: ('Lionel Rabayrol, Associé').toUpperCase(), fontWeight: 'bold', fontSize: 20, positions: { x: 13, y: 170 } },
            { txt: 'Tél. : 06 08 94 32 04', positions: { x: 37, y: 177 } },
            { txt: 'E-mail : lionel.rabayrol@recouveo.com', positions: { x: 13, y: 184 } }
        ]
    }), []);

    const { appConfig } = useApp();

    useEffect(() => {
        if( isNonEmptyArray(reportingRowDataFiltered) ){
            buildData();
        } else if( isEmptyArray(reportingRowDataFiltered) ) {
            setChartsData(initialChartsData);
            setChartsPdfData(initialChartsPdfData);
            setChartsColors(initialChartsColors);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportingRowDataFiltered])

    const findIndex = useCallback(( iterateArr, searchProperty, searchValue ) => {
        return iterateArr.findIndex(el => el[searchProperty] === searchValue);
    }, [])

    const buildData = useCallback(( ) => {
        let chartsDataCopy = deepCopy(initialChartsData);
        let chartsPdfDataCopy = deepCopy(initialChartsPdfData);
        let chartsColorsCopy = deepCopy(initialChartsColors);

        let endDisplayDate = null;
        if( formValues.displayDates[1] ){
            endDisplayDate = formValues.displayDates[1];
        }
        
        const genChartMandatory = chartsDataCopy.gen_chart.map(el => el.id).filter(e => e !== 'OTHERS');

        let tempObj = Object.values(chartsDataCopy)
            .flat()
            .map(el => el.id)
            .filter(el => !el.includes('OTHERS'))
            .reduce((acc, val) => {
                acc[val] = 0;
                return acc;
            }, {});
        let tempObjExtra = {};

        const rerouteToNegoc = [];

        let propertyName = 'caloon';

        let isDevMode = null;
        if( appConfig?.['_isDevMode'] ){
            isDevMode = appConfig['_isDevMode'];
        }

        if( isDevMode === true ) propertyName = 'veo';

        let options = null;
        if( appConfig?.['options'] ){
            options = appConfig['options'];
        }

        if( formValues.bases.includes(propertyName) && options && options.hasOwnProperty(propertyName) ){
            if( options[propertyName].hasOwnProperty('OPT_LITIG') ){
                for(const el of options[propertyName]['OPT_LITIG']){
                    const { text } = el;
                    rerouteToNegoc.push(text);
                }
            }
        }

        let status = [], 
            colors = [],
            extraStatus = [],
            extraColors = [];

        for( const base in appConfig.status ){
            if( !formValues.bases.includes(base) ) continue ;
            const statusRow = appConfig['status'][base];
            let key = 'value';
            for( const el of statusRow ){
                const { value } = el;
                if( findIndex(status, key, value) === -1 ){
                    status.push(el);
                }
            }
            if( appConfig['colors'][base] ){
                const colorsRow = appConfig['colors'][base];
                key = 'id';
                for( const el of colorsRow ){
                    const { id } = el;
                    if( findIndex(colors, key, id) === -1 ){
                        colors.push(el);
                    }
                }
            }
        }
        if( appConfig?.extra_status && isNonEmptyObject(appConfig.extra_status) ){
            for( const base in appConfig.extra_status ){
                if( !formValues.bases.includes(base) ) continue ;
                const extraStatusRow = appConfig['extra_status'][base];
                let key = 'value';
                for( const el of extraStatusRow ){
                    const { value } = el;
                    if( findIndex(extraStatus, key, value) === -1 ){
                        extraStatus.push(el);
                    }
                }
                if( appConfig['extra_colors'][base] ){
                    const extraColorsRow = appConfig['extra_colors'][base];
                    key = 'id';
                    for( const el of extraColorsRow ){
                        const { id } = el;
                        if( findIndex(extraColors, key, id) === -1 ){
                            extraColors.push(el);
                        }
                    }
                }
            }
        }

        reportingRowDataFiltered.forEach(data => {
            for( const property in data ){
                const value = data[property];
                
                if( property.includes('|end') ){
                    const customKey = property.split("|")[0];
                    if( genChartMandatory.includes(customKey) ){
                        if( value ) tempObj[customKey] += value;
                    }

                    if( ['LOCAL', 'REMOTE'].includes(customKey) ){
                        if( value ) tempObj['COLLECT'] += value;
                    }

                    if( customKey === 'DR' ){
                        if( value ) tempObj['RETURN'] += value;
                    }
                }
            }

            const { _status_id, substatus, amount_reste, amount_pending, seniority } = data;

            if( isNonEmptyArray(rerouteToNegoc) && rerouteToNegoc.includes(substatus) ){
                tempObj['NEGOC'] += amount_reste;
            }

            switch( _status_id ){
                case 'S2P_PAY':
                    tempObj['COLLECT'] += amount_reste;
                    if( !tempObjExtra.hasOwnProperty('PAY') ) tempObjExtra['PAY'] = 0;
                    tempObjExtra['PAY'] += amount_reste;
                    break;
                case 'S1_OPEN':
                case 'S0_PRE':
                case 'SX_CLOSE':
                    tempObj['NEGOC'] += amount_reste;
                    break;
                case 'S2L_LITIG':
                    if( !rerouteToNegoc.includes(substatus) ){
                        tempObj['EXT'] += amount_reste;
                    }
                    break;
                case 'S2J_JUDIC':
                    tempObj['JUDIC'] += amount_reste;
                    break;    
                case 'S2T_TRSFR' :
                    if( amount_reste !== 0 ){
                        if( !tempObj['TRSFR'] ) tempObj['TRSFR'] = 0;
                        tempObj['TRSFR'] += amount_reste;
                    } 
                    break; 
                default:
                    break;
            }
            extraStatus.forEach(eStus => {
                if( _status_id === eStus.value && amount_reste !== 0 ){
                    const sufix = eStus.value.split("_")[1];
                    if( !tempObj[sufix] ) tempObj[sufix] = 0;
                    tempObj[sufix] += amount_reste;
                } 
            })

            if( seniority >= 0 && seniority < 90 ){
                tempObj['LESS90'] += amount_pending;
            } else if( seniority >= 90 && seniority < 180 ){
                tempObj['90TO180'] += amount_pending;
            } else if( seniority >= 180 && seniority < 365 ){
                tempObj['180TO365'] += amount_pending;
            } else if( seniority >= 365 ){
                tempObj['MORE365'] += amount_pending;
            } else {
                tempObj['MORE365'] += amount_pending;
            }


        })

        for( const property in tempObj ){
            tempObj[property] = Math.round((tempObj[property] + Number.EPSILON) * 100) / 100;
        }

        if( isNonEmptyObject(tempObjExtra) ){
            for( const property in tempObjExtra ){
                tempObjExtra[property] = Math.round((tempObjExtra[property] + Number.EPSILON) * 100) / 100;
            }
        }

        const dividedBy = 1000,
            currencyFormat = 'k€',
            percentSign = '%';
        let othersSumValue = 0;

        // Chart02
        let sumVal = 0;

        // Data part
        let ttmp = { _tId: 'OtherChart', id: '', name: '', value: 0 };

        const transfersCode = 'TRSFR';
        if( tempObj.hasOwnProperty(transfersCode) ){
            status.forEach(stus => {
                const sufix = stus.value.split("_")[1];
                if( sufix === transfersCode ){
                    const idx = chartsDataCopy.oth_chart.findIndex(el => el.id === transfersCode);
                    if( idx === -1 ){
                        let ttmp2 = deepCopy(ttmp);
                        ttmp2['id'] = transfersCode;
                        ttmp2['name'] = stus.label;
                        chartsDataCopy.oth_chart.push(ttmp2);

                        const idx2 = findIndex(colors, 'id', stus.value);
                        if( idx2 !== -1 ){
                            chartsColorsCopy.oth_chart.push(colors[idx2]['color']);
                        }
                    } else {
                        chartsDataCopy.oth_chart.splice(idx, 1);
                    }
                }
            })
        }

        extraStatus.forEach(eStus => {
            const sufix = eStus.value.split("_")[1];
            if( tempObj.hasOwnProperty(sufix) ){
                const idx = chartsDataCopy.oth_chart.findIndex(el => el.id === sufix);
                if( idx === -1 ){
                    let ttmp2 = deepCopy(ttmp);
                    ttmp2['id'] = sufix;
                    ttmp2['name'] = eStus.label;
                    chartsDataCopy.oth_chart.push(ttmp2);

                    const idx2 = findIndex(extraColors, 'id', eStus.value);
                    if( idx2 !== -1 ){
                        chartsColorsCopy.oth_chart.push(extraColors[idx2]['color']);
                    }
                } else {
                    chartsDataCopy.oth_chart.splice(idx, 1);
                }
            }
        })

        for( const el of chartsDataCopy.oth_chart ){
            let { id, value } = el;
            
            if( tempObj.hasOwnProperty )
            value = tempObj[id];
            othersSumValue+= value;
            sumVal+= value;

            el.value = value;
        }

        // Pdf part
        const chart02InPending = (tempObj['NEGOC'] + tempObj['EXT'] + tempObj['JUDIC'])

        chartsPdfDataCopy.oth_chart.collectFormatted = (formatNumbers(tempObj['COLLECT'] / dividedBy, 1)) + currencyFormat;
        chartsPdfDataCopy.oth_chart.collectPart = tempObj['COLLECT'] ? (formatNumbers((tempObj['COLLECT'] * 100 / sumVal), 1) + percentSign) : null;
        chartsPdfDataCopy.oth_chart.extPart = tempObj['EXT'] ? (formatNumbers((tempObj['EXT'] * 100 / sumVal), 1) + percentSign) : null;
        chartsPdfDataCopy.oth_chart.judicPart = tempObj['JUDIC'] ? (formatNumbers((tempObj['JUDIC'] * 100 / sumVal), 1) + percentSign) : null;
        chartsPdfDataCopy.oth_chart.chart02InPendingFormatted = (formatNumbers(chart02InPending / dividedBy, 1)) + currencyFormat;
        chartsPdfDataCopy.oth_chart.chart02InPendingPart = chart02InPending ? (formatNumbers((chart02InPending * 100 / sumVal), 1) + percentSign) : null;

        // Charts01
        sumVal = 0;

        // Data part
        for( const el of chartsDataCopy.gen_chart ){
            let { id, value } = el;

            if( id === 'OTHERS' ){
                value = othersSumValue;
            } else {
                value = tempObj[id];
            }
            sumVal+= value;

            el.value = value;
        }

        // Pdf part
        chartsPdfDataCopy.gen_chart.totalEntrusted = (formatNumbers(sumVal / dividedBy, 1)) + currencyFormat; 
        chartsPdfDataCopy.gen_chart.allLessOtherFormatted = (formatNumbers((sumVal - othersSumValue) / dividedBy, 1)) + currencyFormat;
        chartsPdfDataCopy.gen_chart.allLessOtherPart = othersSumValue && (sumVal - othersSumValue) > 0 ? (formatNumbers(((sumVal - othersSumValue) * 100 / sumVal), 1) + percentSign) : null;
        if( tempObjExtra.hasOwnProperty('PAY') ) chartsPdfDataCopy.gen_chart.extraData['PAY'] = (formatNumbers(tempObjExtra['PAY'] / dividedBy, 1)) + currencyFormat;

        // Chart03
        sumVal = 0;

        // Data part
        let chart03More180 = 0;

        for( const el of chartsDataCopy.ant_chart ){
            let { id, value } = el;
            
            value = tempObj[id];

            if( ['180TO365', 'MORE365'].includes(id) ){
                chart03More180+= value;
            }

            sumVal+= value;

            el.value = value;
        }

        // Pdf part
        chartsPdfDataCopy.ant_chart.chart03More180Part = chart03More180 ? (formatNumbers((chart03More180 * 100 / sumVal), 1) + percentSign) : null
    
        // Table part
        if( endDisplayDate ){
            if( isNonEmptyArray(chartsPdfDataCopy.tableBody) ){
                chartsPdfDataCopy.tableBody = [];
            }

            if( isNonEmptyArray(chartsPdfDataCopy.tableFooter) ){
                chartsPdfDataCopy.tableFooter = [];
            }

            let tempObjTable = reportingRowDataFiltered.reduce((acc, val) => {
                const { date_load } = val;

                const currDateLoad = dayjs(date_load);
                const firstDayOfYear = dayjs(endDisplayDate).startOf('year');
                
                const monthSeniority = dayjs(endDisplayDate).diff(currDateLoad, 'month');
                const yearSeniority = firstDayOfYear.diff(currDateLoad, 'year');

                const monthOfTreeLastMonth = (dayjs(endDisplayDate).subtract(2, 'month').month() +1); // 2 car il peut y avoir des records sur le mois en cours qui comptera déjà comme 1 dans les 3 mois précédents

                let key;
                if( monthSeniority >= 0 && monthSeniority < 3 ){
                    key = currDateLoad.year() + '.';
                    
                    if( (currDateLoad.month()+1) < 10 ){
                        key += '0' + (currDateLoad.month()+1);
                    } else {
                        key += (currDateLoad.month()+1);
                    }
                } else if( monthSeniority >= 3 && !yearSeniority ){
                    key = currDateLoad.year() + ' T' + currDateLoad.quarter();

                    if( 
                        ([2,3].includes(monthOfTreeLastMonth) && [1,2,3].includes((currDateLoad.month()+1)) && monthSeniority <= 5 ) ||
                        ([5,6].includes(monthOfTreeLastMonth) && [4,5,6].includes((currDateLoad.month()+1)) && monthSeniority <= 5 ) ||
                        ([8,9].includes(monthOfTreeLastMonth) && [7,8,9].includes((currDateLoad.month()+1)) && monthSeniority <= 5 ) ||
                        ([11,12].includes(monthOfTreeLastMonth) && [10,11,12].includes((currDateLoad.month()+1)) && monthSeniority <= 5 )
                    ){
                        key = currDateLoad.year() + '.';
                    
                        if( (currDateLoad.month()+1) < 10 ){
                            key += '0' + (currDateLoad.month()+1);
                        } else {
                            key += (currDateLoad.month()+1);
                        }
                    }
                } else {
                    key = currDateLoad.year();
                }

                if( !acc[key] ) acc[key] = [];

                acc[key].push(val);

                return acc;
            }, {});

            tempObjTable = Object.entries(tempObjTable).sort();
            tempObjTable = Object.fromEntries(tempObjTable);

            let sumVals = {
                load_period: 'Total',
                sum_due: 0,
                sum_collected: 0,
                fees: 0,
                sum_old_avr: 0,
                sum_ci: 0,
                sum_dr: 0,
                sum_stop: 0,
                sum_judic: 0,
                sum_rest: 0
            }

            for(const property in tempObjTable){
                const values = tempObjTable[property];
                
                let rowVals = {
                    load_period: '',
                    sum_due: 0,
                    sum_collected: 0,
                    fees: 0,
                    sum_old_avr: 0,
                    sum_ci: 0,
                    sum_dr: 0,
                    sum_stop: 0,
                    sum_judic: 0,
                    sum_rest: 0
                }

                rowVals.load_period = property;

                for(const el of values){
                    if( el['_status_id'] === 'S2J_JUDIC' ){
                        rowVals.sum_judic += el['amount_reste'];
                        sumVals.sum_judic += el['amount_reste'];
                    } else if( el['_status_id'] === 'S2P_PAY' ){
                        rowVals.sum_collected += el['amount_reste'];
                        sumVals.sum_collected += el['amount_reste'];
                    }

                    for(const prop in el){
                        const value = el[prop];

                        switch( prop ){
                            case 'amount_pending':
                                rowVals.sum_due += value;
                                sumVals.sum_due += value;
                                break;
                            case 'LOCAL|end':
                            case 'REMOTE|end':
                                rowVals.sum_collected += value;
                                sumVals.sum_collected += value;
                                break;
                            case 'OLD|end':
                            case 'AVR|end':
                                rowVals.sum_old_avr += value;
                                sumVals.sum_old_avr += value;
                                break;
                            case 'CI|end': 
                                rowVals.sum_ci += value;
                                sumVals.sum_ci += value;
                                break;
                            case 'DR|end': 
                                rowVals.sum_dr += value;
                                sumVals.sum_dr += value;
                                break;
                            case 'STOP|end': 
                                rowVals.sum_stop += value;
                                sumVals.sum_stop += value;
                                break;
                            case 'amount_reste':
                                rowVals.sum_rest += value;
                                sumVals.sum_rest += value;
                                break;
                            default:
                                break;

                        }
                    }
                }
                
                for( const property in rowVals ){
                    if( typeof rowVals[property] === 'number' ){
                        rowVals[property] = Math.round(rowVals[property] / dividedBy);
                    }
                }
                
                rowVals.fees = Math.round(rowVals.sum_collected/(rowVals.sum_due-rowVals.sum_old_avr-rowVals.sum_ci-rowVals.sum_stop) * 100);
                rowVals.fees = rowVals.fees ? rowVals.fees + percentSign : 0 + percentSign;

                chartsPdfDataCopy.tableBody.push(rowVals);
            }

            for( const property in sumVals ){
                if( typeof sumVals[property] === 'number' ){
                    sumVals[property] = Math.round(sumVals[property] / dividedBy);
                }
            }

            sumVals.fees = Math.round(sumVals.sum_collected/(sumVals.sum_due-sumVals.sum_old_avr-sumVals.sum_ci-sumVals.sum_stop) * 100);
            sumVals.fees = sumVals.fees ? sumVals.fees + percentSign : 0 + percentSign;

            chartsPdfDataCopy.tableFooter.push(sumVals);
        }

        setChartsData(chartsDataCopy);
        setChartsColors(chartsColorsCopy);
        setChartsPdfData(chartsPdfDataCopy);
    }, [reportingRowDataFiltered])

    return {
        chartsData,
        chartsPdfData,
        chartsColors,
        chartsPdfConfig
    }
}