import React from "react"

import { Row, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

export const CustomSpin = ({ tip = "...Chargement" }) => { 
    const antIcon = ( <LoadingOutlined style={{fontSize: 24}} spin/> );

    return(
        <Row className="no-gutters justify-content-center align-items-center h-100">
            <Spin tip={tip} indicator={antIcon}>
                <div style={{ padding: '50px' }} />
            </Spin>
        </Row>
    );
}