import axios from "axios";
import pako from 'pako';

import { arrayToBase64 } from '_helpers';

const instance = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? "" : process.env.REACT_APP_API_URL,
    // timeout: 1000,
    // headers: {'X-Custom-Header': 'foobar'}
});

export function createGetRequest(url) {
  return(
    instance({
      url: url,
      method: "GET",
      timeout: 60000,
      withCredentials: true,
      headers: {
        "Content-Type" : "application/json",
      }
    })
  );
};

export function createPostRequest(url, data) {
  return(
    instance({
        url: url,
        method: "POST",
        timeout: 100000,
        withCredentials: true,
        headers: {
          "Content-Type" : "application/json",
        },
        data: JSON.stringify(data)
      })
  ); 
};

export function createFileDownloadRequest(url, data) {
  return(
    instance({
      url: url,
      method: "POST",
      withCredentials: true,
      responseType: 'blob',
      data: JSON.stringify(data)
    })
  );
}

export function createFileDownloadBigDataRequest(url, data) {
    const jsonString = JSON.stringify(data);
    const compressedData = pako.gzip(jsonString);
    // const base64Data = btoa(String.fromCharCode(...new Uint8Array(compressedData))); // Ne gère pas les gros volumes de données
    const base64Data = arrayToBase64(compressedData);

    return(
        instance({
            url: url,
            method: "POST",
            withCredentials: true,
            responseType: 'json',
            data: base64Data
        })
    );
}