import { 
    Administration,
    Login, 
    Dashboard, 
    FileStatus, 
    FileTracking,
    Reporting,
    ResetPassword
} from "Pages";

export const publicRoutes = [
    { path: "/login", element: <Login /> }
];

export const publicExtendRoutes = [
    { path: "/resetpassword", element: <ResetPassword /> }
];

export const protectedRoutes = [
    { key: "reporting", txt: "Reporting", path: "/reporting", element: <Reporting /> },
    { key: "filestatus", txt: "Etat dossiers", path: "/filestatus", element: <FileStatus /> },
    { key: "filetracking", txt: "Suivi des dossiers", path: "/filetracking", element: <FileTracking /> },
    { key: "dashboard", txt: "Dashboard", path: "/dashboard", element: <Dashboard /> }
];

export const privateRoutes = [
    { path: "/admin", element: <Administration /> }
];