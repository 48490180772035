import { 
    formatTextReplaceAccents as formatText,
    compareTextContains as textContains
} from "_helpers";

import { Tag } from "antd";

const defaultColDef = {
    sortable: true,
    resizable: true,
    type: 'centerAlign',
    pinned: null,
    filter: 'agMultiColumnFilter',
    filterParams: {
        filters: [
            {
                filterParams: { 
                    trimInput: true,
                    filterOptions: ['contains', 'notContains'],
                    textCustomComparator: textContains,
                    textFormatter: formatText,
                    buttons: ['reset']
                }
            },
            {
                filter: 'agSetColumnFilter', 
                filterParams: {
                    textFormatter: formatText,
                    buttons: ['reset']
                }
            }
        ]
    },
    cellClassRules: {
        bold: ({ node }) => {
            const isRowPinned = node.rowPinned;
            const isRowGroup = node.group;
            const isFooter = node.footer;
            return isRowPinned || ( isRowGroup && isFooter );
        }
    },
    getQuickFilterText: function(params) {
        return params.colDef.hide ? '' : params.value; 
    },
    menuTabs: ['filterMenuTab', 'columnsMenuTab']
};

function cellRendererStatus({ node }) {
    const { data, rowPinned } = node;

    if( !rowPinned ){
        return (
            <>
                { 
                    data?._status_color && data?.status_txt
                    &&
                        <div className="d-flex justify-content-center">
                            <Tag color={ data._status_color }>
                                { data.status_txt }
                            </Tag>
                        </div>
                }
            </>
        );
    }
}

function cellRendererPriority({ node }) {
    const { data, rowPinned } = node;

    if( !rowPinned ){
        return (
            <>
                { 
                    data?._priority_color && data?.priority_txt
                    &&
                        <div className="d-flex justify-content-center">
                            <Tag color={ data._priority_color }>
                                { data.priority_txt }
                            </Tag>
                        </div>
                }
            </>
        );
    }
}

const cellRendererParamsAttachmentRowGroup = ( ) => {
    return {
        innerRenderer: ({ value }) => {
            if( value ) return <div>{ value }</div>;
            return <div>Non renseigné</div>;
        }
    }
}

const cellRendererParamsStatusRowGroup = ( ) => {
    return {
        innerRendererParams: {
            context: {
                'En-cours': '#538DD5',
                'Act° ext.': '#E26B0A',
                'Paiement': '#366092',
                'Judiciaire': '#E2C60D',
                'Inactif': '#D3D3D3',
                'Clôture': '#808080'
            }
        },
        innerRenderer: ( params ) => {
            const { node, value, context } = params;
            const isFooter = node.footer;

            return (
                <>
                    {
                        !isFooter
                        ?
                            <span>
                                <Tag color={ context[value] || '#fff' }>
                                    { value }
                                </Tag>
                            </span>
                        :
                            <span>Total { value }</span>
                    }
                </>
            );
        }
    }
}

function cellRendererFramework({ value }){
    return <div style={{ whiteSpace: "pre-wrap", height: "auto" }}>{ value }</div>;
}

const defaultCommentColumnsProperties = {
    type: 'commentColumn', 
    autoHeight: true, 
    cellRenderer: cellRendererFramework
}

const groupIncludeFooter = ({ node }) => node.rowGroupColumn && ['macro_status_txt', 'macro_next_action_txt'].includes(node.rowGroupColumn.getId());

const microGridColumnsMenuParams = {
    columnLayout: [
        { colId: 'soc_consolid_txt_rowGroup' },
        { colId: 'soc_txt_rowGroup' },
        { colId: 'attr_a_rat_rowGroup' },
        { colId: 'acc_txt_rowGroup' },
        { field: '_base_name' },
        { field: 'acc_id' },
        { field: 'user' },
        { field: 'status_txt' },
        { field: 'attr_a_risk' },
        { field: 'attr_a_mission' },
        { field: 'past_action_date' },
        { field: 'past_action_txt' },
        { field: 'next_action_date' },
        { field: 'next_action_txt' },
        { field: 'priority_txt' },
        { field: 'com_report' },
        { field: 'com_report_date_update' },
        { field: 'com_litig' },
        { field: 'com_manager' },
        { field: 'last_date_load' },
        { field: 'inv_nb' },
        { field: 'inv_amount_due' },
        { field: 'acc_sum' }
    ]
}

const macroGridColumnsMenuParams = {
    columnLayout: [
        { field: 'macro_soc_consolid_txt_rowGroup' },
        { field: 'macro_soc_txt_rowGroup' },
        { field: 'macro_status_txt_rowGroup' },
        { field: 'macro_next_action_txt_rowGroup' },
        { field: '_macro_base_name' },
        { field: '<2k' },
        { field: '<2k_nb' },
        { field: '2-5k' },
        { field: '2-5k_nb' },
        { field: '5-50k' },
        { field: '5-50k_nb' },
        { field: '>50k' },
        { field: '>50k_nb' },
        { field: 'sum_consolid' },
        { field: 'sum_consolid_nb' }
    ]
}

export const microGridColumnDefs = [
    // Group visible
    { colId: 'soc_consolid_txt_rowGroup', headerName: 'Entité de regroupement', showRowGroup: 'soc_consolid_txt', minWidth: 320, cellRenderer: 'agGroupCellRenderer' },
    { colId: 'soc_txt_rowGroup', headerName: 'Entité', showRowGroup: 'soc_txt', minWidth: 280, cellRenderer: 'agGroupCellRenderer' },
    { colId: 'attr_a_rat_rowGroup', headerName: 'Compte de rattachement', showRowGroup: 'attr_a_rat', minWidth: 320, cellRenderer: 'agGroupCellRenderer', cellRendererParams: cellRendererParamsAttachmentRowGroup },
    { colId: 'acc_txt_rowGroup', headerName: 'Nom', showRowGroup: 'acc_txt', minWidth: 350, cellRenderer: 'agGroupCellRenderer' },
    // Group hidden for no duplicate values
    { field: 'soc_consolid_txt', headerName: 'Entité de regroupement', rowGroup: true, hide: true },
    { field: 'soc_txt', headerName: 'Entité', rowGroup: true, hide: true },
    { field: 'attr_a_rat', headerName: 'Compte de rattachement', rowGroup: true, hide: true },
    { field: 'acc_txt', headerName: 'Nom', rowGroup: true, hide: true },
    // Rest
    { field: '_base_name', headerName: 'Base', hide: true },
    { field: 'acc_id', headerName: 'N° compte' },
    { field: 'user', headerName: 'Affectation', width: 180 },
    { field: 'file_id', headerName: 'Réf. dossier', width: 180 },
    { field: 'status_txt', headerName: 'Statut', width: 150, cellRenderer: cellRendererStatus },
    { field: 'status_substatus_txt', headerName: 'Sous-statut', width: 150 },
    { field: 'attr_a_risk', headerName: 'Risque' },
    { field: 'attr_a_mission', headerName: 'Mission' },
    { field: 'past_action_date', headerName: 'Date dernière action', type: 'dateColumn' },
    { field: 'past_action_txt', headerName: 'Dernière action', width: 250 },
    { field: 'next_action_date', headerName: 'Date prochaine action', width: 200, type: 'dateColumn' },
    { field: 'next_action_txt', headerName: 'Prochaine action', width: 250 },
    { field: 'priority_txt', headerName: 'Priorité de relance', width: 230, cellRenderer: cellRendererPriority },
    { field: 'com_report', headerName: 'Commentaire reporting', width: 600, ...defaultCommentColumnsProperties },
    { field: 'com_report_date_update', headerName: 'Date maj commentaire reporting', width: 300, type: 'dateColumn' },
    // { field: 'com_litig', headerName: 'Commentaire actions externes', width: 600, ...defaultCommentColumnsProperties },
    { field: 'com_litig_open_txt', headerName: 'Commentaire action externe', width: 600, ...defaultCommentColumnsProperties },
    { field: 'com_manager', headerName: 'Commentaire responsable', width: 600, ...defaultCommentColumnsProperties },
    { field: 'last_date_load', headerName: 'Dernière intégration', type: 'dateColumn' },
    { field: 'inv_nb', headerName: 'Nb. factures', type: 'valueIntColumn' },
    { field: 'inv_amount_due', headerName: 'Solde dossier', type: 'valueColumn' },
    { field: 'records_refs', headerName: 'Pièces du dossier' },
    { field: 'acc_sum', headerName: 'Solde compte', type: 'valueColumn' }
].map(el => {
    el.columnsMenuParams = microGridColumnsMenuParams;
    return el;
})

export const macroGridColumnDefs = [
    { colId: 'macro_soc_consolid_txt_rowGroup', headerName: 'Entité de regroupement', showRowGroup: 'macro_soc_consolid_txt', minWidth: 320, cellRenderer: 'agGroupCellRenderer' },
    { colId: 'macro_soc_txt_rowGroup', headerName: 'Entité', showRowGroup: 'macro_soc_txt', minWidth: 200, cellRenderer: 'agGroupCellRenderer' },
    { colId: 'macro_status_txt_rowGroup', headerName: 'Statut', showRowGroup: 'macro_status_txt', minWidth: 220, cellRenderer: 'agGroupCellRenderer', cellRendererParams: cellRendererParamsStatusRowGroup },
    { colId: 'macro_next_action_txt_rowGroup', headerName: 'Prochaine action', showRowGroup: 'macro_next_action_txt', minWidth: 350, cellRenderer: 'agGroupCellRenderer' },

    { field: 'macro_soc_consolid_txt', headerName: 'Entité de regroupement', rowGroup: true, hide: true },
    { field: 'macro_soc_txt', headerName: 'Entité', rowGroup: true, hide: true },
    { field: 'macro_status_txt', headerName: 'Statut', rowGroup: true, hide: true },
    { field: 'macro_next_action_txt', headerName: 'Prochaine action', rowGroup: true, hide: true },

    { field: '_macro_base_name', headerName: 'Base', hide: true },
    { field: '<2k', headerName: '<2k', type: 'valueColumn' },
    { field: '<2k_nb', headerName: '<2k nb.', type: 'valueIntColumn' },
    { field: '2-5k', headerName: '2-5k', type: 'valueColumn' },
    { field: '2-5k_nb', headerName: '2-5k nb.', type: 'valueIntColumn' },
    { field: '5-50k', headerName: '5-50k', type: 'valueColumn' },
    { field: '5-50k_nb', headerName: '5-50k nb.', type: 'valueIntColumn' },
    { field: '>50k', headerName: '>50k', type: 'valueColumn' },
    { field: '>50k_nb', headerName: '>50k nb.', type: 'valueIntColumn' },
    { field: 'sum_consolid', headerName: 'Total somme solde', type: 'valueColumn' },
    { field: 'sum_consolid_nb', headerName: 'Total nb entité', type: 'valueIntColumn' }
].map(el => {
    el.columnsMenuParams = macroGridColumnsMenuParams;
    return el;
})

export const gridOptions = {
    defaultColDef: defaultColDef,
    suppressAggFuncInHeader: true, // Suppress Sum(textfield) in header
    rowHeight: 30,
    groupDisplayType: 'custom',
    groupHideOpenParents: true,
    showOpenedGroup: true,  // Report values in opened groups
    groupIncludeFooter: groupIncludeFooter,
    // groupAllowUnbalanced: true, // Supress Blank Row Group
    suppressContextMenu: true,
    debounceVerticalScrollbar: true,
    rowBuffer: 20,
    cacheQuickFilter: true
};