import { createGetRequest, createPostRequest } from '_helpers';

// API
export async function apiList() {
    return createGetRequest("api_list")
        .then(res => res.data)
        .then(({ success, data, errorMsg, redirect = false }) => {
            return { response: success, data, errorMsg, redirect };
        })
}

export async function apiCrud( values, method ) {
    return createPostRequest("api_crud", { ...values, method})
        .then(res => res.data)
        .then(({ success, successMsg, errorMsg }) => {
            return { response: success, successMsg, errorMsg };
        })
}

// REMOTE LICENSES
export async function remoteLicensesList() {
    return createGetRequest("remlic_list")
        .then(res => res.data)
        .then(({ success, data, errorMsg, redirect = false }) => {
            return { response: success, data, errorMsg, redirect };
        })
}

export async function remoteLicensesCrud( values, method ) {
    return createPostRequest("remlic_crud", { ...values, method})
        .then(res => res.data)
        .then(({ success, successMsg, errorMsg }) => {
            return { response: success, successMsg, errorMsg };
        })
}

// PERMISSIONS
export async function permissionsList() {
    return createGetRequest("permissions_list")
        .then(res => res.data)
        .then(({ success, data, errorMsg, redirect = false }) => {
            return { response: success, data, errorMsg, redirect };
        })
}

export async function permissionsCrud( values, method ) {
    return createPostRequest("permissions_crud", { ...values, method})
        .then(res => res.data)
        .then(({ success, successMsg, errorMsg }) => {
            return { response: success, successMsg, errorMsg };
        })
}

// USERS
export async function usersList() {
    return createGetRequest("users_list")
        .then(res => res.data)
        .then(({ success, data, errorMsg, redirect = false }) => {
            return { response: success, data, errorMsg, redirect };
        })
}

export async function usersCrud( values, method ) {
    return createPostRequest("users_crud", { ...values, method})
        .then(res => res.data)
        .then(({ success, successMsg, errorMsg }) => {
            return { response: success, successMsg, errorMsg };
        })
}