import { useState, useEffect } from "react";

import { useAuth } from "_contexts";
import { hasPermission, deepCopy } from "_helpers";

import { 
    columnDefsReporting,
    columnDefsBillings,
    columnDefsBillingsSynt,
} from "Pages/Reporting/_tableConfigs";

export function useGetColumnDefs( ){
    const [ columnDefsReportingByLiteMode, setColumnDefsReporting ] = useState([]);
    const [ columnDefsBillingsByLiteMode, setColumnDefsBillings ] = useState([]);
    const [ columnDefsBillingsSyntByLiteMode, setColumnDefsBillingsSynt ] = useState([]);

    const { user } = useAuth();
    const isLiteMode = !hasPermission(['reporting', 'reporting_billing', 'reporting_billing_fees'], user);

    useEffect(() => {
        if( isLiteMode ){
            let columnDefsBillingsCopy = deepCopy(columnDefsBillings);

            columnDefsBillingsCopy = columnDefsBillingsCopy
                .filter(el => {
                    const { headerName } = el;

                    return !['Honoraire', 'Taux'].includes(headerName);
                })

            setColumnDefsBillings(columnDefsBillingsCopy);

            let columnDefsBillingsSyntCopy = deepCopy(columnDefsBillingsSynt);

            columnDefsBillingsSyntCopy = columnDefsBillingsSyntCopy
                .filter(el => {
                    const { children } = el;

                    const findIndex = children.findIndex(child => child.headerName === 'Honoraire');

                    if( findIndex !== -1 ){
                        children.splice(findIndex, 1);
                    } 

                    return el;
                })

            setColumnDefsBillingsSynt(columnDefsBillingsSyntCopy);
        } else {
            setColumnDefsBillings(columnDefsBillings);
            setColumnDefsBillingsSynt(columnDefsBillingsSynt);
        }
        setColumnDefsReporting(columnDefsReporting);
    }, [isLiteMode])

    return {
        columnDefsReporting: columnDefsReportingByLiteMode,
        columnDefsBillings: columnDefsBillingsByLiteMode,
        columnDefsBillingsSynt: columnDefsBillingsSyntByLiteMode
    }
}