import { createPostRequest, createGetRequest } from '_helpers';

export async function login(credentials) {
    return createPostRequest("login", credentials)
        .then(res => res.data)
        .then(({ success, successMsg, errorMsg, data }) => {
            return { response: success, successMsg, errorMsg, data };
        })
}

export async function forgotPassword(email) {
    return createPostRequest("forgot_password", email)
        .then(res => res.data)
        .then(({ success, successMsg, errorMsg }) => {
            return { response: success, successMsg, errorMsg };
        })
}

export async function resetPassword(values) {
    return createPostRequest("user_resetPassword", values)
        .then(res => res.data)
        .then(({ success, successMsg, errorMsg }) => {
            return { response: success, successMsg, errorMsg };
        })
}

export async function logout() {
    return createGetRequest("logout")
        .then(res => res.data)
        .then(({ success, data, errorMsg }) => {
            return { response: success, data, errorMsg };
        })
}

export async function isLogged() {
    return createGetRequest("user_isLogged")
        .then(res => res.data)
        .then(({ success, data }) => {
            return { response: success, data };
        })
}