import { isBool } from "_helpers";

const boolRender = ( bool ) => isBool(bool) && Number(bool) ? 'Oui' : 'Non';

const activityRender = ( val ) => {
    switch( val ){
        case 'high' : return 'Haute';
        case 'medium' : return 'Moyenne';
        case 'low' : return 'Basse';
        default : return ;
    }
}

const boolList = [
    { value: "_empty", label: "Sélection", disabled: true },
    { value: "1", label: "Oui" },
    { value: "0", label: "Non" }
]

const activityList = [
    { value: "_empty", label: "Sélection", disabled: true },
    { value: "low", label: "Basse" },
    { value: "medium", label: "Moyenne" },
    { value: "high", label: "Haute" }
]

export const titleRender = ( title, align = 'center' ) => {
    let classNameExtend = "";
    switch( align ){
        case 'left' : classNameExtend = 'justify-content-start text-start'; break ;
        case 'center' : classNameExtend = 'justify-content-center text-center'; break ;
        case 'right' : classNameExtend = 'justify-content-end text-end'; break ;
        default : break ;
    }

    return <div className={["d-flex", classNameExtend].join(" ")}>{ title }</div>;
}

export const apiColumns = [
    {
        title: "Domaine",
        dataIndex: "domain",
        width: "10%",
        inputType: "text",
        editable: true
    },{
        title: "Clé api",
        dataIndex: "apikey",
        width: "25%",
        inputType: "text",
        editable: true
    },{
        title: "Dans le reporting ?",
        dataIndex: "in_reporting",
        width: "10%",
        inputType: "select",
        inputValues: boolList,
        editable: true,
        render: (val) => boolRender(val)
    },{
        title: "Dans l'état des dossiers ?",
        dataIndex: "in_filestatus",
        width: "10%",
        inputType: "select",
        inputValues: boolList,
        editable: true,
        render: (val) => boolRender(val)
    },{
        title: "Dans le suivi des dossiers ?",
        dataIndex: "in_filetracking",
        width: "10%",
        inputType: "select",
        inputValues: boolList,
        editable: true,
        render: (val) => boolRender(val)
    },{
        title: "Dans la liste des licences ?",
        dataIndex: "in_dashboard_licenses",
        width: "10%",
        inputType: "select",
        inputValues: boolList,
        editable: true,
        render: (val) => boolRender(val)
    },{
        title: "Activité",
        dataIndex: "activity",
        width: "10%",
        inputType: "select",
        inputValues: activityList,
        editable: true,
        render: (val) => activityRender(val)
    }
]
    .map(el => {
        el.title = titleRender(el.title)
        return el;
    })

export const remoteLicensesColumns = [
    {
        title: "Base",
        dataIndex: "base",
        width: "10%",
        inputType: "text",
        editable: true,
        render: (_,{ id, parent }) => parent === 'root' ? id.toUpperCase() : null
    },{
        title: "Nom",
        dataIndex: "name",
        width: "20%",
        inputType: "text",
        editable: true
    },{
        title: "Email",
        dataIndex: "email",
        width: "30%",
        inputType: "text",
        editable: true
    },{
        title: "Enfant de",
        dataIndex: "parent",
        width: "25%",
        inputType: "select",
        editable: true,
        render: (t) => t === 'root' ?  null : t.toUpperCase()
    }
]
    .map(el => {
        el.title = titleRender(el.title)
        return el;
    })

export const permissionsColumns = [
    {
        title: "Id",
        dataIndex: "id",
        width: "20%",
        inputType: "text",
        editable: true
    },{
        title: "Nom",
        dataIndex: "txt",
        width: "40%",
        inputType: "text",
        editable: true
    },{
        title: "Enfant de",
        dataIndex: "parent",
        width: "25%",
        inputType: "select",
        editable: true,
        render: (t) => t === 'root' ?  null : t
    }
]
    .map(el => {
        el.title = titleRender(el.title)
        return el;
    })

export const usersColumns = [
    {
        title: "Nom d'utilisateur",
        dataIndex: "username",
        width: "20%"
    },{
        title: "E-mail",
        dataIndex: "email",
        width: "30%"
    },{
        title: "Rôle",
        dataIndex: "role",
        width: "10%"
    },{
        title: "Permissions",
        dataIndex: "permissions",
        width: "25%",
        render: (t) => t
            .map(e => {
                e = e.trim();
                return e;
            })
            .join(", ")
    }
]
    .map(el => {
        el.title = titleRender(el.title)
        return el;
    })