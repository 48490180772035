import React, { useMemo, useCallback } from "react";

import { columns } from "./_columns";
import { summary } from "./_footer";

import { 
    FloatButton,
    Table,
    Typography
} from "antd";

import { ReactComponent as RightArrow } from "_assets/right_arrow.svg";

import styles from "_styles/dashboard/LicensesListing.module.css";
 
export function LicensesListing({ fetchData, selectedBase }){
    const { Title } = Typography;
    const { BackTop } = FloatButton;

    const handleOnRow = useCallback(( ) => {
        return {
            onCopy: (event) => {
                const selection = document.getSelection();

                event.clipboardData.setData("text/plain", selection.toString().trim());
                event.preventDefault();
            }
        }
    }, [])

    const defaults = useMemo(() => ({
        columns,
        rowKey: 'id',
        bordered: true,
        pagination: false,
        showSorterTooltip: false,
        summary,
        onRow: handleOnRow // Hack 22/03/23 Bug present in antd table, when copying value, many blanks charaters are adding
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [])

    return(
        <div>
            {
                fetchData?.[selectedBase]?.['main'] &&
                    <div className="my-3">
                        <Title level={2}>Licences principales</Title>
                        <Table
                            dataSource={fetchData?.[selectedBase]?.['main'] || []}
                            {...defaults}
                        />
                    </div>
            }
            {
                fetchData?.[selectedBase]?.['ext'] &&
                    <div className="my-3">
                        <Title level={2}>Licences ext</Title>
                        <Table
                            dataSource={fetchData?.[selectedBase]?.['ext'] || []}
                            {...defaults}
                        />
                    </div>
            }
            {
                fetchData?.[selectedBase]?.['other'] &&
                    <div className="my-3">
                        <Title level={2}>Autres licences</Title>
                        <Table
                            dataSource={fetchData?.[selectedBase]?.['other']}
                            {...defaults}
                        />
                    </div>
            }
            <BackTop 
                className={styles.backtop}
                style={{
                    right: 84,
                    bottom: 56,
                }}
                duration={200}
                icon={
                    <RightArrow 
                        height={20} 
                        width={20} 
                        transform="rotate(-90)" 
                        viewBox="0 0 30 50"
                    />
                }
            />
        </div>
    );
}