import React from "react";

import { isNonEmptyArray, defineCardBgColor, defineTagColor, defineBadgeRibbon } from "_helpers";

import { TooltipCustom as Tooltip } from "./_Tooltip";

import { Badge, Card, Col, Divider, Image, Row, Tag, Typography } from "antd";

import defaultImg from "_assets/dashboard/server.png";

export const CardCustom = ({ entry }) => {
    const { val } = entry;
    const { Ribbon } = Badge;

    return (
        <Row gutter={[16,16]}>
            {
                isNonEmptyArray(val)
                &&
                    val.map((v, i) => {
                        const { activity } = v;
                        const { text, color } = defineBadgeRibbon(activity);

                        return (
                            <Col xs={8} key={i}>
                                {
                                    activity
                                    ?
                                        <Ribbon text={text} color={color}>
                                            <CardContent v={v} />
                                        </Ribbon>
                                    :
                                        <CardContent v={v} />
                                }
                            </Col>
                        );
                    })
            }
        </Row>
    );
}

const CardContent = ({ v }) => {
    const { title, apilog, envelope, email } = v,
        { inbox, outbox } = email;

    const { Text, Title } = Typography;

    return (
        <Card style={{ border: defineCardBgColor(v) }}>
            <Row>
                <Col xs={8} className="d-flex align-items-center p-2">
                    <Image src={defaultImg} preview={false} alt="Bdd image" height='100%' />
                </Col>
                <Col xs={16} className="p-2">
                    <Divider orientation="left" className="mt-0">
                        <Title level={4}>{title}</Title>
                    </Divider>
                    <div className="d-flex">
                        <Text className="me-2">Déversement quotidien :</Text>
                        {
                            apilog?.status === 'KO'
                            ?
                                <Tooltip value={apilog} placement="right">
                                    <Tag className={["bdg", "d-flex", "align-items-center", defineTagColor(apilog?.status)].join(" ")}>{ apilog?.status }</Tag>
                                </Tooltip>
                            :
                                <Tag className={["bdg", "d-flex", "align-items-center", defineTagColor(apilog?.status)].join(" ")}>{ apilog?.status }</Tag>
                        }
                    </div>
                    <div className="d-flex">
                        <Text className="me-2">Courriers :</Text>
                        {
                            envelope?.status === 'KO'
                            ?
                                <Tooltip value={envelope} placement="right">
                                    <Tag className={["bdg", "d-flex", "align-items-center", defineTagColor(envelope?.status)].join(" ")}>{ envelope?.status }</Tag>
                                </Tooltip>
                            :
                                <Tag className={["bdg", "d-flex", "align-items-center", defineTagColor(envelope?.status)].join(" ")}>{ envelope?.status }</Tag>
                        }
                    </div>
                    <div>
                        <Text className="me-2">Emails :</Text>
                        <div className="d-flex justify-content-center">
                            {
                                inbox?.status
                                &&
                                    <>
                                        <Text className="me-2">IN :</Text>
                                        {
                                            inbox?.status === 'KO'
                                            ?
                                                <Tooltip value={inbox} placement="bottom">
                                                    <Tag className={["bdg", "d-flex", "align-items-center", defineTagColor(inbox?.status)].join(" ")}>{ inbox?.status }</Tag>
                                                </Tooltip>
                                            :
                                                <Tag className={["bdg", "d-flex", "align-items-center", defineTagColor(inbox?.status)].join(" ")}>{ inbox?.status }</Tag>
                                        }
                                    </>
                            }
                            {
                                outbox?.status
                                &&
                                    <>
                                        <Text className="me-2">OUT :</Text>
                                        {
                                            outbox?.status === 'KO'
                                            ?
                                                <Tooltip value={outbox} placement="bottom">
                                                    <Tag className={["bdg", "d-flex", "align-items-center", defineTagColor(outbox?.status)].join(" ")}>{ outbox?.status }</Tag>
                                                </Tooltip>
                                            :
                                                <Tag className={["bdg", "d-flex", "align-items-center", defineTagColor(outbox?.status)].join(" ")}>{ outbox?.status }</Tag>
                                        }
                                    </>
                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </Card>
    );
}