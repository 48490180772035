import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";

import { 
    AgGridCustom as AgGrid,
    CustomFormRow as FormRow, 
    CustomSpin as Spin
} from "_components";
import {
    GridContext,
    useApp,
    useAuth
} from "_contexts";
import { 
    isArray, 
    isNonEmpty,
    isNonEmptyArray, 
    isEmptyArray,
    objectsAreUnequals,
    disableAntdPickerDates,
    sortNumbers,
    hasPermission
} from "_helpers";
import { 
    useFileStatus,
    useFileStatusColumnDefsByViewModeAndStatus
} from "_hooks";

import { popoverContent } from "./_popover";
import { 
    gridOptions,
    
    columnDefsAccounts, 
    columnDefsAccountsLitig, 
    columnDefsFilesOpen,
    columnDefsFilesClose,
    columnDefsFilesLitig,
    columnDefsRecords 
} from "./_tableConfigs";

import { 
    Button,
    DatePicker,
    Divider, 
    Dropdown,
    Form,
    InputNumber,
    message,
    Popover,
    Row,
    Select,
    Slider,
    Space,
    theme as Theme,
    TreeSelect
} from "antd";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Delete } from "_assets/delete.svg";
import { ReactComponent as DownloadXlsx } from "_assets/download_xlsx.svg";
import { ReactComponent as Info } from "_assets/info.svg";
import { ReactComponent as RightArrow } from "_assets/right_arrow.svg";

import styles from "_styles/fileStatus/fileStatus.module.css";

import dayjs from 'dayjs';

const { useToken } = Theme;

export function FileStatus(){
    const [ bases, setBases ] = useState([]);
    const [ entities, setEntities ] = useState([]);
    const [ status, setStatus ] = useState([]);
    // eslint-disable-next-line
    const [ colors, setColors ] = useState([]);

    const formatFR = useMemo(() => ( "DD/MM/YYYY" ), []);

    const initialFieldsState = useMemo(() => ({
        bases: [],
        entities: [],
        status: null,
        dateCloseFrom: ""
    }), [])

    const columnDefsList = useMemo(() => ({
        account_view: columnDefsAccounts,
        account_view_S2L_LITIG: columnDefsAccountsLitig,
        record_view: columnDefsRecords,
        S1_OPEN: columnDefsFilesOpen,
        SX_CLOSE: columnDefsFilesClose,
        S2L_LITIG: columnDefsFilesLitig
    }), [])

    const [ fields, setFields ] = useState(initialFieldsState);

    const [ domains, setDomains ] = useState("");
    const [ tableData, setTableData ] = useState([]);

    const [ viewMode, setViewMode ] = useState("file_view");

    const { appConfig } = useApp();
    const { user } = useAuth();
    const navigate = useNavigate();

    const isFileStatusAllowed = hasPermission(['filestatus'], user);

    const { fetchDomains, fetchTableData, setIsSubmitted, fetchIsDoneHandler, getDataByViewMode } = useFileStatus(fields, viewMode);
    const { fetchIsDone, setFetchIsDone } = fetchIsDoneHandler;
    const { columnDefs } = useFileStatusColumnDefsByViewModeAndStatus(viewMode, fields['status'], columnDefsList);

    const { SHOW_CHILD } = TreeSelect;
    const [ messageApi, contextHolder ] = message.useMessage();

    const gridRef = useRef();

    const viewModeOptions = useMemo(() => ([
        { value: "account_view", label: "Vue par compte" },
        { value: "file_view", label: "Vue par dossier" },
        { value: "record_view", label: "Vue par facture" }
    ]), []);

    const clearIconStyle = useMemo(() => ({
        height:"1em", width:"1em", fontSize:14 
    }), []);

    useEffect(() => {
        document.title = 'Etat dossiers';
    }, [])

    useEffect(() => {
        if( !isFileStatusAllowed ){
            navigate('/');
        }
    }, [isFileStatusAllowed])

    useEffect(() => {
        if( isNonEmpty(appConfig) ){
            setBases(appConfig?.filestatus_bases);
            setColors(appConfig?.colors);
        }
    }, [appConfig])

    const resetFields = useCallback(() => {
        setDomains("");
        setTableData([]);
    }, [])

    useEffect(() => {
        setDomains(fetchDomains);
        setTableData(getDataByViewMode(viewMode, fetchTableData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchDomains, fetchTableData, viewMode])

    const findIndex = ( iterateArr, searchProperty, searchValue ) => {
        return iterateArr.findIndex(el => el[searchProperty] === searchValue);
    }

    useEffect(() => {
        if( isNonEmptyArray(fields.bases) && isNonEmpty(appConfig) ){
            const sortArray = (a,b) => (a["title"] < b["title"]) ? -1 : 1 ;

            let returnArr = [];
            for( const base of fields.bases ){
                returnArr = [...returnArr, ...appConfig?.['entities'][base]];
            }

            setEntities(returnArr.sort(sortArray));

            let tmpStatus = [];
            const key = 'value';
            for( const base of fields.bases ){
                if( appConfig?.['status']?.[base] ){
                    appConfig['status'][base].forEach(stus => {
                        if( !['S1_OPEN', 'S2L_LITIG', 'SX_CLOSE'].includes(stus[key]) ) return ;
                        if( findIndex(tmpStatus, key, stus[key]) === -1 ){
                            tmpStatus.push(stus);
                        }
                    });
                }
            }

            setStatus(tmpStatus);
        } else if( isEmptyArray(fields.bases) ){
            setEntities([]);
            setStatus([]);
            setTimeout(() => {
                setFields(initialFieldsState);
            }, 0) 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields?.bases, appConfig])

    useEffect(() => {
        if( gridRef.current.api ){
            setTimeout(() => { gridRef.current.api.setColumnDefs(columnDefs) }, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridRef?.current, columnDefs])

    const onChange = ( value, field ) => {
        setFields({...fields, [field]: value});
        if( isNonEmptyArray(tableData) ) resetFields();
    }

    const onSubmit = () => {
        let isValid = false,
            fieldsFiltered = [];

        if( fields.status !== "SX_CLOSE" ){
            fieldsFiltered = Object.entries(fields).filter(([key]) => key !== "dateCloseFrom");
            fieldsFiltered = Object.values(Object.fromEntries(fieldsFiltered));
        } else{
            fieldsFiltered = Object.values(fields);
        }

        if( fieldsFiltered.every(field => isNonEmptyArray(field) || (!isArray(field) && field)) ){
            isValid = true;
        }

        if( !isValid ){
            messageApi.error("Le formulaire est incorrecte !");
        } else {
            messageApi.success("Le formulaire à été soumit !");
            
            setIsSubmitted(true);
            resetFields();
            
            const timer = setTimeout(() => {
                gridRef.current.api.showLoadingOverlay();
            }, 100);

            return () => clearTimeout(timer);
        }
    } 

    const today = useMemo(() => ( dayjs().format('YYYYMMDD_HHmmss') ), [])

    const getParams = useCallback((  ) => {
        return {
            author: 'Veostack',
            fileName: 'Etat dossiers - ' + today,
            sheetName: 'Etat dossiers',
            processCellCallback: ( params ) => {
                const colDef = params.column.getColDef();

                if( colDef.valueFormatter && colDef.type !== 'valueColumn' ) {
                    const valueFormatterParams = {
                        ...params,
                        data: params.node.data,
                        node: params.node,
                        colDef: params.column.getColDef()
                    }
                    return colDef.valueFormatter(valueFormatterParams);
                }
                return params.value;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsExcel( getParams() );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridRef?.current])

    // TOP X
    const [ dropdownIsOpen, setDropdownIsOpen ] = useState(false);

    const [ topXAccountMaxValue, setTopXAccountMaxValue ] = useState(0);

    const initialTopXValues = useMemo(() => ({
        topXAccount: 0,
    }), [])

    const [ topXValues, setTopXValues ] = useState(initialTopXValues);

    const [ dropdownContentStyle, setDropdownStyle ] = useState({});

    const topXButton = document.getElementById('topXButton');
    const contextMenuEl = document.querySelector('#fileStatusContextMenu');

    const topXAccountRef = useRef(null);

    const { token } = useToken();

    const toggleVisibility = ( element, onlyHide = false ) => {
        if( element.style.visibility === 'visible' && onlyHide ){
            element.style.visibility = 'hidden'  
        } else if( !onlyHide ){
            element.style.visibility = 'visible';
        }
    }

    useEffect(() => {
        if( topXButton ){
            const topXButtonWidth = topXButton.getBoundingClientRect();

            const tempDropdownStyle = {
                backgroundColor: token.colorBgElevated,
                borderRadius: token.borderRadiusLG,
                boxShadow: token.boxShadowSecondary,
                height: "fit-content",
                width: topXButtonWidth.width,
                padding: "10px"
            }

            setDropdownStyle(tempDropdownStyle);
        }
    }, [topXButton])

    useEffect(() => {
        if( contextMenuEl ){
            window.addEventListener('click', ( e ) => {
                // e.preventDefault();
                toggleVisibility(contextMenuEl, true);
            }, { passive: true})
        } 
    }, [contextMenuEl])

    useEffect(() => {
        if( tableData.length ){
            topXAccountRef.current = topXValues.topXAccount;
            setTopXAccountMaxValue(tableData.length-1);
            gridRef.current.api.onFilterChanged();
            if( topXValues.topXAccount > 0 ){
                sortBySoldeDesc();
                return ;
            } 
            clearSort();
            return ;
        }
        setTopXAccountMaxValue(0);
    }, [tableData, topXValues])

    useEffect(() => {
        if( topXValues.topXAccount > topXAccountMaxValue ){
            setTopXValues(oldValue => ({...oldValue, topXAccount: topXAccountMaxValue}));
        }
    }, [topXValues, topXAccountMaxValue])

    useEffect(() => {
        if( topXValues.topXAccount > 0 ){
            if( viewMode === 'record_view' ) setTopXValues(initialTopXValues);
        }
    }, [topXValues, viewMode])

    const DropdownContentRender = (  ) => {
        return(
            <div style={dropdownContentStyle}>
                <Space className="w-100" direction="vertical">
                    <Slider 
                        value={topXValues.topXAccount}
                        max={topXAccountMaxValue}
                        onChange={(value) => handleContextMenuValuesChange(value, 'topXAccount')}
                    />
                    <InputNumber
                        value={topXValues.topXAccount}
                        max={topXAccountMaxValue}
                        controls={false}
                        className="w-100"
                        onChange={(value) => handleContextMenuValuesChange(value, 'topXAccount')}
                    />
                </Space>
            </div>
        );
    }

    const handleContextMenu = ( e ) => {
        e.preventDefault();

        if( objectsAreUnequals(topXValues, initialTopXValues) ){
            toggleVisibility(contextMenuEl);
            setDropdownIsOpen(false);
        }
    }

    const handleContextMenuValuesChange = ( value, property ) => {
        setTopXValues(oldValue => ({...oldValue, [property]: value}));
    }

    const sortBySoldeDesc = useCallback(() => {
        gridRef.current.columnApi.applyColumnState({
            state: [{ colId: 'amount_due', sort: 'desc' }],
        });
    }, []);

    const clearSort = useCallback(() => {
        gridRef.current.columnApi.applyColumnState({
            defaultState: { sort: null },
          });
    }, [])

    const isExternalFilterPresent = ( ) => viewMode !== 'record_view' && topXAccountRef.current !== null;

    const doesExternalFilterPass = ( node ) => {
        const accountNumber = topXAccountRef.current;

        if( accountNumber > 0 ){
            const sortData = tableData
                .sort((a,b) => sortNumbers(b.file_amount_due, a.file_amount_due))
                .filter((rowFiltered, index) => (index+1 <= accountNumber) && rowFiltered);

            return sortData.includes(node.data);
        } 
        return true;
    }

    return(
        <div className="h-100">
            { contextHolder }
            <Form 
                name="fileStatusRequest" 
                className="w-100"
                onFinish={onSubmit}
            >
                <Row className="justify-content-center">
                    <FormRow 
                        width="15%"
                        topTextConfig={{
                            topText: "Base", 
                            topRequired: true
                        }} 
                        bottomTextConfig={{ 
                            bottomText: "",
                            bottomIsAlert: true 
                        }}
                    >
                        <Select 
                            options={bases}
                            placeholder="Sélection"
                            className="w-100"
                            size="large"
                            mode="multiple"
                            maxTagCount="responsive"
                            allowClear={true}
                            clearIcon={<Delete {...clearIconStyle}/>}
                            dropdownRender={(menu) => (
                                <>
                                    {
                                        isNonEmptyArray(bases) 
                                        ?
                                            menu
                                        :
                                            <Spin/>
                                    }
                                </>
                            )}
                            showSearch={false} // Disable key controls
                            removeIcon={() => <></>} // Disable each tags remove icon
                            onChange={(value) => onChange(value, "bases")} 
                        />
                    </FormRow>
                    <FormRow 
                        width="20%"
                        topTextConfig={{ 
                            topText: "Entité", 
                            topRequired: true 
                        }} 
                        bottomTextConfig={{ 
                            bottomText: "",
                            bottomIsAlert: true 
                        }}
                    >
                        <TreeSelect 
                            treeData={entities}
                            placeholder="Sélection"
                            value={fields?.entities}
                            name="entities"
                            className="w-100"
                            size="large"
                            showCheckedStrategy={SHOW_CHILD}
                            showArrow={true}
                            maxTagCount="responsive"
                            treeCheckable={true}
                            allowClear={true}
                            clearIcon={<Delete {...clearIconStyle}/>}
                            virtual={false} // Show ScrollBar
                            treeNodeFilterProp="title" // When searching use 'title' instead of 'key' 
                            showSearch={true} // Disable blur effect when clicking outside of selectbox
                            onChange={(value) => onChange(value, "entities")}
                        />
                    </FormRow>
                    <FormRow 
                        width="15%"
                        topTextConfig={{ 
                            topText: "Statut", 
                            topRequired: true 
                        }} 
                        bottomTextConfig={{ 
                            bottomText: "",
                            bottomIsAlert: true 
                        }}
                    >
                        <Select
                            options={status}
                            virtual={false}
                            value={fields?.status}
                            placeholder="Sélection"
                            className="w-100"
                            size="large"
                            onChange={(value) => onChange(value, "status")}
                        />
                    </FormRow>
                    {
                        fields.status && fields.status === "SX_CLOSE" &&
                            <FormRow
                                width="12%"
                                topTextConfig={{
                                    topText: 
                                        <span className="d-flex align-items-center">
                                            Clôturées depuis
                                            <Popover content={popoverContent}>
                                                <Info height={15} width={15} className={"ms-1 info-svg"}/>
                                            </Popover>
                                        </span>
                                    ,
                                    topRequired: true
                                }}
                                bottomTextConfig={{ 
                                    bottomText: "",
                                    bottomIsAlert: true 
                                }}
                            >
                                <DatePicker
                                    format={formatFR}
                                    disabledDate={disableAntdPickerDates}
                                    showToday={false}
                                    clearIcon={<Delete {...clearIconStyle}/>}
                                    size="large"
                                    onChange={(value) => onChange(value, "dateCloseFrom")}
                                />
                            </FormRow>
                    }

                    <FormRow>
                        <Button 
                            htmlType="submit"
                            size="large"
                            type="primary"
                            icon={<RightArrow height={18} width={18} viewBox="0 0 40 50"/>}
                        />
                    </FormRow>
                </Row>
            </Form>
            
            <div className="container-fluid">
                <Row className="align-items-center mb-3">
                    <Space>
                        Requête sur : &nbsp;<span className="bld-txt">{domains}</span>

                        <Divider type="vertical"/>

                        <Button type="primary" className="d-flex align-items-center" onClick={onBtnExport}>
                            <DownloadXlsx height={20} width={20}/>
                            Exporter
                        </Button>

                        <Select
                            options={viewModeOptions}
                            defaultValue={viewMode}
                            className="w-100"
                            onChange={(value) => setViewMode(value)}
                        />

                        {
                            viewMode !== "record_view" &&
                                <>
                                    <Dropdown
                                        dropdownRender={DropdownContentRender}
                                        trigger={['click']}
                                        open={dropdownIsOpen}
                                        onContextMenu={handleContextMenu}
                                        onOpenChange={setDropdownIsOpen}
                                    >
                                        <Button type="primary" id="topXButton">
                                            Top X par en-cours
                                        </Button>
                                    </Dropdown>
                                    <div className={styles.contextMenu} id="fileStatusContextMenu">
                                        <div className={styles.contextMenuItem} onClick={() => setTopXValues(initialTopXValues)}>
                                            Réinitialiser le Top X
                                        </div>
                                    </div>
                                </>
                        }
                    </Space>
                </Row>
            </div>
            <div className="ag-theme-alpine" style={{ height: 'calc(100% - 140px)' }}>
                <GridContext.Provider value={{ gridOptions, isExternalFilterPresent, doesExternalFilterPass }}>
                    <AgGrid
                        gridRef={gridRef}
                        columnDefs={columnDefs}
                        fetchData={tableData}
                    />
                </GridContext.Provider>
            
            </div>
        </div>
    );
}