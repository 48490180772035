import { useState, useMemo, useEffect, useContext, createContext } from "react";

import en from "_translate/en.json";
import fr from "_translate/fr.json";

const TranslateContext = createContext();

export const TranslateProvider = ({ children }) => {
    const [ lang, setLang ] = useState("fr");

    const mapTranslations = useMemo(() => ({
        en,
        fr
    }), []);

    const [ translations, setTranslations ] = useState(mapTranslations?.[lang] || []);

    useEffect(() => {
        setTranslations(mapTranslations[lang]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang])
    
    return(
        <TranslateContext.Provider value={{ lang, setLang, translations, setTranslations }}>
            {children}
        </TranslateContext.Provider>
    );
}

export const useTranslate = ( ) => useContext(TranslateContext);
export const useTranslation = ( ) => {
    const { translations } = useTranslate();

    return (key, fallback) => {
        return translations?.[key] || fr?.[key] || fallback;
    }
}