import { 
    formatTextReplaceAccents as formatText,
    compareTextContains as textContains 
} from "_helpers";

function cellRendererFramework({ value }){
    return <div style={{ whiteSpace: "pre-wrap", height: "auto" }}>{ value }</div>;
}

const defaultColDef = {
    sortable: true,
    resizable: true,
    wrapText: true,
    type: 'centerAlign',
    filter: 'agMultiColumnFilter',
    filterParams: {
        filters: [
            {
                filterParams: { 
                    trimInput: true,
                    filterOptions: ['contains', 'notContains'],
                    textCustomComparator: textContains,
                    textFormatter: formatText,
                    buttons: ['reset']
                }
            },
            {
                filter: 'agSetColumnFilter', 
                filterParams: {
                    textFormatter: formatText,
                    buttons: ['reset']
                }
            }
        ]
    },
    menuTabs: ['filterMenuTab', 'columnsMenuTab']
};

const columnDefsTranslate = {
    action_txt: 'Commentaire des actions',
    action_opened_date: 'Date d\'ouverture',
    action_opened_txt: 'Motif d\'ouverture',
    action_opened_complete_txt: 'Commentaire d\'ouverture'
}

const defaultCommentColumnsProperties = {
    type: 'commentColumn', 
    autoHeight: true, 
    cellRenderer: cellRendererFramework
}

export const columnDefsAccounts = [
    { field: 'file_acc_id', headerName: 'N° de compte', width: 200 },
    { field: 'file_acc_name', headerName: 'Nom', width: 250 },
    { field: 'file_nb_total', headerName: 'Nb fact.', width: 100 },
    { field: 'file_amount_total', headerName: 'Montant', width: 200, type: 'valueColumn' },
    { colId: 'amount_due', field: 'file_amount_due', headerName: 'Solde', width: 200, type: 'valueColumn' },
    { field: 'file_reporting_txt', headerName: 'Commentaire reporting', flex: 1, ...defaultCommentColumnsProperties }
];

export const columnDefsAccountsLitig = [
    { field: 'file_acc_id', headerName: 'N° de compte', width: 200 },
    { field: 'file_acc_name', headerName: 'Nom', width: 250 },
    { field: 'file_nb_total', headerName: 'Nb fact.', width: 100 },
    { field: 'file_amount_total', headerName: 'Montant', width: 200, type: 'valueColumn' },
    { colId: 'amount_due', field: 'file_amount_due', headerName: 'Solde', width: 200, type: 'valueColumn' },
    { field: 'file_reporting_txt', headerName: 'Commentaire reporting', width: 600, ...defaultCommentColumnsProperties },
    { field: 'file_litig_txt', headerName: 'Commentaire actions externes', width: 600, ...defaultCommentColumnsProperties }
];

export const columnDefsFilesOpen = [
    { field: 'file_acc_id', headerName: 'N° de compte', width: 200 },
    { field: 'file_acc_name', headerName: 'Nom', width: 250 },
    { field: 'file_ref', headerName: 'Dossier', width: 250 },
    { field: 'file_nb_total', headerName: 'Nb fact.', width: 100 },
    { field: 'file_amount_total', headerName: 'Montant', width: 200, type: 'valueColumn' },
    { colId: 'amount_due', field: 'file_amount_due', headerName: 'Solde', width: 200, type: 'valueColumn' },
    { field: 'action_txt', headerName: columnDefsTranslate.action_txt, width: 600, ...defaultCommentColumnsProperties }
];

export const columnDefsFilesClose = [
    { field: 'file_acc_id', headerName: 'N° de compte', width: 200 },
    { field: 'file_acc_name', headerName: 'Nom', width: 250 },
    { field: 'file_ref', headerName: 'Dossier', width: 250 },
    { field: 'file_nb_total', headerName: 'Nb fact.', width: 100 },
    { field: 'file_amount_total', headerName: 'Montant', width: 200, type: 'valueColumn' },
    { colId: 'amount_due', field: 'file_amount_due', headerName: 'Solde', width: 200, type: 'valueColumn' },
    { field: 'action_opened_date', headerName: columnDefsTranslate.action_opened_date, width: 250, type: 'dateColumn' },
    { field: 'action_opened_txt', headerName: columnDefsTranslate.action_opened_txt, width: 400 },
    { field: 'action_txt', headerName: columnDefsTranslate.action_txt, width: 600, ...defaultCommentColumnsProperties }
];

export const columnDefsFilesLitig = [
    { field: 'file_acc_id', headerName: 'N° de compte', width: 200 },
    { field: 'file_acc_name', headerName: 'Nom', width: 250 },
    { field: 'file_ref', headerName: 'Dossier', width: 250 },
    { field: 'file_nb_total', headerName: 'Nb fact.', width: 100 },
    { field: 'file_amount_total', headerName: 'Montant', width: 200, type: 'valueColumn' },
    { colId: 'amount_due', field: 'file_amount_due', headerName: 'Solde', width: 200, type: 'valueColumn' },
    { field: 'action_opened_date', headerName: columnDefsTranslate.action_opened_date, width: 250, type: 'dateColumn' },
    { field: 'action_opened_txt', headerName: columnDefsTranslate.action_opened_txt, width: 400 },
    { field: 'action_opened_complete_txt', headerName: columnDefsTranslate.action_opened_complete_txt, width: 400, ...defaultCommentColumnsProperties }
];

export const columnDefsRecords = [
    { field: 'file_acc_id', headerName: 'N° de compte', width: 200 },
    { field: 'file_acc_name', headerName: 'Nom', width: 250 },
    { field: 'record_ref', headerName: 'N° de facture', width: 200 },
    { field: 'file_status', headerName: 'Statut', width: 120 },
    { field: 'record_amount', headerName: 'Montant', width: 150, type: 'valueColumn' },
    { field: 'record_date', headerName: 'Date facture', width: 150, type: 'dateColumn' },
    { field: 'action_txt', headerName: columnDefsTranslate.action_txt, flex: 1, ...defaultCommentColumnsProperties }
];

export const gridOptions = {
    defaultColDef: defaultColDef,
    // suppressAggFuncInHeader: true, // Suppress Sum(textfield) in header
    suppressContextMenu: true,
    debounceVerticalScrollbar: true,
    rowBuffer: 20
};