import React, { useMemo } from "react";

import { Tooltip } from "antd";

import dayjs from "dayjs";

export const TooltipCustom = ({ value, placement, children }) => (
    <Tooltip title={<TooltipTitle value={value} />} placement={placement} overlayStyle={{ maxWidth: '500px' }}>
        { children }
    </Tooltip>
)

const TooltipTitle = ({ value }) => {
    const {
        apilog_apiMethod,
        apilog_dateRequest,
        apilog_dateLastRecordLoad,
        apilog_errorInPercent,
        apilog_keycode,
        apilog_warningLevel,

        email_daysFromFirstFail,
        email_failsCount,
        email_dateFirstFail,

        envelope_notSentCount,

        error_code
    } = value;

    const dateFR = useMemo(() => ( 'DD/MM/YYYY' ), []);

    const defineCriticalErrorStatus = ( warningLevel ) => {
        if( warningLevel === 'high' ) return 'Haute';
        if( warningLevel === 'medium' ) return 'Moyenne';
        return 'Basse';
    }

    return (
        <div>
            {
                apilog_keycode
                &&
                    <div>Utilisateur : { apilog_keycode }</div>
            }
            {
                error_code
                &&
                    <div>Code erreur : { error_code }</div>
            }
            {
                apilog_dateRequest
                &&
                    <div>Date de la requête : { dayjs(apilog_dateRequest).format(dateFR) }</div>
            }
            {
                apilog_dateLastRecordLoad
                &&
                    <div>Date de la derniere pièce comptable chargée : { dayjs(apilog_dateLastRecordLoad).format(dateFR) }</div>
            }
            {
                apilog_apiMethod
                &&
                    <div>Méthode de la requête : { apilog_apiMethod }</div>
            }
            {
                apilog_errorInPercent
                &&
                    <div>Pourcentage d'erreur : { apilog_errorInPercent }</div>
            }
            {
                apilog_warningLevel
                &&
                    <div>Criticité de l'erreur : { defineCriticalErrorStatus(apilog_warningLevel) }</div>
            }
            {
                email_failsCount
                &&
                    <div>Nb. email(s) en échec : { email_failsCount }</div>
            }
            {
                email_daysFromFirstFail
                &&
                    <div>Nb. jour(s) depuis le premier échec : { email_daysFromFirstFail }</div>
            }
            {
                email_dateFirstFail
                &&
                    <div>Date du premier email en échec : { dayjs(email_dateFirstFail).format(dateFR) }</div>
            }
            {
                envelope_notSentCount
                &&
                    <div>Nb. courrier(s) non envoyé(s) : { envelope_notSentCount }</div>
            }
        </div>
    );
}