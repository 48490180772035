import { isNonEmptyArray } from "_helpers";

export function formatWithTwoDecimals(val) {
    return Math.floor(parseFloat(val) * 100) / 100;
}
 
export function formatNumbers( value, decimals = 2 ) {
    return value.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function formatTextReplaceAccents(text){
    return text
        .replace(/\s/g, '')
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y')
        .replace(/\W/g, ''); 
}

export function formatHeadernames(array, displayDates){
    const [ startDisplayDate, endDisplayDate ] = displayDates;
    
    if( isNonEmptyArray(array) && (startDisplayDate && endDisplayDate) ){
        array.forEach(column => {
            if(!column.groupId){
                column.children.forEach(col => {
                    if(col.field.includes("variation")){
                        if(startDisplayDate && endDisplayDate) col.headerName = "Du " + startDisplayDate + " au " + endDisplayDate
                        else if(!startDisplayDate && endDisplayDate) col.headerName = "Du... au " + endDisplayDate
                        else if(startDisplayDate && !endDisplayDate) col.headerName = "Du " + startDisplayDate + " au... "
                        else if(!startDisplayDate && !endDisplayDate) col.headerName = "Du... au... "
                    } else if(col.field.includes("start")){
                        if(startDisplayDate) col.headerName = "Au " + startDisplayDate
                        else if(!startDisplayDate) col.headerName = "Au... "
                    } else if(col.field.includes("end")){
                        if(endDisplayDate) col.headerName = "Au " + endDisplayDate
                        else if(!endDisplayDate) col.headerName = "Au... "
                    }
                })
            }
        })
    }
    
    return array;
}