import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";

import { CustomFormItemByType as Input } from "_components";
import { useAuth } from "_contexts";
import { shallowCopy } from "_helpers";
import { resetPassword } from "_services";

import { Button, Card, message, Progress, Row, Space } from "antd";

export function ResetPassword(  ) {
    const [ formValues, setFormValues ] = useState({});

    const [ requiredListItemsWidth, setRequiredListItemsWidth ] = useState([]);

    const initialRequiredList = useMemo(() => ([
        { key: 'lowerChar', label: 'Une minuscule' },
        { key: 'upperChar', label: 'Une majuscule' },
        { key: 'aNumber', label: 'Un chiffre' },
        { key: 'specialChar', label: 'Un caractère spécial' },
        { key: 'atLeastEightChar', label: 'Au moins 8 caractères' },
        { key: 'samePass', label: 'Mots de passe identiques' }
    ]
        .map(el => {
            el['stroke'] = 'red';
            return el;
        })
    ), [])
    const [ requiredList, setRequireList ] = useState(initialRequiredList);

    const requiredListRefs = useRef([]);
    const [ messageApi, contextHolder ] = message.useMessage();

    const { user, setUser } = useAuth();

    useEffect(() => {
        requiredListRefs.current = requiredListRefs.current.slice(0, requiredList.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, []);

    useEffect(() => {
        if( requiredListRefs?.current ){
            const ttmp = requiredListRefs.current.map(el => {
                const domRect = el.getBoundingClientRect(),
                    { width } = domRect; 

                return width;
            })

            setRequiredListItemsWidth(ttmp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requiredListRefs?.current])

    const handleChange = useCallback(({ name, value }) => {
        handleCheck(name, value);
        setFormValues(oldVal => ({ ...oldVal, [name]: value }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requiredList])

    const handleCheck = useCallback(( name, value ) => {
        let requiredListCopy = shallowCopy(requiredList);
        
        let pattern;

        requiredListCopy.forEach(rLC => {
            switch( rLC.key ) {
                case 'lowerChar' :
                    pattern = new RegExp(/[a-z]+/);
                    break;
                case 'upperChar' :
                    pattern = new RegExp(/[A-Z]+/);
                    break;
                case 'aNumber' :
                    pattern = new RegExp(/[0-9]+/);
                    break;
                case 'specialChar' :
                    pattern = new RegExp(/[!@#$€%^&*)(+=._-]+/);
                    break;
                case 'atLeastEightChar' :
                    pattern = new RegExp(/[^-\s]{8,}/);
                    break;
                case 'samePass' :
                    const { password, passwordverify } = formValues;

                    pattern = null;
                    rLC.stroke = ((password && value === password) || (passwordverify && value === passwordverify)) ? 'green' : 'red';
                    break;
                default : pattern = null; break ;
            }

            if( pattern && name === 'password' ) rLC.stroke = (value && pattern.test(value)) ? 'green' : 'red';
            
            return rLC;
        })

        setRequireList(requiredListCopy);
    }, [requiredList, formValues])

    const onBeforeSubmit = useCallback(() => {
        return requiredList.every(el => el.stroke === 'green');
    }, [requiredList])

    const onSubmit = useCallback(() => {
        const isValid = onBeforeSubmit();

        if( isValid ){
            try {
                const timer = setTimeout(async() => {
                    const { response, successMsg, errorMsg } = await resetPassword(formValues);

                    if( response ){
                        messageApi.success({key: "validation", content: successMsg});
                        setUser(oldVal => ({ ...oldVal, passConfirmed: response }))
                    } else {
                        messageApi.error({key: "validation", content: errorMsg});
                    }
                }, 0)

                return () => clearTimeout(timer);
            } catch(e) {
                throw new Error(e);
            }
        } else {
            messageApi.error({key: "validation", content: "Validation incorrecte, veuillez correctement remplir les champs marqués d'un astérisque"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requiredList, formValues])

    return (
        <>
            { contextHolder }
            <Row gutter={16} className='h-100 w-100 justify-content-center align-items-center'>
                <Card
                    title='Réinitialiser mon mot de passe'
                    style={{width: '650px'}}
                >
                    <Space direction='vertical' className='w-100'>
                        <Input
                            type='inputpassword'
                            name='password'
                            required
                            onChange={handleChange}
                        >
                            Mot de passe
                        </Input>
                        <Input
                            type='inputpassword'
                            name='passwordverify'
                            required
                            onChange={handleChange}
                        >
                            Retapez le mot de passe
                        </Input>
                         <Space direction='vertical' size={2}>
                            <Space className='d-flex justify-content-between'>
                                {
                                    requiredList
                                        .map((rl, i) => {
                                            const { key, label } = rl;
                                            return (
                                                <span 
                                                    key={key} 
                                                    ref={el => requiredListRefs.current[i] = el}  
                                                    className='d-flex text-center requiredlist'
                                                >
                                                    { label }
                                                </span>
                                            );
                                        })
                                }
                            </Space>
                            <Space size={1.5} className='d-flex justify-content-between'>
                                {
                                    new Array(requiredList.length)
                                        .fill(null)
                                        .map((p, i) => {
                                            const width = requiredListItemsWidth[i];
                                            const stroke = requiredList[i]['stroke'];

                                            return (
                                                <Progress 
                                                    key={i} 
                                                    strokeColor={stroke}
                                                    percent={100} 
                                                    steps={1} 
                                                    showInfo={false} 
                                                    size={[ width,15 ]} 
                                                />
                                            );
                                        })
                                }
                            </Space>
                        </Space>
                        
                        <div className='d-flex'>
                            <Button className='ms-auto' type='primary' onClick={onSubmit}>Valider</Button>
                        </div>
                    </Space>
                </Card>
            </Row>
        </>
    );
}