import "bootstrap/dist/css/bootstrap.min.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'antd/dist/reset.css';
import "_styles/antd.css";
import "./custom.scss";
import "./App.css";

import React, { useMemo, useEffect } from "react";

import { 
    CustomFloatButton as FloatButton, 
    NavBar, 
    PublicRoutes,
    PublicExtendRoutes, 
    ProtectedRoutes, 
    PrivateRoutes 
} from "_components";
import { useAuth, useApp } from "_contexts";
import { hasPermission } from "_helpers";
import { isLogged, appConfig } from "_services";
import { 
	publicRoutes, 
	publicExtendRoutes, 
	protectedRoutes, 
	privateRoutes 
} from "./routes";

import dayjs from "dayjs";
import { HashRouter as Router, Navigate, Routes, Route } from "react-router-dom";

export function App() {
    const { user, setUser } = useAuth();

    const redirectPath = useMemo(() => {
        if( user?.isAuth ){
            return hasPermission(['reporting'], user) ? "/reporting" : user?.permissions && "/" + user.permissions[0];
        } else {
            return "/login";
        }
    }, [user]);

    const { setAppConfig } = useApp();

    useEffect(() => {
        dayjs.locale('fr');
        try{
            const timer = setTimeout(async () => {
                const { response, data } = await isLogged();
                setUser(oldVal => ({ ...oldVal, isAuth: response, ...data }));
            }, 0)

            return () => clearTimeout(timer);
        } catch(e){
            throw new Error(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if( user.isAuth ){
            try {
                const timer = setTimeout(async () => {
                    const { response, data } = await appConfig();
                    
                    if( response ){
                        setAppConfig(data);
                    } else {
                        setAppConfig({});
                    }
                    
                }, 0)
    
                return () => clearTimeout(timer);
            } catch(e) {
                throw new Error(e);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.isAuth])

	return(
        <Router>
            <NavBar />
            <div id="site" className="container-fluid">
                <Routes>
                    <Route path="/" element={<Navigate to={redirectPath} />} />
                    <Route element={<PublicRoutes />}>
                        { publicRoutes.map((r, i) => <Route key={i} {...r} />) }
                    </Route>
                    <Route element={<PublicExtendRoutes />}>
                        { publicExtendRoutes.map((r, i) => <Route key={i} {...r} />) }
                    </Route>
                    <Route element={<ProtectedRoutes />}>
                        { protectedRoutes.map((r, i) => <Route key={i} {...r} />) }
                    </Route>
                    <Route element={<PrivateRoutes />}>
                        { privateRoutes.map((r, i) => <Route key={i} {...r} />) }
                    </Route>
                    <Route path="*" element={<div>Cette page n'éxite pas</div>} />
                </Routes>
                <FloatButton />
            </div>
        </Router>
	);
}