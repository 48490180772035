import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";

import { AgGridCustom as AgGrid, CustomFormItemByType as Input, CustomMenu as Menu } from "_components";
import { GridContext, useApp, useAuth } from "_contexts";
import { isEmptyArray, shallowCopy, isNonEmptyArray, isNonEmptyObject, hasPermission } from "_helpers";
import { useFileTracking } from "_hooks";

import { Button, Card, Col, Divider, Image, message, Row, Space, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { CCollapse } from "@coreui/react";

import { gridOptions, microGridColumnDefs, macroGridColumnDefs } from "./_tableConfigs";
 
import Collapse from "_assets/collapse.svg";
import Expand from "_assets/expand.svg";

import { ReactComponent as OptionsPanel } from "_assets/reporting/ext_filters.svg";

import { ReactComponent as DownloadXlsx } from "_assets/download_xlsx.svg";
import { ReactComponent as DropdownArrow } from "_assets/dropdown-arrow.svg";
import { ReactComponent as RightArrow } from "_assets/right_arrow.svg";

import styles from "_styles/FileTracking.module.css";

import dayjs from "dayjs";

export function FileTracking() {
    const defaultSelectedKeys = useMemo(() => ( "macro" ), []);
    const [ currentMenuSelected, setCurrentMenuSelected ] = useState(defaultSelectedKeys);

    const [ optionsPanelIsOpen, setOptionsPanelIsOpen ] = useState(false);

    const [ creditorConsolidOptions, setCreditorConsolidOptions ] = useState([]);
    const [ creditorOptions, setCreditorOptions ] = useState([]);
    const [ attachmentOptions, setAttachmentOptions ] = useState([]);
    const [ userOptions, setUserOptions ] = useState([]);
    const [ nextActionOptions, setNextActionOptions ] = useState([]);

    const [ status, setStatus ] = useState([]);

    const RowGroupLevels = useMemo(() => ({
        min: 0,
        max: 4
    }), [])
    const { min, max } = RowGroupLevels;
    const [ currentRowGroupPosition, setCurrentRowGroupPosition ] = useState(min);

    const [ exportPanelIsOpen, setExportPanelIsOpen ] = useState(false);

    const { appConfig } = useApp();
    const { user } = useAuth();
    const [ messageApi, contextHolder ] = message.useMessage();
    const navigate = useNavigate();

    const isFileTrackingAllowed = hasPermission(['filetracking'], user);

    const findIndex = ( iterateArr, searchProperty, searchValue ) => {
        return iterateArr.findIndex(el => el[searchProperty] === searchValue);
    }

    useEffect(() => {
        if( appConfig?.status && isNonEmptyObject(appConfig.status) ){
            let filetrackingBases = [];
            if( appConfig?.filetracking_bases && isNonEmptyArray(appConfig.filetracking_bases) ){
                filetrackingBases = appConfig.filetracking_bases.map(el => el.value);
            }
            
            let tmpStatus = [];
            const key = 'value';

            for( const base in appConfig.status ){
                if( !filetrackingBases.includes(base) ) continue ;
                const statusRow = appConfig['status'][base];
                for( const el of statusRow ){
                    const { value } = el;
                    if( findIndex(tmpStatus, key, value) === -1 ){
                        tmpStatus.push(el);
                    }
                }
            }
            if( appConfig?.extra_status && isNonEmptyObject(appConfig.extra_status) ){
                for( const base in appConfig.extra_status ){
                    if( !filetrackingBases.includes(base) ) continue ;
                    const extraStatusRow = appConfig['extra_status'][base];
                    for( const el of extraStatusRow ){
                        const { value } = el;
                        if( findIndex(tmpStatus, key, value) === -1 ){
                            tmpStatus.push(el);
                        }
                    }
                }
            }

            setStatus(tmpStatus);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appConfig])

    const formRequestConfig = useMemo(() => ([
        {
            name: 'bases',
            label: 'Bases',
            required: true,
            type: 'select',
            mode: 'multiple',
            classNameContainer: styles['custom-input-bases'],
            options: appConfig?.filetracking_bases,
            submitIsPending: !appConfig.filetracking_base,
            placeholder: 'Sélection',
        },
        {
            name: 'status',
            label: 'Statuts',
            required: true,
            type: 'select',
            mode: 'multiple',
            classNameContainer: styles['custom-input-status'],
            options: status,
            placeholder: 'Sélection'
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ]), [appConfig?.filetracking_bases, status])

    const { formValues, formErrors, handleCheckErrors, handleChange, fetchMicroData, fetchMacroData, formIsSubmittedHandler, fetchIsDoneHandler } = useFileTracking( formRequestConfig );
    const { formIsSubmitted, setFormIsSubmitted } = formIsSubmittedHandler;
    const { fetchIsDone, setFetchIsDone } = fetchIsDoneHandler;

    const [ gridRef, setGridRef ] = useState(null);

    const microGridRef = useRef();
    const macroGridRef = useRef();

    const initialFilterValues = useMemo(() => ({
        soc_consolid_txt: [],
        attr_a_rat: [],
        user: [],
        next_action_txt: [],
        next_action_date: [],
    }), [])
    const [ filterValues, setFilterValues ] = useState(initialFilterValues);
    const filterValuesRef = useRef(initialFilterValues);

    const menuItems = useMemo(() => ([
        {
            key: 'macro',
            label: 'Analyse Macro',
        },
        {
            key: 'micro',
            label: 'Analyse Micro',
        }
    ]), [])

    const imgCommonProps = useMemo(() => ({
        width: 22,
        height: 22,
        preview: false
    }), [])

    const imgCollapseProps = useMemo(() => ({
        src: Collapse,
        ...imgCommonProps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [])

    const imgExpandProps = useMemo(() => ({
        src: Expand,
        ...imgCommonProps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [])

    const exportIsDisable = useMemo(() => ( isEmptyArray(fetchMicroData) ), [fetchMicroData])

    useEffect(() => { document.title = 'Suivi des dossiers' }, [])

    useEffect(() => {
        if( !isFileTrackingAllowed ){
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFileTrackingAllowed])

    const getCurrentRef = useCallback(() => {
        let currentRef = null;

        if( microGridRef.current ){
            currentRef = microGridRef.current;
        } else if( macroGridRef.current ){
            currentRef = macroGridRef.current;
        }

        return currentRef;
    }, []);

    useEffect(() => {
        setGridRef(getCurrentRef());
        setCurrentRowGroupPosition(min);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMenuSelected])

    useEffect(() => {
        if( currentMenuSelected === 'macro' ){
            const filterValuesCopy = shallowCopy(filterValues);

            for( const property in filterValuesCopy ){
                const value = filterValuesCopy[property];

                if( ['attr_a_rat', 'user', 'next_action_date'].includes(property) && isNonEmptyArray(value) ){
                    filterValuesCopy[property] = [];
                }
            }

            setFilterValues(filterValuesCopy);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMenuSelected])

    useEffect(() => {
        if( fetchIsDone ){
            if( isEmptyArray(fetchMicroData) ){
                setTimeout(() => {
                    gridRef.api.showNoRowsOverlay();
                }, 100)
            }
            defineFilters();
            setFetchIsDone(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchMicroData, fetchIsDone, gridRef])

    useEffect(() => {
        if( gridRef ){
            filterValuesRef.current = filterValues;
            gridRef.api.onFilterChanged();
        }
    }, [gridRef, filterValues])

    const menuHandleSelect = ( key ) => {
        setCurrentMenuSelected(key);
    }

    const toggleRenderComponent = ( ) => {
        switch( currentMenuSelected ){
            case 'macro' :
                return (
                    <AgGrid 
                        gridRef={macroGridRef} 
                        columnDefs={macroGridColumnDefs}
                        fetchData={fetchMacroData}
                    />
                );
            case 'micro' :
                return (
                    <AgGrid 
                        gridRef={microGridRef} 
                        columnDefs={microGridColumnDefs}
                        fetchData={fetchMicroData}
                    />
                );
            default : break ;
        }
    }

    const resetAll = useCallback(() => {
        setFilterValues(initialFilterValues);
        setCreditorConsolidOptions([]);
        setCreditorOptions([]);
        setAttachmentOptions([]);
        setUserOptions([]);
        setNextActionOptions([]);
        setCurrentRowGroupPosition(min);
        setOptionsPanelIsOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const defineFilters = useCallback(() => {
        if( isNonEmptyArray(fetchMicroData) ){
            const dataCopy = shallowCopy(fetchMicroData);
            let tempObj = {};
    
            dataCopy.forEach(rowData => {
                const { soc_consolid_txt, soc_txt, attr_a_rat, user, next_action_txt } = rowData;

                if( !tempObj['soc_consolid_txt'] && !tempObj['soc_txt'] && !tempObj['attr_a_rat'] && !tempObj['user'] && !tempObj['next_action_txt'] ){
                    tempObj['soc_consolid_txt'] = [];
                    tempObj['soc_txt'] = [];
                    tempObj['attr_a_rat'] = [];
                    tempObj['user'] = [];
                    tempObj['next_action_txt'] = [];
                }

                if( soc_consolid_txt && !tempObj['soc_consolid_txt'].includes(soc_consolid_txt) ) tempObj['soc_consolid_txt'].push(soc_consolid_txt);
                if( soc_txt && !tempObj['soc_txt'].includes(soc_txt) ) tempObj['soc_txt'].push(soc_txt);
                if( attr_a_rat && !tempObj['attr_a_rat'].includes(attr_a_rat) ) tempObj['attr_a_rat'].push(attr_a_rat);
                if( user && !tempObj['user'].includes(user) ) tempObj['user'].push(user);
                if( next_action_txt && !tempObj['next_action_txt'].includes(next_action_txt) ) tempObj['next_action_txt'].push(next_action_txt);
            })

            function callBack() {
                return (acc, val) => {
                    acc.push({
                        value: val,
                        label: val
                    })
                    
                    return acc;
                }

            }

            let ttmp = tempObj['soc_consolid_txt'].sort();
            ttmp = tempObj['soc_consolid_txt'].reduce(callBack(), []);
            setCreditorConsolidOptions(ttmp);

            ttmp = tempObj['soc_txt'].sort();
            ttmp = tempObj['soc_txt'].reduce(callBack(), []);
            setCreditorOptions(ttmp);

            ttmp = tempObj['attr_a_rat'].sort();
            ttmp = tempObj['attr_a_rat'].reduce(callBack(), []);
            setAttachmentOptions(ttmp);

            ttmp = tempObj['user'].sort();
            ttmp = tempObj['user'].reduce(callBack(), []);
            setUserOptions(ttmp);

            ttmp = tempObj['next_action_txt'].sort();
            ttmp = tempObj['next_action_txt'].reduce(callBack(), []);
            setNextActionOptions(ttmp);
        }
    }, [fetchMicroData])

    const handleSubmit = useCallback(( ) => {
        const { isValid, errors } = handleCheckErrors();

        if( isValid ){
            setFormIsSubmitted(true);
            resetAll();
            setTimeout(() => {
                gridRef.api.showLoadingOverlay();
            }, 100)
        } else {
            let ttmpMessage = [];
            for( const property in errors ){
                const value = errors[property];
                ttmpMessage.push(value.errorMsg);
            }

            messageApi.error({ duration: 3, content: ttmpMessage.join(", ") });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridRef, formValues])

    const handleFilterChange = useCallback(({ name, value, valuePosition = null }) => {
        if( valuePosition !== null ){
            setFilterValues( oldValue => {
                let newValue = [...oldValue[name]];
                newValue[valuePosition] = value;
                return { ...oldValue, [name]: newValue };
            });
        } else {
            setFilterValues( oldValue => ({ ...oldValue, [name]: value }) );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues])

    const isExternalFilterPresent = ( ) => Object.values(filterValuesRef.current).some(f => isNonEmptyArray(f));

    const doesExternalFilterPass = ({ data }) => {
        let filters = filterValuesRef.current;
        const theUglyDuckling = 'next_action_date';

        if( Object.values(filters).every(v => isEmptyArray(v) || v.every(sv => !sv) ) ) return true; // No filter apply

        filters = Object.fromEntries( Object.entries(filters).filter(([k,v]) => isNonEmptyArray(v) && v.some(subV => subV)) );

        const dataFiltered = Object.fromEntries( Object.entries(data)
            .filter(([k, v]) => {
                const newK = k.replace("macro_", "");
                if( !filters[newK] ) return false;

                if( newK === theUglyDuckling ){
                    if( isNonEmptyArray(filters[theUglyDuckling]) && Object.values(filters[theUglyDuckling]).some(nadv => nadv) ){
                        if( filters[theUglyDuckling][0] && filters[theUglyDuckling][1] ){
                            if( dayjs(data[theUglyDuckling]) < dayjs(filters[theUglyDuckling][0]) || dayjs(data[theUglyDuckling]) > dayjs(filters[theUglyDuckling][1]) ) return false;
                        } else if( filters[theUglyDuckling][0] ){
                            if( dayjs(data[theUglyDuckling]) < dayjs(filters[theUglyDuckling][0]) ) return false;
                        } else if( filters[theUglyDuckling][1] ) {
                            if( dayjs(data[theUglyDuckling]) > dayjs(filters[theUglyDuckling][1]) ) return false;
                        }
                    }
                } else {
                    if( filters[newK] && !filters[newK].includes(v) ){
                        return false;
                    }
                }

                return true;
            }) 
        );

        if( isNonEmptyObject(dataFiltered) && Object.keys(filters).length === Object.keys(dataFiltered).length ) {
            return dataFiltered;
        }
        return false;
    }

    const handleCollapseAllRows = useCallback(( ) => {
        if( currentRowGroupPosition > min ){
            setCurrentRowGroupPosition(min);
        }
        gridRef.api.collapseAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridRef, currentRowGroupPosition])

    const handleToggleRows = useCallback(( method ) => {
        if( method === 'collapse' ){
            if( currentRowGroupPosition > min ){
                setCurrentRowGroupPosition(oldVal => oldVal-1);
                gridRef.api.forEachNode(node => {
                    const { level } = node;
                    if( level === currentRowGroupPosition-1 ){
                        gridRef.api.setRowNodeExpanded(node, false);
                    }
                })
            }
        } else if( method === 'expand' ) {
            if( currentRowGroupPosition < max ){
                setCurrentRowGroupPosition(oldVal => oldVal+1);
                gridRef.api.forEachNode(node => {
                    const { level } = node;
                    if( level === currentRowGroupPosition ){
                        gridRef.api.setRowNodeExpanded(node, true);
                    }
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridRef, currentRowGroupPosition])

    const handleExpandAllRows = useCallback(( ) => {
        if( currentRowGroupPosition < max ){
            setCurrentRowGroupPosition(max);
        }
        gridRef.api.expandAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridRef, currentRowGroupPosition])

    const today = useMemo(() => ( dayjs().format('YYYYMMDD_HHmmss') ), [])

    const getParams = useCallback((  ) => {
        return {
            author: 'Veostack',
            fileName: 'Suivi des dossiers - ' + today,
            sheetName: 'Suivi des dossiers',
            processCellCallback: ( params ) => {
                const colDef = params.column.getColDef();
                const { node } = params;
                const isRowGroup = node.group;
                const isFooter = node.footer;

                if( colDef.valueFormatter && colDef.type !== 'valueColumn' ) {
                    const valueFormatterParams = {
                        ...params,
                        data: params.node.data,
                        node: params.node,
                        colDef: params.column.getColDef()
                    }
                    return colDef.valueFormatter(valueFormatterParams);
                }
                if( isRowGroup && isFooter ){
                    if( ['macro_status_txt', 'macro_next_action_txt'].includes(colDef.showRowGroup) ) {
                        if( colDef.showRowGroup === node.field ) {
                            return 'Total ' + params.node.key;
                        }
                        return '';
                    } else if( colDef.showRowGroup ){
                        return '';
                    } 
                }
                return params.value;
            },
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onBtnExport = useCallback(() => {
        gridRef.api.exportDataAsExcel(getParams());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridRef])

    return (
        <>
            { contextHolder }
            <Space className="mb-2 w-100 d-flex justify-content-center align-items-end">
                {
                    formRequestConfig
                        .map(fRConfig => {
                            const { name, label } = fRConfig;

                            return (
                                <Input 
                                    key={ name }
                                    status={formErrors?.[name]?.['status']}
                                    onChange={handleChange}
                                    onBlur={() => handleCheckErrors(name)}
                                    size="large"
                                    labelOrientation="top"
                                    labelSeparator=""
                                    ratio="24/24"
                                    { ...fRConfig }
                                >
                                    { label }
                                </Input>
                            );
                        })
                }
                <Button 
                    type="primary"
                    size="large"
                    icon={ <RightArrow height={18} width={18} viewBox="0 0 40 50" /> }
                    onClick={handleSubmit}
                />
            </Space>
            <div className="d-flex align-items-center mb-3 mx-auto" style={{ height: '32px', width: '80%' }}>
                <Divider className="m-0" />
            </div>
            <Row gutter={16} style={{ height: 'calc(100% - 125px)' }}>
                <Col xs={3}>
                    <Menu
                        items={menuItems}
                        defaultSelectedKeys={[defaultSelectedKeys]}
                        onSelect={menuHandleSelect}
                    />
                </Col>
                {/* FILTERS PANEL */}
                <Col xs={optionsPanelIsOpen ? 3 : 0}>
                    <CCollapse horizontal visible={optionsPanelIsOpen}>
                        <Card bordered={false} bodyStyle={{ paddingLeft: '5px' }} style={{ boxShadow: "none" }}>
                            <Divider orientation="left">Filtres</Divider>
                            <Space direction="vertical" size={10} className="w-100">
                                <Input 
                                    type="select"
                                    mode="multiple"
                                    name="soc_consolid_txt"
                                    value={filterValues.soc_consolid_txt}
                                    placeholder="Entité de regroupement"
                                    submitIsPending={formIsSubmitted}
                                    options={creditorConsolidOptions}
                                    onChange={handleFilterChange}
                                />
                                <Input 
                                    type="select"
                                    mode="multiple"
                                    name="soc_txt"
                                    value={filterValues.soc_txt}
                                    placeholder="Entité"
                                    submitIsPending={formIsSubmitted}
                                    options={creditorOptions}
                                    onChange={handleFilterChange}
                                />
                                {
                                    ['micro'].includes(currentMenuSelected)
                                    &&
                                        <>
                                            <Input 
                                                type="select"
                                                mode="multiple"
                                                name="attr_a_rat"
                                                value={filterValues.attr_a_rat}
                                                placeholder="Compte de rattachement"
                                                submitIsPending={formIsSubmitted}
                                                options={attachmentOptions}
                                                onChange={handleFilterChange}
                                            />
                                            <Input 
                                                type="select"
                                                mode="multiple"
                                                name="user"
                                                value={filterValues.user}
                                                placeholder="Affectation"
                                                submitIsPending={formIsSubmitted}
                                                options={userOptions}
                                                onChange={handleFilterChange}
                                            />
                                        </>
                                }
                                <Input 
                                    type="select"
                                    mode="multiple"
                                    name="next_action_txt"
                                    value={filterValues.next_action_txt}
                                    placeholder="Prochaine action"
                                    submitIsPending={formIsSubmitted}
                                    options={nextActionOptions}
                                    onChange={handleFilterChange}
                                />
                                {
                                    ['micro'].includes(currentMenuSelected)
                                    &&
                                        <Space direction="vertical" size={2} className="w-100">
                                            <Input 
                                                type="datepicker"
                                                name="next_action_date"
                                                value={filterValues.next_action_date?.[0]}
                                                valuePosition={0}
                                                placeholder="De prochaine action"
                                                onChange={handleFilterChange}
                                            />
                                            <Input 
                                                type="datepicker"
                                                name="next_action_date"
                                                value={filterValues.next_action_date?.[1]}
                                                valuePosition={1}
                                                placeholder="A prochaine action"
                                                onChange={handleFilterChange}
                                            />
                                        </Space>
                                }
                            </Space>

                            <Divider orientation="left">Commandes tableau</Divider>
                            <Space className="d-flex justify-content-center">
                                <Tooltip title="Réduire tout" placement="bottom">
                                    <Space size={2} style={{ cursor: 'pointer' }} onClick={() => handleCollapseAllRows()}>
                                        <Image { ...imgCollapseProps } />
                                        <Image { ...imgCollapseProps } />
                                    </Space>
                                </Tooltip>

                                <Space>
                                    <Tooltip title="Réduire moins" placement="bottom">
                                        <Image { ...imgCollapseProps } style={{ cursor: 'pointer' }} onClick={() => handleToggleRows('collapse')} />
                                    </Tooltip>
                                    <Tooltip title="Etendre plus" placement="bottom">
                                        <Image { ...imgExpandProps } style={{ cursor: 'pointer' }} onClick={() => handleToggleRows('expand')} />
                                    </Tooltip>
                                </Space>

                                <Tooltip title="Etendre tout" placement="bottom">
                                    <Space size={2} style={{ cursor: 'pointer' }} onClick={() => handleExpandAllRows()}>
                                        <Image { ...imgExpandProps } />
                                        <Image { ...imgExpandProps } />
                                    </Space>
                                </Tooltip>
                            </Space>

                            <Divider orientation="left">
                                <div 
                                    onClick={() => setExportPanelIsOpen(!exportPanelIsOpen)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <span>
                                        Exportation 
                                    </span>
                                    <DropdownArrow 
                                        transform={exportPanelIsOpen ? "rotate(180)" : undefined} 
                                        className="expand-icon"
                                    />
                                </div>
                            </Divider>
                            <CCollapse visible={exportPanelIsOpen}>
                                <div style={exportIsDisable ? {cursor: 'not-allowed'} : undefined}>
                                    <Button 
                                        type="primary"
                                        danger={exportIsDisable} 
                                        icon={<DownloadXlsx height={22} width={22} className="me-1"/>}
                                        className="w-100 d-flex justify-content-center"
                                        style={exportIsDisable ? {pointerEvents: "none"} : undefined}
                                        onClick={() => exportIsDisable ? undefined : onBtnExport()}
                                    >
                                        Exporter
                                    </Button>
                                </div>
                            </CCollapse>
                        </Card>
                    </CCollapse>
                </Col>
                <Col flex="none">
                    <Row className="flex-column align-items-center h-100">
                        <Divider type="vertical" className="mb-2" style={{height: "15px"}}/>
                        <Button 
                            onClick={ isNonEmptyArray(fetchMicroData) ? () => setOptionsPanelIsOpen(!optionsPanelIsOpen) : undefined } 
                            type="primary"
                            shape="circle"
                            icon={<OptionsPanel height={18} width={18}/>}
                        />
                        <Divider type="vertical" className="mt-2" style={{flex: 1}}/>
                    </Row>
                </Col>
                <Col flex={1}>
                    <GridContext.Provider value={{ 
                        gridOptions, 
                        isExternalFilterPresent, 
                        doesExternalFilterPass 
                    }}>
                        { toggleRenderComponent() }
                    </GridContext.Provider>
                </Col>
            </Row>
        </>
    );
}