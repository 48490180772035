import React from "react";

import { Input, Select } from "antd";

const InputByType = ({ 
    type, 
    typeValues,
    name,
    values,
    onChange, 
    ...restProps 
}) => {
    switch( type ){
        case "text":
            return (
                <Input 
                    value={values?.[name]}
                    onChange={({ currentTarget }) => onChange(currentTarget.value)} 
                    {...restProps}
                />
            );
        case "select":
            return(
                <Select 
                    options={typeValues} 
                    value={values?.[name]}
                    className="w-100"
                    onChange={(val) => onChange(val)} 
                />
            ); 
        default:
            return (
                <Input 
                    value={values?.[name]}
                    onChange={({ currentTarget }) => onChange(currentTarget.value)} 
                    {...restProps}
                />
            );
    }
}

export const CustomTableCell = ({
    rec,
    inputType,
    inputValues,
    dataIndex,
    title,
    editing,
    editingValues,
    handleChange,
    children,
    ...restProps 
}) => {
    return(
        <td {...restProps}>
            {
                editing
                ?
                    <InputByType
                        type={inputType}
                        typeValues={inputValues}
                        name={dataIndex}
                        values={editingValues}
                        onChange={(value) => handleChange(dataIndex, value)}
                    />
                :
                    children
            }
        </td>
    );
}