import { sortStrings } from "_helpers";

import { Tooltip, Typography } from "antd";

const Text = Typography.Text;

const renderHeader = ( headerText ) => {
    return(
        <Text style={{fontSize: 12}}>{headerText}</Text>
    );
}

const renderCell = ( cellText ) => {
    return(
        <Text style={{fontSize: 11}}>{cellText}</Text>
    );
}

const renderCellWithTooltip = ( cellText, record, placement ) => {
    return(
        <Tooltip title={record['entity']} placement={placement}>
            <Text style={{fontSize: 11}}>{cellText}</Text>
        </Tooltip>
    );
}

const commonConfig = {
    align: "center",
    sorter: (a, b) => sortStrings(a.acc_id, b.acc_id),
    render: (text) => renderCell(text)
}

export const columnsWithOneBaseCount = [
    {
        title: renderHeader("Client"),
        dataIndex: "acc_id",
        width: "20%",
        ...commonConfig,
        render: (text, rec) => renderCellWithTooltip(text, rec, "left")
    },
    {
        title: renderHeader("Nom"),
        dataIndex: "acc_name",
        width: "30%",
        ...commonConfig
    },
    {
        title: renderHeader("Dossier"),
        dataIndex: "file_id",
        width: "20%",
        ...commonConfig
    },
    {
        title: renderHeader("S/statut dossier"),
        dataIndex: "substatus",
        width: "30%",
        ...commonConfig
    }
];

export const columnsWithMoreOneBaseCount = [
    {
        title: renderHeader("Base"),
        dataIndex: "_base_name",
        width: "15%",
        ...commonConfig
    },
    {
        title: renderHeader("Client"),
        dataIndex: "acc_id",
        width: "20%",
        ...commonConfig,
        render: (text, rec) => renderCellWithTooltip(text, rec, "top")
    },
    {
        title: renderHeader("Nom"),
        dataIndex: "acc_name",
        width: "20%",
        ...commonConfig
    },
    {
        title: renderHeader("Dossier"),
        dataIndex: "file_id",
        width: "20%",
        ...commonConfig
    },
    {
        title: renderHeader("S/statut dossier"),
        dataIndex: "substatus",
        width: "25%",
        ...commonConfig
    }
];