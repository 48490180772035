import { useState, useEffect, useCallback } from "react";

export function useCheckboxGroup(options = [], checkAllDefaultValue = false){
    const [ checkedList, setCheckedList ] = useState([]);
    const [ indeterminate, setIndeterminate ] = useState(false);
    const [ checkAll, setCheckAll ] = useState(checkAllDefaultValue);

    useEffect(() => {
        if( options.length && checkAllDefaultValue ){
            onCheckAllChange(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options])

    const onChange = (list) => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < options.length);
        setCheckAll(list.length === options.length);
    }

    const onCheckAllChange = useCallback((event) => {
        const optionsValues = options.map(option => option.value)
        
        if( event === null ){
            setCheckedList(optionsValues);
            setCheckAll(true);
        } else {
            const { target } = event,
                { checked } = target;
                
            setCheckedList(checked ? optionsValues : []);
            setCheckAll(checked);
        }

        setIndeterminate(false);
    }, [options])

    const onResetAll = () => {
        setCheckedList([]);
        setIndeterminate(false);
        setCheckAll(false);
    }

    return {
        checkedList,
        indeterminate,
        checkAll,
        onCheckboxGroupChange: onChange,
        onCheckboxGroupAllChange: onCheckAllChange,
        onResetAll
    };
}