import React, { useEffect, useState, useMemo, useCallback }  from "react";

import { 
    NoRowsOverlay, 
    LoadingOverlay, 
    AG_GRID_LOCALE_FR as localeText,
} from "_components";
import { useGrid } from "_contexts";
import { isNonEmptyArray, sortDates, sortNumbers, formatNumbers, formatWithTwoDecimals } from "_helpers";

import { AgGridReact } from "ag-grid-react";
import 'ag-grid-enterprise';

import dayjs from 'dayjs';

const formatFR = "DD/MM/YYYY";

const AgGridGlobalGetRowStyleCustom = ({ node }) => {
    const isPinned = node.rowPinned;
    const isRowGroup = node.group;
    const isFooter = node.footer;

    if ( isPinned || ( isRowGroup && isFooter ) ) return { 'fontWeight': 'bold' };
};

const AgGridGlobalExcelStylesCustom = [
    {
        id: 'cell',
        alignment: { horizontal: 'Center' },
        dataType: 'String'
    },      
    {
        id: 'bold',
        font: {
            bold: true
        }
    },
    {
        id: 'dateExcelFormat',
        numberFormat: { format: 'dd/mm/yyyy' },
        dataType: 'DateTime'
    },
    {
        id: 'numberExcelFormat',
        numberFormat: { format: '#,##0.00' },
        alignment: { horizontal: 'Right' },
        dataType: 'Number'
    },
    {
        id: 'multiline',
        alignment: { horizontal: 'Left', vertical: 'Top', wrapText: true },
        dataType: 'String'
    }
];

const AgGridGlobalColumnTypesCustom = {
    centerAlign: {
        cellStyle: { 'textAlign': 'center' }
    },
    valueIntColumn : {
        aggFunc: 'sum',
        valueParser: 'Number(newValue)',
        filter: 'agMultiColumnFilter',
        filterParams: {
            filters: [
                {
                    filter: 'agNumberColumnFilter', 
                    filterParams: {
                        buttons: ['reset']
                    }
                },
                {
                    filter: 'agSetColumnFilter', 
                    filterParams: {
                        comparator: sortNumbers,
                        buttons: ['reset']
                    }
                },
            ], 
        },
        cellStyle: { 'textAlign': 'center' },
        comparator: sortNumbers
    },
    valueColumn: {
        aggFunc: 'sum',
        valueParser: 'Number(newValue)',
        filter: 'agMultiColumnFilter',
        filterParams: {
            filters: [
                {
                    filter: 'agNumberColumnFilter', 
                    filterParams: {
                        buttons: ['reset']
                    }
                },
                {
                    filter: 'agSetColumnFilter', 
                    filterParams: {
                        comparator: sortNumbers,
                        valueFormatter: params => {
                            const value = formatWithTwoDecimals(params.value);

                            if( value === 0 ) return '0';
                            
                            return formatNumbers(value);
                        },
                        buttons: ['reset']
                    }
                },
            ], 
        },
        cellStyle: { 'textAlign': 'right' },
        cellClass : 'numberExcelFormat',
        comparator: sortNumbers,
        valueFormatter: params => {
            const value = formatWithTwoDecimals(params.value);

            if( !value && params.node.rowPinned ) return null;

            if( value === 0 ){
                if( params.node.rowPinned ) return 0;
                return '';
            }

            return formatNumbers(value);
        }
    },
    dateColumn: {
        filter: 'agMultiColumnFilter',
        filterParams: {
            filters: [
                {
                    filter: 'agDateColumnFilter', 
                    filterParams: {
                        buttons: ['reset']
                    }
                },
                {
                    filter: 'agSetColumnFilter', 
                    filterParams: {
                        comparator: sortDates, // For filtering columns in agSetColumnFilter part
                        valueFormatter: ({ value }) => value && value !== '0000-00-00 00:00:00' ? dayjs(value).format(formatFR) : dayjs('1970-01-01').format(formatFR),
                        buttons: ['reset']
                    }
                },
            ]
        },
        cellStyle: { 'textAlign': 'center' },
        cellClass: 'dateExcelFormat',
        comparator: sortDates, // For sorting columns
        valueFormatter: ({ value }) => value && value !== '0000-00-00 00:00:00' ? dayjs(value).format(formatFR) : '',
    },
    commentColumn: {
        filter: false,
        sortable: false,
        cellStyle: { 'textAlign': 'left' },
        cellClass: 'multiline'
    }
};

export const AgGridCustom = ({ gridRef, columnDefs, createPinData, createPinDataExtraParams, fetchData, updateRowData }) => {
    const [ rowData, setRowData ] = useState([]);

    const { gridOptions, isExternalFilterPresent, doesExternalFilterPass } = useGrid();

    useEffect(() => {
        setRowData(fetchData);
    }, [fetchData])

    const onGridReady = useCallback(( params ) => {
        params.api.hideOverlay();
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const pinnedTopRowData = useMemo(() => createPinData && createPinData(rowData, createPinDataExtraParams), [rowData, createPinDataExtraParams]);

    // UPDATE PINNED DATAS
    const onFilterChanged = useCallback(( params ) => {
        let result = [];

        params.api.forEachNodeAfterFilter(n => { result.push(n.data) });
        
        if( updateRowData !== undefined ) updateRowData(result);

        if( createPinData ) params.api.setPinnedTopRowData(createPinData(result, createPinDataExtraParams));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createPinDataExtraParams])

    const loadingOverlayComponentParams = useCallback(( ) => ({
        loadingMessage: "Vos données se chargent, veuillez patienter..."
    }), [])
    
    const noRowsOverlayComponentParams = useCallback(( ) => ({
        loadingMessage: (
            <>
                <div>Désolé, nous ne sommes pas parvenus à charger vos données où aucunes données ne correspondent à votre demande,</div>
                <div>veuillez relancer une nouvelle requête</div>
            </>
        )
    }), [])

    return(
        <div className="container-fluid h-100">
            <div className="ag-theme-alpine h-100">
                <AgGridReact
                    ref={gridRef}
                    rowData={isNonEmptyArray(rowData) ? rowData : null}
                    gridOptions={gridOptions}
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                    pinnedTopRowData={pinnedTopRowData}
                    onFilterChanged={onFilterChanged}
                    isExternalFilterPresent={isExternalFilterPresent}
                    doesExternalFilterPass={doesExternalFilterPass}
                    loadingOverlayComponent={LoadingOverlay}
                    loadingOverlayComponentParams={loadingOverlayComponentParams}
                    noRowsOverlayComponent={NoRowsOverlay}
                    noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    columnTypes={AgGridGlobalColumnTypesCustom}
                    excelStyles={AgGridGlobalExcelStylesCustom}
                    getRowStyle={AgGridGlobalGetRowStyleCustom}
                    localeText={localeText}
                />
            </div>
        </div>
    );
}