import { useState, useEffect, useMemo, useCallback } from "react";

import { isNonEmpty, isNonEmptyArray, isEmptyArray, isArray, deepCopy } from "_helpers";
import { getFileStatus } from "_services";

import dayjs from 'dayjs';

export function useFileStatus(fields, viewMode){
    const [ domains, setDomains ] = useState("");
    const [ data, setData ] = useState([]);

    const [ bases, setBases ] = useState([]);
    const [ entities, setEntities ] = useState([]);
    const [ status, setStatus ] = useState("");
    const [ dateCloseFrom, setDateCloseFrom ] = useState("");

    const [ isSubmitted, setIsSubmitted ] = useState(false); 
    const [ fetchIsDone, setFetchIsDone ] = useState(false);

    const formatEN = "YYYY-MM-DD";

    let values = useMemo(() => (
        {
            'filter_bases': bases,
            'filter_entities': entities,
            'filter_status': status,
        }
    ),[bases, entities, status]);

    useEffect(() => {
        setBases(fields.bases);
        setEntities(fields.entities);
        setStatus(fields.status);
        setDateCloseFrom(fields.dateCloseFrom);
    }, [fields])

    useEffect(() => {
        if( status === "SX_CLOSE" && dateCloseFrom ){
            values['filter_status_bydate'] = dayjs(dateCloseFrom).format(formatEN);
        } else{
            delete values['filter_status_bydate'];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, dateCloseFrom])

    const getDataByViewMode = useCallback((view, fetchData) => {
        let ret = [];
        if( view && isNonEmptyArray(fetchData) ){
            const dataCopy = deepCopy(fetchData);
            let ttmp = {};

            switch( view ){
                case 'account_view' :
                    dataCopy.forEach(data => {
                        const { file_nb_total, file_amount_total, file_amount_due, file_acc_id, file_acc_name, file_reporting_txt, file_litig_txt } = data;

                        const rest = { file_acc_id, file_acc_name, file_reporting_txt, file_litig_txt };

                        const acc_id = data.file_acc_id;

                        if( !ttmp[acc_id] ){
                            ttmp[acc_id] = {};
                        }

                        if( !ttmp[acc_id]['file_nb_total'] && !ttmp[acc_id]['file_amount_total'] && !ttmp[acc_id]['file_amount_due'] ){
                            ttmp[acc_id]['file_nb_total'] = 0;
                            ttmp[acc_id]['file_amount_total'] = 0;
                            ttmp[acc_id]['file_amount_due'] = 0;
                        }

                        ttmp[acc_id]['file_nb_total'] += file_nb_total;
                        ttmp[acc_id]['file_amount_total'] += file_amount_total;
                        ttmp[acc_id]['file_amount_due'] += file_amount_due;

                        if( Object.keys(rest).every(r => !Object.keys(ttmp[acc_id]).includes(r)) ){
                            ttmp[acc_id] = { ...ttmp[acc_id], ...rest };
                        }
                    })

                    ret = Object.values(ttmp);
                    break ;
                case 'record_view' :
                    dataCopy.forEach(data => {
                        const { _records, file_acc_id, file_acc_name, file_status, action_txt } = data;

                        const rest = { file_acc_id, file_acc_name, file_status, action_txt };
                        
                        _records.forEach(rec => {
                            const { record_filerecord_id, record_ref, amount, date_record } = rec;

                            if( !ttmp[record_filerecord_id] ){
                                ttmp[record_filerecord_id] = {};
                            }

                            ttmp[record_filerecord_id]['record_ref'] = record_ref;
                            ttmp[record_filerecord_id]['record_amount'] = amount;
                            ttmp[record_filerecord_id]['record_date'] = date_record;

                            if( Object.keys(rest).every(r => !Object.keys(ttmp[record_filerecord_id]).includes(r)) ){
                                ttmp[record_filerecord_id] = { ...ttmp[record_filerecord_id], ...rest };
                            }
                        })
                    })

                    ret = Object.values(ttmp);
                    break ;
                default : 
                    ret = dataCopy;
                    break ;
            }
        //     const reduced = dataCopy
        //         .reduce((acc, item) => {
        //             const { _file_id, file_acc_id, _records } = item;
        //             let key = _file_id;

        //             if( theView === 'record_view' ){
        //                 for( const rec of _records ){
        //                     const { record_filerecord_id, record_ref, amount, date_record } = rec;
        //                     key = record_filerecord_id;

        //                     if( !acc[key] ){
        //                         acc[key] = [];
        //                     }

        //                     acc[key] = {
        //                         ...item,
        //                         record_ref,
        //                         record_amount: parseFloat(amount),
        //                         record_date: date_record,
        //                     };
        //                 }

        //                 return acc;
        //             }

        //             if( theView === 'account_view' ){
        //                 key = file_acc_id;

        //                 if( !acc[key] ){
        //                     acc[key] = [];
        //                 }

        //                 ['file_nb_total', 'file_amount_total', 'file_amount_due'].forEach(el => {
        //                     if( !acc[key].hasOwnProperty(el) ){
        //                         acc[key][el] = 0;
        //                     }
        //                 })
    
        //                 acc[key] = {
        //                     ...item,
        //                     file_nb_total: acc[key]['file_nb_total'] + item['file_nb_total'],
        //                     file_amount_total: acc[key]['file_amount_total'] + item['file_amount_total'],
        //                     file_amount_due: acc[key]['file_amount_due'] + item['file_amount_due']
        //                 };

        //                 return acc;
        //             }

        //             if( !acc[key] ){
        //                 acc[key] = [];
        //             }
                    
        //             acc[key] = item;
                
        //             return acc;
        //         }, {});

        //     ret = Object.values(reduced);
        }
        return ret;
    }, [])

    useEffect(() => {
        if( isSubmitted ){
            if( Object.values(values).some(value => isEmptyArray(value) || (!isArray(value) && !value)) ){
                setDomains([]);
                setData([]);
            } else if( Object.values(values).every(value => isNonEmptyArray(value) || (!isArray(value) && value)) ){
                try{
                    const timer = setTimeout(async () => {
                        const { response, data } = await getFileStatus(values);
        
                        if( response ){
                            if( isNonEmpty(data) ){
                                setData(data.records);
                                setDomains(data.domains);
                            }
                            setFetchIsDone(true);
                        } else {
                            // resetData();
                        }
                        setIsSubmitted(false);
                    }, 0)
        
                    return () => clearTimeout(timer);
                } catch(e){
                    // resetData();
                    setIsSubmitted(false);
                    throw new Error(e);
                }
            }
        }
    }, [values, isSubmitted, viewMode])

    return {
        fetchDomains: domains, 
        fetchTableData: data, 
        setIsSubmitted,
        fetchIsDoneHandler: {
            fetchIsDone,
            setFetchIsDone
        },
        getDataByViewMode
    };
}

export function useFileStatusColumnDefsByViewModeAndStatus( viewMode, status = "", columnDefsList = {} ){
    const [ columnDefs, setColumnDefs ] = useState([]);

    useEffect(() => {
        let tempColumnDefs = [];

        switch( viewMode ){
            case 'account_view':
            case 'record_view':
                if( viewMode === 'account_view' && status === 'S2L_LITIG' ){
                    tempColumnDefs = columnDefsList['account_view_S2L_LITIG'];
                    break;
                } 
                tempColumnDefs = columnDefsList[viewMode];
                break;
            case 'file_view':
                if( status ){
                    if( columnDefsList.hasOwnProperty(status) ){
                        tempColumnDefs = columnDefsList[status];
                    }
                } else {
                    if( columnDefsList.hasOwnProperty('S1_OPEN') ){
                        tempColumnDefs = columnDefsList['S1_OPEN'];
                    }
                }
                break;
            default:
                break;
        }

        setColumnDefs(tempColumnDefs);
    }, [viewMode, status])

    return { columnDefs };
}