import { useState, useEffect, useCallback } from "react";

import { isNonEmptyObject, isEmptyObject, shallowCopy, isEmptyArray } from "_helpers";
import { getFileTracking } from "_services";

export function useFileTracking( formConfig ){
    const [ formValues, setFormValues ] = useState({});
    const [ formIsSubmitted, setFormIsSubmitted ] = useState(false);

    const [ formErrors, setFormErrors ] = useState({});

    const [ rowDataMicro, setRowDataMicro ] = useState([]);
    const [ rowDataMacro, setRowDataMacro ] = useState([]);
    const [ fetchIsDone, setFetchIsDone ] = useState(false);

    const resetData = useCallback(() => {
        setFormValues({});
        setRowDataMicro([]);
        setRowDataMacro([]);
        setFetchIsDone(false);
        setFormIsSubmitted(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = useCallback(({ name, value }) => {
        setFormValues(oldVal => ({ ...oldVal, [name]: value }));
    }, [])

    const handleCheckErrors = useCallback(( currName = null ) => {
        const formValuesCopy = shallowCopy(formValues);
        const formConfigFiltered = formConfig.filter(fConfig => fConfig.required);
        let ttmpErrors = shallowCopy(formErrors);

        formConfigFiltered.forEach(fConfig => {
            const { name, label, requiredMsg } = fConfig;

            if( currName && currName !== name ) return ;

            if( !formValuesCopy[name] || isEmptyArray(formValuesCopy[name]) ){
                ttmpErrors[name] = {
                    'status': 'error'
                }

                if( requiredMsg ){
                    ttmpErrors[name]['errorMsg'] = requiredMsg;
                } else {
                    ttmpErrors[name]['errorMsg'] = 'Le champ ' + label + ' est requis';
                }
            } else {
                delete(ttmpErrors[name]);
            }
        })

        setFormErrors(ttmpErrors);

        return { isValid: isEmptyObject(ttmpErrors), errors: ttmpErrors };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues, formErrors])

    useEffect(() => {
        if( isNonEmptyObject(formValues) && formIsSubmitted ){
            try{
                const timer = setTimeout(async () => {
                    const { response, data } = await getFileTracking( formValues );
    
                    if( response ){
                        const { micro, macro } = data;
                        setRowDataMicro(micro);
                        setRowDataMacro(macro);
                        setFetchIsDone(true);
                    } else {
                        resetData();
                    }
                    setFormIsSubmitted(false);
                }, 0)
    
                return () => clearTimeout(timer);
            } catch(e){
                resetData();
                throw new Error(e);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues, formIsSubmitted])
    
    return {
        formValues,
        formErrors,
        handleCheckErrors,
        handleChange,
        fetchMicroData: rowDataMicro,
        fetchMacroData: rowDataMacro,
        formIsSubmittedHandler: {
            formIsSubmitted,
            setFormIsSubmitted
        },
        fetchIsDoneHandler: {
            fetchIsDone,
            setFetchIsDone
        }
    };
}